import {
  StyleSheet,
  Image,
  TouchableOpacity,
  View,
  StatusBar,
  Text,
  FlatList,
  TextInput,
  ScrollView,
  SafeAreaView
} from "react-native";
import React, { useState, useEffect } from "react";
import { AntDesign, Ionicons } from "@expo/vector-icons";
import cartIcon from "../../assets/icons/cart_beg.png";
import { colors, messages, network } from "../../constants";
import CustomButton from "../../components/CustomButton";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../states/actionCreators/actionCreators";
import AsyncStorage from "@react-native-async-storage/async-storage";
import CartIconAndStatus from "../../components/CartIconAndStatus";
import ProgressDialog from "react-native-progress-dialog";
import { useToast } from "react-native-toast-notifications";
import CommentItem from '../../components/CommentItem/CommentItem';
import Ratings from "../../components/Ratings";
import { useAuthStore } from "../../states/store";
import { useIsFocused } from '@react-navigation/native';

const ProductDetailScreen = ({ navigation, route }) => {
  const { productId } = route.params;
  const isFocused = useIsFocused();
  const [product, setProduct] = useState();
  const user = useAuthStore((state) => state.user);
  const logout = useAuthStore((state) => state.logout);
  console.log("product inside ProductDetailScreen", productId, "user inside ProductDetailScreen", user)
  const toast = useToast();
  const [newComment, setNewComment] = useState('');
  const [commentList, setCommentList] = useState([]);
  const dispatch = useDispatch();
  const { addCartItem } = bindActionCreators(actionCreators, dispatch);
  const [onWishlist, setOnWishlist] = useState(false);
  const [productImage, setProductImage] = useState("");
  const [wishlistItems, setWishlistItems] = useState([]);
  const [isDisable, setIsDisbale] = useState(true);
  const [alertType, setAlertType] = useState("error");
  const [productComments, setProductComments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [offerAmount, setOfferAmount] = useState('0');
  const [offerQuantity, setOfferQuantity] = useState('1');
  const [isOutOfStock, setIsOutOfStock] = useState(false);

  const handleDeleteItem = (id) => {
    setCommentList(commentList.filter((item) => item.id !== id));
    // console.log(id)
  }

  const handleAddComment = () => {
    if (newComment.trim() === '') return;
    setIsLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("x-auth-token", user?.token);
    myHeaders.append('Content-Type', 'application/json');
    var addCommentRequestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({
        commentText: newComment,
      }),
    };

    fetch(`${network.serverip}/product/${productId}/comment`, addCommentRequestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.err === "jwt expired") {
          setIsLoading(false);
          toast.show(messages.needLoginMessage, {
            placement: "bottom",
            type: "danger_with_close_button"
          });
          logout();
        }
        if (result.success) {
          // setProductComments(result.data);
          setIsLoading(false);
          setCommentList([result.addedComment, ...commentList]);
          setNewComment('');
          toast.show("Yorumunuz eklendi.", {
            placement: "bottom",
            type: "success_with_close_button"
          });

        } else {
          toast.show("Bir hata oluştu. Lütfen daha sonra tekrar deneyin.", {
            placement: "bottom",
            type: "danger_with_close_button"
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.show("Bir hata oluştu. Lütfen daha sonra tekrar deneyin.", {
          placement: "bottom",
          type: "danger_with_close_button"
        });
        console.log("error", error);
      });
  };


  //method to add item to cart(redux)
  const handleAddToCart = (item) => {
    addCartItem(item);
    toast.show("Sepete eklendi.", {
      placement: "bottom",
      type: "success_with_close_button"
    });
  };

  const digits_only = string => [...string].every(c => '0123456789'.includes(c));


  const handleMakeOffer = (item) => {
    console.log("offerAmount", offerAmount, typeof offerAmount)
    console.log("offerQuantity", offerQuantity, typeof offerAmount)

    if (!digits_only(offerAmount)) {
      toast.show("Teklif fiyatı sadece rakamlardan oluşmalıdır", {
        placement: "bottom",
        type: "danger_with_close_button"
      });
      return
    }

    if (!digits_only(offerQuantity)) {
      toast.show("Teklif adeti sadece rakamlardan oluşmalıdır", {
        placement: "bottom",
        type: "danger_with_close_button"
      });
      return
    }

    if (offerAmount == '') {
      toast.show("Lütfen teklif fiyatını boş bırakmayın", {
        placement: "bottom",
        type: "danger_with_close_button"
      });
      return
    }

    if (offerQuantity == '') {
      toast.show("Lütfen teklif adetini boş bırakmayın", {
        placement: "bottom",
        type: "danger_with_close_button"
      });
      return
    }
    var createOfferRequestHeaders = new Headers();
    createOfferRequestHeaders.append("x-auth-token", user?.token);
    createOfferRequestHeaders.append("Content-Type", "application/json");

    var createOfferRequestOptions = {
      method: "POST",
      headers: createOfferRequestHeaders,
      body: JSON.stringify({
        offerAmount: offerAmount,
        offerQuantity: offerQuantity,
        productId: product._id
      }),
      redirect: "follow",
    };
    fetch(`${network.serverip}/offers`, createOfferRequestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          toast.show("Teklif başarıyla gönderildi", {
            placement: "bottom",
            type: "success_with_close_button",
          });
        } else {
          if (result.message === "Unable to send offer to self") {
            toast.show("Bu ürün size ait. Kendinize teklif yapamazsınız", {
              placement: "bottom",
              type: "danger_with_close_button"
            });
          } else {
            toast.show("Teklifi yaratırken bir hata oluştu. Lütfen sonra tekrar deneyin", {
              placement: "bottom",
              type: "danger_with_close_button"
            });
          }
        }
      })
      .catch((error) => {
        toast.show("Teklifi yaratırken bir hata oluştu. Lütfen sonra tekrar deneyin", {
          placement: "bottom",
          type: "danger_with_close_button"
        });
        console.log("error", error);
      });
  }

  //remove the userData from async storage and navigate to login
  const handleLogout = async () => {
    logout();
    navigation.replace("login");
  };

  //method to fetch wishlist from server using API call
  const fetchComments = async () => {
    setIsLoading(true);
    var myHeaders = new Headers();
    // myHeaders.append("x-auth-token", user?.token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(`${network.serverip}/product/${productId}/comments`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result?.err === "jwt expired") {
          setIsLoading(false);
          handleLogout();
        }
        if (result.success) {
          setCommentList(result.data);
          setIsLoading(false);
        } else {
          toast.show(messages.genericErrorMessage, {
            placement: "bottom",
            type: "danger_with_close_button"
          });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.show(messages.genericErrorMessage, {
          placement: "bottom",
          type: "danger_with_close_button"
        });
        console.log("error", error);
      });
  };
  const updateParentCommentsList = (newAddedReply) => {
    setCommentList([newAddedReply, ...commentList]);
    setNewComment('');
    toast.show("Yorumunuz eklendi.", {
      placement: "bottom",
      type: "success_with_close_button"
    });

  }
  const incrementVisitCount = async () => {
    fetch(`${network.serverip}/product/${productId}/increment-visit-count`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // "x-auth-token": user?.token
      },
    }).then((response) => response.json())
      .then((result) => {
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const fetchProduct = () => {
    setIsLoading(true);
    var myHeaders = new Headers();
    // myHeaders.append("x-auth-token", user?.token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(`${network.serverip}/product/${productId}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          setProduct(result.data);
          console.log("result.data", result.data)
          incrementVisitCount();
          fetchComments();
          setIsOutOfStock(result.data.quantity <= 0)
          setOfferAmount(result.data.price.toString())
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.show("Bir hata oluştu. Lütfen daha sonra tekrar deneyin.", {
          placement: "bottom",
          type: "danger_with_close_button"
        });
        console.log("error", error);
      });
  }
  //set quantity, avaiableQuantity, product image and fetch wishlist on initial render
  useEffect(() => {
    fetchProduct();
  }, [isFocused]);

  const determineOfferButtonText = () => {
    if (user && isOutOfStock) {
      return "ÜRÜN STOKTA KALMADI"
    } else if (user && !isOutOfStock) {
      return "TEKLİF GÖNDER"
    } else {
      return messages.needLoginMessage
    }
  }
  return (
    <ScrollView>
      <StatusBar backgroundColor={colors.primary}></StatusBar>
      <ProgressDialog visible={isLoading} label={"Yükleniyor..."} />
      <View style={styles.topBarContainer}>
        <TouchableOpacity
          onPress={() => {
            // user can directly come here and we need to make failproof
            // User can come directly, or from viewmyproducts, or tab/buy
            if (navigation.canGoBack()) {
              navigation.goBack()
            } else {
              navigation.navigate("tabs", { screen: "buy" }); // we can't leave goBack since user can come directly. 
            }
          }}
        >
          <Ionicons
            name="arrow-back-circle-outline"
            size={30}
            color={colors.muted}
          />
        </TouchableOpacity>

        {/* <CartIconAndStatus navigation={navigation} /> */}
      </View>
      {product ?
        <View style={styles.bodyContainer}>
          <View style={styles.productImageContainer}>
            <Image source={{ uri: `${network.serverip}/uploads/${product?.filename}` }} style={styles.productImage} />
          </View>
          <View style={styles.productInfoContainer}>
            <View style={styles.productInfoTopContainer}>
              <View style={styles.productNameContainer}>
                <Text style={styles.productNameText}>{product.title}</Text>
              </View>
              {/* <View style={styles.infoButtonContainer}>
              <View style={styles.wishlistButtonContainer}>
                <TouchableOpacity
                  disabled={isDisable}
                  style={styles.iconContainer}
                  onPress={() => handleWishlistBtn()}
                >
                  {onWishlist == false ? (
                    <Ionicons name="heart" size={25} color={colors.muted} />
                  ) : (
                    <Ionicons name="heart" size={25} color={colors.danger} />
                  )}
                </TouchableOpacity>
              </View>
            </View> */}
              <View style={styles.productDetailContainer}>
                <View style={styles.sameLineLayout}>
                  <Text style={styles.secondaryTextSm}>Kitap İsmi: </Text>
                  <Text>{product.title}</Text>
                </View>
                <View style={styles.sameLineLayout}>
                  <Text style={styles.secondaryTextSm}>Fiyat: </Text>
                  <Text style={styles.primaryTextSm}>{product.price}₺</Text>
                </View>
                <View style={styles.sameLineLayout}>
                  <Text style={styles.secondaryTextSm}>Yazar: </Text>
                  <Text>{product?.author}</Text>
                </View>
                <View style={styles.sameLineLayout}>
                  <Text style={styles.secondaryTextSm}>Kondisyon: </Text>
                  <Text>{product.condition}</Text>
                </View>
                <View style={styles.sameLineLayout}>
                  <Text style={styles.secondaryTextSm}>Kategori: </Text>
                  <Text>{product?.category}</Text>
                </View>
                <View style={styles.sameLineLayout}>
                  <Text style={styles.secondaryTextSm}>Adet: </Text>
                  <Text style={isOutOfStock && styles.primaryTextSm}>{isOutOfStock ? "Stokta Kalmadı" : product?.quantity}</Text>
                </View>
                <Text style={styles.secondaryTextSm}>Ürün Açıklaması:</Text>
                <Text>{product?.description}</Text>
                <View style={styles.sameLineLayout}>
                  <Text style={styles.secondaryTextSm}>Satan Kullanıcı: </Text>
                  <Text>{product?.userId?.username}</Text>
                </View>
                <View style={styles.sameLineLayout}>
                  <Ratings
                    labelText={"Satıcının Aldığı Oylama"}
                    readOnly={true}
                    initialRatingsCount={product?.userId?.ratingsCount || 0}
                    initialRating={product?.userId?.ratings || 0} // Initial rating value
                    onRatingChange={(newRating) => {
                      // Handle the new rating value here
                      console.log('New Rating:', newRating);
                      console.log("product passed to Ratings")
                    }}
                  />
                </View>
                <View style={styles.productButtonContainer}>
                  <CustomButton
                    disabled={isOutOfStock}
                    text={isOutOfStock ? "ÜRÜN STOKTA KALMADI" : "SEPETE EKLE"}
                    onPress={() => {
                      handleAddToCart(product);
                    }}
                  />
                  {isOutOfStock ? (<Text style={{ justifyContent: "center", fontSize: 24, fontWeight: "bold", textDecorationStyle: 'solid' }}>
                    {"\n"}
                  </Text>) : (<Text style={{ justifyContent: "center", fontSize: 24, fontWeight: "bold", textDecorationStyle: 'solid' }}>
                    VEYA
                  </Text>)
                  }
                  <View style={styles.offerInputContainer}>
                    {/* <Text style={{ justifyContent: "center", fontSize: 24, fontWeight: "bold", textDecorationLine: 'line-through', textDecorationStyle: 'solid' }}>
              {product.price}₺
            </Text> */}
                    <Text style={styles.label}>Teklif Fiyatı</Text>
                    <TextInput style={styles.offerInput} keyboardType='numeric' onChangeText={setOfferAmount} value={offerAmount} placeholder={"Teklif fiyatı girin"} />
                    <Text style={styles.label}>Ürün Adeti</Text>
                    <TextInput style={styles.offerInput} keyboardType='numeric' onChangeText={setOfferQuantity} value={offerQuantity} placeholder={"Ürün adeti girin."} />
                    <Text style={styles.label}>Teklif Özetim</Text>
                    <Text>Toplam Teklif Tutarı = {offerQuantity} tane x {offerAmount} ₺ = <Text style={styles.primaryTextSm}>{offerAmount * offerQuantity} ₺</Text></Text>
                    <Text style={styles.offerTermsParagraph}>
                      - Satıcı teklifiniz hakkında bilgilendirilecek.
                    </Text>
                    <Text style={styles.offerTermsParagraph}>
                      - Teklif yapmak için kredi kartına gerek yoktur.
                    </Text>
                    <Text style={styles.offerTermsParagraph}>
                      - İstediğiniz sayıda teklif yapabilirsiniz.
                    </Text>
                    <Text style={styles.offerTermsParagraph}>
                      - Ödemenizi teklif kabul edildikten sonra isteyeceğiz.
                    </Text>
                    <Text style={styles.offerTermsParagraph}>
                      - Teklifinizi her zaman iptal edebilirsiniz, onaylanmış olsa bile.
                    </Text>
                    <TouchableOpacity
                      disabled={isOutOfStock || !user}
                      style={(isOutOfStock || !user) ? styles.containerDisabled : styles.containerForOfferButton}
                      onPress={() => {
                        handleMakeOffer(product);
                      }}
                    >
                      <Text style={(isOutOfStock || !user) ? styles.buttonTextDisabled : styles.buttonTextForOffer}>{determineOfferButtonText()}</Text>
                    </TouchableOpacity>
                  </View>
                </View>
                <View style={styles.productNameContainer}>
                  <Text style={styles.productNameText}>Yorumlar ({commentList.length})</Text>
                </View>
                <View style={styles.horizontalLine}></View>
                <View style={styles.commentInputContainer}>
                  <TextInput onChangeText={e => setNewComment(e)} onSubmitEditing={() => handleAddComment()} style={styles.commentInput} value={newComment} placeholder={user ? "Yorum girin" : messages.needLoginMessage} />
                  <TouchableOpacity
                    disabled={!user}
                    style={user ? styles.commentButton : styles.commentButtonDisabled}
                    onPress={() => {
                      handleAddComment();
                    }}
                  >
                    <AntDesign name="arrowright" size={24} color="white" style={styles.searchIcon} />
                  </TouchableOpacity>
                </View>
                <View style={styles.commentsContainer}>
                  <FlatList
                    style={{ marginTop: 20, width: "100%" }}
                    data={commentList}
                    keyExtractor={(item, index) => `${item}-${index}`}
                    // keyExtractor={(item) => item._id}
                    // renderItem={renderItem}
                    renderItem={({ item }) => (
                      <CommentItem key={item._id} user={user} commentItem={item} deleteItem={handleDeleteItem} />
                    )}
                  />
                </View>
              </View>
              {/* <View style={styles.commentsContainer}>
              <FlatList
                data={comments}
                keyExtractor={(comment, index) => `${index}`}
                renderItem={renderItem}
              />
            </View> */}
              {/* <View style={styles.commentsTopContainer}>
              <Text style={{ fontSize: 36, fontWeight: '600', }}>Comments</Text>
              <Image source={{ uri: 'https://assets.ccbp.in/frontend/react-js/comments-app/comments-img.png' }} style={{ height: 200, width: 300, marginVertical: 20 }} />
              <Text>Say something about 4.0 Technologies</Text>
              <TextInput onChangeText={(text) => setName(text)} placeholder='Your Name' style={{ paddingLeft: 4, height: 40, borderColor: 'gray', borderWidth: 1, width: 300, marginTop: 10 }} />
              <TextInput
                onChangeText={text => setComment(text)}
                multiline={true}
                numberOfLines={4}
                style={{ paddingLeft: 4, height: 100, borderColor: 'gray', borderWidth: 1, width: 300, marginTop: 10 }}
                placeholder='Your Comment'
              />
              <TouchableOpacity onPress={addComment} style={{ backgroundColor: 'blue', width: 300, height: 40, marginTop: 10, alignItems: 'center', justifyContent: 'center' }}>
                <Text style={{ color: 'white' }}>Submit</Text>
              </TouchableOpacity>

              
              <FlatList
                style={{ marginTop: 20 }}
                data={commentList}
                keyExtractor={item => item.id}
                renderItem={({ item }) => (
                  <CommentItem commentList={item} deleteItem={deleteItem} />
                )}
              />
            </View>  */}
            </View>
          </View>
        </View>
        : <View style={styles.noProductFoundContainer}>
          <View style={styles.screenNameContainer}>
            <View>
              <Text style={styles.screenNameText}>Ürün Detayları</Text>
            </View>
            <View>
              <Text style={styles.noProductViewText}>
                Ürün bulunamadı.
              </Text>
            </View>
          </View>
          </View>
        }
    </ScrollView>
  );
};

export default ProductDetailScreen;

const styles = StyleSheet.create({
  noProductFoundContainer: {
    flexDirecion: "row",
    backgroundColor: colors.light,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    flex: 1,
  },
  screenNameContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  screenNameText: {
    fontSize: 30,
    fontWeight: "800",
    color: colors.muted,
  },
  noProductBodyContainer: {
    flex: 1, width: "100%"
  },
  noProductContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  noProductViewText: {
    fontSize: 18, // Adjust the font size as needed
    fontWeight: 'bold', // Bold text
    color: "black",
    padding: 2,
    backgroundColor: "yellow",
  },
  buttonTextDisabled: {
    fontWeight: "bold",
    color: colors.light,
  },
  containerDisabled: {
    padding: 15,
    alignItems: "center",
    borderRadius: 10,
    backgroundColor: colors.muted,
  },
  offerTermsParagraph: {
    fontSize: 12,
    marginBottom: 1,
    fontWeight: 'bold',
    maxWidth: 350
  },
  label: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 1,
    opacity: 0.5
  },
  replyIcon: {
    width: 16,
    height: 13,
    marginRight: 5,
    position: 'absolute',
    tintColor: 'rgba(0, 0, 0, 0.67)', // Simulate "invert(67%)"
  },
  horizontalLine: {
    borderBottomColor: 'black',
    borderBottomWidth: 1,
    marginVertical: 10
  },
  commentsTopContainer: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 12,
  },
  commentButton: {
    backgroundColor: colors.lightpink,
    borderRadius: 5,
    padding: 10,
    flex: 1,
    // width: "5%",
    justifyContent: "center",
    alignItems: "center",
  },
  commentButtonDisabled: {
    backgroundColor: colors.muted,
    borderRadius: 5,
    padding: 10,
    flex: 1,
    // width: "5%",
    justifyContent: "center",
    alignItems: "center",
  },
  commentInputContainer: {
    display: "flex",
    flexDirection: 'row',
    // alignItems: 'space-between',
    width: "100%",
    backgroundColor: '#fff',
    borderRadius: 5,
    backgroundColor: "#f3f3f3"
    // marginHorizontal: 10,
    // paddingHorizontal: 10,
  },
  commentInput: {
    flex: 8,
    borderWidth: 2,
    backgroundColor: colors.bisque,
    // outlineColor: colors.lightpink,
    backgroundColor: "#fff",
    padding: 8,
    borderRadius: 5,
    justifyContent: "flex-start",
  },
  commentItemContainer: {
    marginVertical: 10,
    padding: 10,
    borderWidth: 1,
    borderColor: 'lightgray',
  },
  commentText: {
    fontSize: 12
  },
  commentButtonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginVertical: 10,
  },
  replyContainer: {
    marginLeft: 20,
    padding: 10,
    borderWidth: 1,
    borderColor: 'lightgray',
  },
  containerForOfferButton: {
    padding: 15,
    // width: "50%",
    // marginBottom: 10,
    alignItems: "center",
    borderRadius: 10,
    backgroundColor: colors.lightpink,
  },
  buttonTextForOffer: {
    fontWeight: "bold",
    color: "#fff",
  },
  offerInputContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginHorizontal: 10,
    backgroundColor: "lightgrey",
    padding: 5,
    borderColor: "black",
    borderRadius: 8

  },
  offerInput: {
    borderWidth: 2,
    backgroundColor: colors.bisque,
    // outlineColor: colors.lightpink,
    backgroundColor: "#fff",
    padding: 8,
    borderRadius: 5,
    justifyContent: "center",
  },
  container: {
    // width: "100%",
    backgroundColor: colors.light,
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  topBarContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 20,
    paddingLeft: 20
  },
  toBarText: {
    fontSize: 15,
    fontWeight: "600",
  },
  bodyContainer: {
    width: "100%",
    // flexDirection: "column",
    // backgroundColor: colors.light,
    // alignItems: "center",
    // justifyContent: "flex-start",
    // flex: 1,
  },
  productImageContainer: {
    width: "100%",
    backgroundColor: colors.light,
    flexDirecion: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: 0,
  },
  productInfoContainer: {
    width: "100%",
    flex: 3,
    backgroundColor: colors.white,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    elevation: 25,
  },
  productImage: {
    height: 300,
    width: 300,
    resizeMode: "contain",
  },
  productInfoTopContainer: {
    marginTop: 20,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    height: "100%",
    width: "100%",
    flex: 1,
  },
  productInfoBottomContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.light,
    width: "100%",
    height: 140,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
  },
  productButtonContainer: {
    padding: 20,
    paddingLeft: 40,
    paddingRight: 40,
    backgroundColor: colors.white,
    width: "100%",
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  productNameContainer: {
    padding: 5,
    paddingLeft: 20,
    display: "flex",
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  productNameText: {
    fontSize: 20,
    fontWeight: "bold",
  },
  infoButtonContainer: {
    padding: 5,
    paddingRight: 0,
    display: "flex",
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  wishlistButtonContainer: {
    height: 50,
    width: 80,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: colors.light,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
  },
  sameLineLayout: {
    display: "flex",
    flexDirection: "row",
    paddingRight: 5
  },
  productDetailContainer: {
    padding: 5,
    paddingLeft: 20,
    paddingRight: 20,
    // display: "flex",
    width: "100%",
    // flexDirection: "column",
    // alignItems: "center",
    // justifyContent: "space-between",
    elevation: 5,
  },
  secondaryTextSm: { fontSize: 15, fontWeight: "bold" },
  primaryTextSm: { color: colors.primary, fontSize: 15, fontWeight: "bold" },
  productNonDescriptionContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "left",
    paddingLeft: 20,
    paddingRight: 20,
    marginRight: 20,
    margingLeft: 20
  },
  productDescriptionContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    paddingLeft: 20,
    paddingRight: 20,
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 40,
    height: 40,
    backgroundColor: colors.white,
    borderRadius: 20,
  },
  counterContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    marginRight: 50,
  },
  counter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 5,
  },
  counterButtonContainer: {
    display: "flex",
    width: 30,
    height: 30,
    marginLeft: 10,
    marginRight: 10,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.muted,
    borderRadius: 15,
    elevation: 2,
  },
  counterButtonText: {
    fontSize: 20,
    fontWeight: "bold",
    color: colors.white,
  },
  counterCountText: {
    fontSize: 20,
    fontWeight: "bold",
  },
  cartIconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cartItemCountContainer: {
    position: "absolute",
    zIndex: 10,
    top: -10,
    // left: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 22,
    width: 22,
    backgroundColor: colors.danger,
    borderRadius: 11,
  },
  cartItemCountText: {
    color: colors.white,
    fontWeight: "bold",
    fontSize: 10,
  },
  commentsContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    marginRight: 50,
  }
});
