import {
    StyleSheet,
    Text,
    StatusBar,
    View,
    TouchableOpacity,
    TextInput,
    ScrollView,
    Pressable
} from "react-native";
import React, { useState, useEffect } from "react";
import { colors, messages, network, ORDER_STATUS, formatDateStringToHumanReadable, customStylesForStepIndicator } from "../../constants";
import { Ionicons, FontAwesome } from "@expo/vector-icons";
import ProgressDialog from "react-native-progress-dialog";
import BasicProductList from "../../components/BasicProductList/BasicProductList";
import CustomButton from "../../components/CustomButton";
import DropDownPicker from "react-native-dropdown-picker";
import { useToast } from "react-native-toast-notifications";
import CustomInput from "../../components/CustomInput";
import Ratings from "../../components/Ratings";
import AlertPolyfill from "../../components/AlertPolyfill/AlertPolyfill";
import CustomIconButton from "../../components/CustomIconButton/CustomIconButton";
import StepIndicator from "react-native-step-indicator";
import { useAuthStore } from "../../states/store";
import AskLogin from "../../components/AskLogin";

// This screen is used commonly for as saleDetailsScreen (i.e orders you received as seller) as well
// as PurchaseDetailScreen( i.e purchases you placed as buyer)
// We inject Ratings component if you view this page as buyer so that buyer can rate seller
const uuids = [];

const ViewSaleDetailsScreen = ({ navigation, route }) => {
    const toast = useToast();
    const { saleId } = route.params;
    const user = useAuthStore((state) => state.user);
    const [saleDetail, setSaleDetail] = useState();
    console.log("saleDetail", saleDetail);
    const [isLoading, setIsLoading] = useState(false);
    const [label, setLabel] = useState("Yükleniyor...");
    const [totalCost, setTotalCost] = useState(0);
    const [address, setAddress] = useState("");
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(null);
    // start as null and pass to Ratings object. If user rates, we will update the Ratings objects.
    const [updatedSellerRating, setUpdatedSellerRating] = useState(null);
    const [sellerRatingCount, setSellerRatingCount] = useState(null);
    const [statusDisable, setStatusDisable] = useState(false);
    const [isRatingSubmitted, setIsRatingSubmitted] = useState(false);
    const [handleRefresh, setHandleRefresh] = useState(0)
    const [items, setItems] = useState([
        { label: ORDER_STATUS.PENDING, value: ORDER_STATUS.PENDING },
        { label: ORDER_STATUS.SHIPPED, value: ORDER_STATUS.SHIPPED },
        { label: ORDER_STATUS.DELIVERED, value: ORDER_STATUS.DELIVERED },
    ]);
    const [trackingNumber, setTrackingNumber] = useState();
    const labels = ["Ödeme", "Kargo", "Teslim"];
    // Create an array to hold the state for each item's dropdown value
    const [dropdownValues, setDropdownValues] = useState([]);

    const [radioButtonValues, setRadioButtonValues] = useState([]);

    const [trackingNumbers, setTrackingNumbers] = useState([]);

    const [openStates, setOpenStates] = useState([]);
    const [radioButton, setRadioButton] = useState(true);
    const generateRandomUUID = () => {
        // Generates a random 8-character hexadecimal string
        const segment = () =>
            Math.floor((1 + Math.random()) * 0x100000000).toString(16).substring(1);

        // Combine 4 segments to create a UUID-like string
        return `${segment()}`;
    }

    const determineStepForStepIndicator = (status) => {
        console.log("determineStepForStepIndicator status", status)
        var stepNumber = 1
        if (status == ORDER_STATUS.PENDING) {
            stepNumber = 1
        } else if (status == ORDER_STATUS.SHIPPED) {
            stepNumber = 2
        } else if (status == ORDER_STATUS.DELIVERED) {
            stepNumber = 3
        }
        return stepNumber
    }


    const submitRating = (productId, rating) => {
        setIsLoading(true);
        console.log("saleDetail", saleDetail, "rating", rating, "productId", productId);
        var myHeaders = new Headers();
        myHeaders.append("x-auth-token", user?.token);
        myHeaders.append("Content-Type", "application/json")

        var rateRequestOptions = {
            method: "POST",
            headers: myHeaders,
            redirect: "follow",
            body: JSON.stringify({ ratingValue: rating, productId: productId })
        };

        fetch(`${network.serverip}/sales/${saleId}/rate-seller`, rateRequestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    setIsLoading(false);
                    toast.show("Oylamanız Başarıyla Gönderildi", { placement: "bottom", type: "success_with_close_button" });
                    {/**result looks like this
          //   {
          //     "success": true,
          //     "message": "rating is successfully applied",
          //     "updatedSellerRatings": 4,
          //     "updatedSellerTotalRatingsCount": 5
          // }
        */}
                    setUpdatedSellerRating(result.updatedSellerRatings);
                    setSellerRatingCount(result.updatedSellerTotalRatingsCount);
                    setIsRatingSubmitted(true); // we dont want to allow multiple submissions. Otherwise, users would just click 5 star repeatedly.
                } else if (result.message === "Buyer already rated seller for this product.") {
                    setIsLoading(false);
                    AlertPolyfill(
                        `Çoktan Oylamanızı Verdiniz`,
                        `Bu satıcıya çoktan oy verdiniz ve bir daha oy veremezsiniz`,
                        [
                            {
                                text: "Tamam",
                                onPress: () => {
                                },
                            },
                        ]
                    );
                    return;
                } else {
                    setIsLoading(false);
                    toast.show(messages.genericErrorMessage, {
                        placement: "bottom",
                        type: "danger_with_close_button"
                    });
                }
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.show(messages.genericErrorMessage, {
                    placement: "bottom",
                    type: "danger_with_close_button"
                });
                console.log("error", error);
            });
    }

    const fetchSaleDetails = () => {
        setIsLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("x-auth-token", user?.token);
        myHeaders.append("Content-Type", "application/json")

        var saleDetailOptions = {
            method: "GET",
            headers: myHeaders,
        };
        fetch(`${network.serverip}/sales/${saleId}`, saleDetailOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log("order result", result)
                if (result.success) {
                    setIsLoading(false);
                    setSaleDetail(result.data);
                } else {
                    setIsLoading(false);
                    toast.show(messages.genericErrorMessage, {
                        placement: "bottom",
                        type: "danger_with_close_button"
                    });
                }
            })
            .catch((error) => {
                setIsLoading(false);
                toast.show(messages.genericErrorMessage, {
                    placement: "bottom",
                    type: "danger_with_close_button"
                });
                console.log("error", error);
            });
    }

    //method to update the status using API call
    const handleUpdateStatus = (id) => {
        setIsLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("x-auth-token", user?.token);

        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };
        console.log("id #1", id)
        fetch(
            `${network.serverip}/${user?._id}/sales/sale-status?orderNumber=${id}&status=${value}`,
            requestOptions
        ) //API call
            .then((response) => response.json())
            .then((result) => {
                console.log("update sale status", result);
                if (result.success == true) {
                    toast.show(`Satışınız başarıyla güncellendi.`, { placement: 'bottom', type: "success_with_close_button" });
                    setIsLoading(false);
                    setValue(value) // update the status on the client side without another fetch
                } else {
                    toast.show(messages.genericErrorMessage, { placement: "bottom", type: "danger_with_close_button" })
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                toast.show(messages.genericErrorMessage, { placement: "bottom", type: "danger_with_close_button" })
                console.log("error", error);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        const fetchDetails = async () => {
            try {
                console.log("Fetching sale details...");
                fetchSaleDetails();
            } catch (error) {
                console.error("Error in fetching sale details:", error);
            }
        };

        fetchDetails();
    }, [saleId]);

    useEffect(() => {
        if (saleDetail?.items) {
            const items = saleDetail.items;
            setRadioButtonValues(items.map(() => true));
            setDropdownValues(items.map(() => null));
            setTrackingNumbers(items.map(() => ''));
            setOpenStates(items.map(() => false));
        }
    }, [saleDetail]);

    // calculate the total cost and set the all requried variables on initial render
    useEffect(() => {
        // if (saleDetail?.status == ORDER_STATUS.DELIVERED) {
        //   setStatusDisable(true);
        // } else {
        //   setStatusDisable(false);
        // }
        // setValue(saleDetail?.status);
        setIsLoading(true);
        setTotalCost(
            saleDetail?.items.reduce((accumulator, object) => {
                return accumulator + object.price * object.quantity;
            }, 0) // calculate the total cost
        );
        setIsLoading(false);
        // Generate a random UUID-like string for each item
        saleDetail?.items.forEach(() => {
            const uuid = generateRandomUUID();
            uuids.push(uuid);
        });
    }, [handleRefresh]);


    const handleDropdownChange = (index, newValue) => {
        // Create a copy of the dropdownValues array
        const newDropdownValues = [...dropdownValues];
        // Update the value at the specified index
        newDropdownValues[index] = newValue;
        // Update the state with the new array
        setDropdownValues(newDropdownValues);
    };


    const handleTrackingNumberChange = (index, newValue) => {
        // Create a copy of the trackingNumbers array
        const newTrackingNumbers = [...trackingNumbers];
        // Update the value at the specified index
        newTrackingNumbers[index] = newValue;
        // Update the state with the new array
        setTrackingNumbers(newTrackingNumbers);
    };

    const handleRadioButtonChange = (index, newValue) => {
        // Create a copy of the trackingNumbers array
        const newRadioButtonValues = [...radioButtonValues];
        // Update the value at the specified index
        newRadioButtonValues[index] = newValue;
        // Update the state with the new array
        setRadioButtonValues(newRadioButtonValues);
        console.log("index", index, "radioButtonValues", radioButtonValues)
    };


    const handleOpenChange = (index, newOpenState) => {
        // Create a copy of the openStates array
        const newOpenStates = [...openStates];
        // Update the open state at the specified index
        newOpenStates[index] = newOpenState;
        // Update the state with the new array
        setOpenStates(newOpenStates);
    };


    return (
        <View style={styles.container}>
            <ProgressDialog visible={isLoading} label={label} />
            <StatusBar backgroundColor={colors.primary}></StatusBar>
            <View style={styles.TopBarContainer}>
                <TouchableOpacity
                    onPress={() => {
                        navigation.goBack(); // Dont change. We might come from different screens, admin panel or sales screen.
                    }}
                >
                    <Ionicons
                        name="arrow-back-circle-outline"
                        size={30}
                        color={colors.muted}
                    />
                </TouchableOpacity>
                <TouchableOpacity
                    onPress={() => {
                        setIsLoading(true);
                        setHandleRefresh((prevState => prevState + 1))
                        setIsLoading(false);
                    }
                    }
                    style={styles.refreshTimeContainer}>
                    <FontAwesome name="refresh" size={24} color="black" />
                    <Text style={styles.secondarytextSm}>{formatDateStringToHumanReadable(Date.now())}</Text>
                </TouchableOpacity>
            </View>
            <View style={styles.screenNameContainer}>
                <View>
                    <Text style={styles.screenNameText}>Satış Detayları</Text>
                </View>
                <View>
                    <Text style={styles.screenNameParagraph}>
                        Satış hakkında tüm detayları burada görebilirsiniz
                    </Text>
                </View>
            </View>
            {saleDetail ? (
                <View
                    style={styles.bodyContainer}
                    showsVerticalScrollIndicator={false}
                >
                    <View>
                        <Text style={styles.containerNameText}>Satış Bilgileri</Text>
                    </View>
                    <View style={styles.orderInfoContainer}>
                        <Text style={styles.secondarytextMedian}>
                            Sipariş # {saleDetail?._id}
                        </Text>
                        <Text style={styles.secondarytextMedian}>Toplam Tutar : {saleDetail?.totalAmount}₺</Text>
                        <Text style={styles.secondarytextMedian}>
                            Sipariş Edildi: {formatDateStringToHumanReadable(saleDetail?.createdAt)}
                        </Text>
                        {saleDetail?.shippedOn && (
                            <Text style={styles.secondarytextSm}>
                                Postaya Verildi: {formatDateStringToHumanReadable(saleDetail?.shippedOn)}
                            </Text>
                        )}
                        {saleDetail?.deliveredOn && (
                            <Text style={styles.secondarytextSm}>
                                Teslim Edildi: {formatDateStringToHumanReadable(saleDetail?.deliveredOn)}
                            </Text>
                        )}
                        <Text style={styles.secondarytextMedian}>Platformun Payı %10'dur : {(saleDetail?.totalAmount * 0.1).toFixed(2)}$</Text>
                        <Text style={styles.secondarytextMedian}>Size Yatıcak Beklenen Ödeme (%90): {(saleDetail?.totalAmount * 0.9).toFixed(2)}$</Text>
                        <Text style={styles.secondarytextMedian}>Ödemeniz, kargo teslim edildikten 5 gün sonra yatırılacaktır.</Text>
                        <Text style={styles.secondarytextMedian}>Siparişteki Ürün Miktarı: {saleDetail?.items.reduce((accumulator, item) => item.quantity + accumulator, 0)}</Text>
                        <Text style={styles.secondarytextMedian}>Siparişinizde birden fazla ürün var ise:</Text>
                        <Text style={styles.secondarytextMedian}>Ürünleri Alıcı Bazında Gruplama: Tüm ürünleri, alıcı bilgilerine göre gruplayın.</Text>
                        <Text style={styles.secondarytextMedian}>Tek Takip Numarası Oluşturma: Her grup için tek bir takip numarası oluşturun.</Text>
                        <Text style={styles.secondarytextMedian}>Sipariş Takip Numarası Güncelleme: Sipariş kaydını güncellerken, aynı gruptaki ürünler için aynı takip numarasını kullanın.</Text>

                    </View>
                    <View style={styles.containerNameContainer}>
                        <View>
                            <Text style={styles.containerNameText}>Gönderim ve Fatura Adresi</Text>
                        </View>
                    </View>
                    <View style={styles.ShipingInfoContainer}>
                        <Text style={styles.secondarytextMedian}>
                            Alıcı Adı: {saleDetail?.buyerFirstName}
                        </Text>
                        <Text style={styles.secondarytextMedian}>
                            Alıcı Soyadı: {saleDetail?.buyerLastName}
                        </Text>
                        <Text style={styles.secondarytextMedian}>
                            Alıcı Telefonu: {saleDetail?.buyerPhone}
                        </Text>
                        <Text style={styles.secondarytextMedian}>
                            Alıcı Emaili: {saleDetail?.buyerEmail}
                        </Text>
                        <Text style={styles.secondarytextMedian}>
                            Alıcı Adresi: {`${saleDetail?.streetAddress}, ${saleDetail?.district}, ${saleDetail?.city}, ${saleDetail.postalCode}`}
                        </Text>
                    </View>
                    <View style={styles.containerNameContainer}>
                        <View>
                            <Text style={styles.containerNameText}>Teslimat Detayları</Text>
                        </View>
                    </View>
                    <View style={styles.orderItemsContainer}>
                        <View
                            style={styles.orderSummaryContainer}
                        >
                            {saleDetail?.items?.map((item, index) => (
                                <View style={styles.orderSummaryContainerForEachProduct} key={index}>
                                    <StepIndicator
                                        customStyles={customStylesForStepIndicator}
                                        currentPosition={determineStepForStepIndicator(item.status)}
                                        stepCount={3}
                                        labels={labels}
                                    />
                                    <BasicProductList
                                        seller={item?.sellerUser?.username}
                                        quantity={item?.quantity}
                                        description={item?.product.description}
                                        imageUri={`${network.serverip}/uploads/${item.product.filename}`}
                                        title={item?.product.title}
                                        price={item?.product.price}
                                        status={item?.status}
                                    />
                                    <View style={{ padding: 5 }}>
                                        <Text style={{ fontWeight: 'bold' }}>
                                            Kargo seçeneklerimiz
                                        </Text>
                                    </View>
                                    <View style={{ flexDirection: "row", flexWrap: 'wrap', justifyContent: "space-around", padding: 10 }}>
                                        <View>
                                            <TouchableOpacity onPress={() => {
                                                handleRadioButtonChange(index, true)
                                            }}>
                                                <Text>
                                                    <Ionicons name={radioButtonValues[index] ? "radio-button-on" : "radio-button-off"} size={18} color="green" />
                                                    MNG kargo kupon kodu istiyorum.
                                                </Text>
                                            </TouchableOpacity>
                                        </View>
                                        <View>
                                            <TouchableOpacity onPress={() => {
                                                handleRadioButtonChange(index, false)
                                            }}>
                                                <Text>
                                                    <Ionicons name={!radioButtonValues[index] ? "radio-button-on" : "radio-button-off"} size={18} color="green" />
                                                    Kargomu kuponsuz göndereceğim.
                                                </Text>
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                    {radioButtonValues[index] ?
                                        (<View style={styles.mainBottomContainer}>
                                            <Text style={styles.partnerTrackingNumber}>MNG Kargo Kodu: {uuids[index]}</Text>
                                            <Text>
                                                Herhangi bir MNG şubesine gidin. Bu kodu gösterin ve ucuz kargo ücretlerinden faydalanın. Bu kodu kullanırsanız, sistemimiz otomatik olarak kuryenizin nerede olduğunu alıcıya gösterecektir.
                                            </Text>
                                        </View>) : (
                                            <View style={styles.mainBottomContainer}>
                                                <Text>
                                                    Eğer partner şirketimiz MNG'yi kullanmak yerine dilediğiniz başka bir kargo şirketini kullanmak istiyorsanız, 'Kargomu kuponsuz göndereceğim' seçeneğini seçin. Takip numarasını girmeyi unutmayın.
                                                </Text>
                                                <View style={styles.bottomContainer}>
                                                    <CustomInput
                                                        value={trackingNumbers[index]}
                                                        onChangeText={(newValue) =>
                                                            handleTrackingNumberChange(index, newValue)
                                                        }
                                                        keyboardType={"number-pad"}
                                                        placeholder="TAKİP NUMARASINI BURAYA GİRİN"
                                                        labelStyle={{ color: colors.muted, }}
                                                        style={{
                                                            paddingLeft: 10,
                                                            borderWidth: 3, borderRadius: 5, marginRight: 5, height: 40
                                                        }}
                                                    />
                                                    <CustomButton
                                                        text={"TAKİP NUMARASI GIR"}
                                                        onPress={() => handleUpdateStatus(saleDetail?._id)}
                                                    />
                                                </View>
                                            </View>)}
                                </View>
                            ))}
                        </View>
                    </View>
                </View>) : <>
                <View style={styles.bodyContainer}>
                    <View style={styles.noSalesContainer}>
                        <Text style={styles.noSalesViewText}>
                            Satış bulunamadı.
                        </Text>
                    </View>
                    {!user && <View style={{ margin: 5 }}><AskLogin /></View>}
                </View>
            </>}
        </View>
    );
};

export default ViewSaleDetailsScreen;

const styles = StyleSheet.create({
    noSalesContainer: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start"
    },
    noSalesViewText: {
        fontSize: 18, // Adjust the font size as needed
        fontWeight: 'bold', // Bold text
        marginTop: 5,
        color: "black",
        padding: 2,
        backgroundColor: "yellow",
    },
    partnerTrackingNumber: {
        color: 'green', // Change the color to green
        fontSize: 18,
        fontWeight: 'bold',
        textShadowOffset: { width: 2, height: 2 },
        textShadowRadius: 4,
    },
    refreshTimeContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    orderSummaryContainerForEachProduct: {
        borderWidth: 2,
        borderColor: "black",
        borderRadius: 4,
        padding: 2,
        marginBottom: 20
    },
    ratingsContainer: {
        padding: 10,
    },
    container: {
        flexDirecion: "row",
        backgroundColor: colors.light,
        alignItems: "center",
        justifyContent: "center",
        padding: 20,
        paddingBottom: 0,
        flex: 1,
    },
    TopBarContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },

    screenNameContainer: {
        marginTop: 10,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        marginBottom: 5,
    },
    screenNameText: {
        fontSize: 30,
        fontWeight: "800",
        color: colors.muted,
    },
    screenNameParagraph: {
        marginTop: 10,
        fontSize: 15,
    },
    bodyContainer: { flex: 1, width: "100%", padding: 5 },
    ShipingInfoContainer: {
        marginTop: 5,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        backgroundColor: colors.white,
        padding: 10,
        borderRadius: 10,
        borderColor: colors.muted,
        elevation: 5,
        marginBottom: 10,
    },
    containerNameContainer: {
        marginTop: 10,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
    },
    containerNameText: {
        fontSize: 18,
        fontWeight: "800",
        color: colors.muted,
    },
    secondarytextSm: {
        color: colors.muted,
        fontSize: 13,
    },
    orderItemsContainer: {
        marginTop: 5,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        backgroundColor: colors.white,
        padding: 10,
        borderRadius: 10,

        borderColor: colors.muted,
        elevation: 3,
        marginBottom: 10,
    },
    orderItemContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    orderItemText: {
        fontSize: 13,
        color: colors.muted,
    },
    orderSummaryContainer: {
        backgroundColor: colors.white,
        borderRadius: 10,
        padding: 10,
        width: "100%",
        marginBottom: 5,
    },
    mainBottomContainer: {
        width: "100%",
        zIndex: -9999
    },
    bottomContainer: {
        backgroundColor: colors.white,
        width: "100%",
        borderTopLeftRadius: 10,
        borderTopEndRadius: 10,
        elevation: 5,
        flexWrap: 'wrap',
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        zIndex: -9999,
        padding: 20
    },
    orderInfoContainer: {
        marginTop: 5,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        backgroundColor: colors.white,
        padding: 10,
        borderRadius: 10,

        borderColor: colors.muted,
        elevation: 1,
        marginBottom: 10,
    },
    primarytextMedian: {
        color: colors.primary,
        fontSize: 15,
        fontWeight: "bold",
    },
    secondarytextMedian: {
        color: colors.muted,
        fontSize: 15,
        fontWeight: "bold",
    }
});
