import {
  StyleSheet,
  Text,
  View,
  StatusBar,
  TouchableOpacity,
  Alert,
  WebView,
  ScrollView
} from "react-native";
import React, { useState } from "react";
import UserProfileCard from "../../components/UserProfileCard/UserProfileCard";
import { Ionicons } from "@expo/vector-icons";
import { MaterialIcons } from "@expo/vector-icons";
import { network, colors } from "../../constants";

const ReturnTermsScreen = ({ navigation, route }) => {
  const [showBox, setShowBox] = useState(true);
  const [error, setError] = useState("");

  return (
    <View style={styles.container}>
      <StatusBar backgroundColor={colors.primary}></StatusBar>
      <View style={styles.TopBarContainer}>
        <TouchableOpacity
          onPress={() => {
            navigation.goBack(null);
          }}
        >
          <Ionicons
            name="arrow-back-circle-outline"
            size={30}
            color={colors.muted}
          />
        </TouchableOpacity>
      </View>
      <View style={styles.screenNameContainer}>
        <Text style={styles.screenNameText}>Teslimat ve İade Şartları</Text>
      </View>
      <View style={styles.OptionsContainer}>
        <ScrollView>
        <View style={styles.primaryTextContainer}>
          <Text style={styles.primaryText}>Teslimat ve İade Şartları</Text>
        </View>
          <Text>
            {`
            İptal ettiğiniz ürünün ücret iadesi bankanıza bağlı olarak değişkenlik gösterebilir. Bu süre yaklaşık 1 haftayı bulabilir.

            İade ettiğiniz ürünün ücret iade süreci aşağıdaki gibidir;

            • Ürün satıcıya ulaştıktan sonra en geç 48 saat içerisinde iade şartlarına uygunluğu kontrol edilir.

            • Ürün iade şartlarına uygunsa, iadeniz onaylanır ve ücret iadeniz bankanıza bağlı olarak 2-10 iş günü içerisinde ödeme yapmış olduğunuz kartınıza yansır.

            • Ürün iade şartlarına uygun değilse adresinize geri gönderilir.

            Not:

            • Bankanıza ücret iadesi yapıldığında üyelik e-posta adresinize bilgilendirme mesajı gönderilir. Tutarın kartınıza/hesabınıza yansıma süresi bankanıza bağlıdır.

            • Banka kartına yapılan iadenlerin hesabınıza yansıma süresi daha uzundur.

            • Bankanızdan ücret iadesi kontrolü yapmak için “Hesabım” > “Siparişlerim“ adımından referans numaranızı görüntüleyebilirsiniz.

            • Taksitli yapılan alışverişlerin ücreti bankaya tek seferde ödenir ancak bankanız bu tutarı kredi kartınıza taksitli bir şekilde iade eder.
            `}
          </Text>
          </ScrollView>
      </View>
    </View>
  );
};

export default ReturnTermsScreen;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexDirecion: "row",
    backgroundColor: colors.light,
    alignItems: "center",
    justifyContent: "flex-start",
    padding: 20,
    flex: 1,
  },
  TopBarContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  UserProfileCardContianer: {
    width: "100%",
    height: "25%",
  },
  primaryTextContainer: {
    padding: 20,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "100%",
    paddingTop: 10,
    paddingBottom: 10,
  },
  primaryText: {
    fontSize: 20,
    fontWeight: "bold",
  },
  screenNameContainer: {
    marginTop: 10,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: 10,
  },
  screenNameText: {
    fontSize: 30,
    fontWeight: "800",
    color: colors.muted,
  },
  OptionsContainer: {
    width: "100%",
    flex: 1,
    justifyContent:'center'
  },
});
