import {
  StyleSheet,
  Text,
  StatusBar,
  View,
  ScrollView,
  TouchableOpacity
} from "react-native";
import React, { useState, useEffect } from "react";
import { colors, network } from "../../constants";
import { Ionicons } from "@expo/vector-icons";
import ProgressDialog from "react-native-progress-dialog";
import OptionList from "../../components/OptionList/OptionList";
import { useAuthStore } from "../../states/store";


const SellerScreen = ({ navigation, route }) => {
  const user = useAuthStore((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [refeshing, setRefreshing] = useState(false);

  return (
    <View style={styles.container}>
      <StatusBar backgroundColor={colors.primary}></StatusBar>
      {/* <View style={styles.topBarContainer}>
        <TouchableOpacity onPress={() => navigation.navigate("tabs", { screen: "buy"})}>
          <Ionicons name="home-outline" size={30} color={colors.primary} />
        </TouchableOpacity>
      </View> */}
      <ProgressDialog visible={isLoading} label={"Yükleniyor..."} />
      <View style={styles.screenNameContainer}>
        <Text style={styles.screenNameText}>Satıcı Paneli</Text>
      </View>
    <ScrollView style={styles.OptionsContainer}>
          <OptionList
            text={"Satışa Sunduğum Ürünler"}
            Icon={Ionicons}
            iconName={"rocket"}
            onPress={() =>
              navigation.navigate("viewmyproducts")
            }
          />
          <OptionList
            text={"Aldığım Teklifler"}
            Icon={Ionicons}
            iconName={"flame"}
            onPress={() =>
              navigation.navigate("viewoffersreceived")
            }
          />
          <OptionList
            text={"Satışlarım"}
            Icon={Ionicons}
            iconName={"cart"}
            onPress={() =>
              navigation.navigate("viewsales")
            }
          />
            <OptionList
            text={"Yeni Kitap Satışa Sun"}
            Icon={Ionicons}
            iconName={"add-outline"}
            onPress={() =>
              navigation.navigate("addproduct")
            }
          />
      </ScrollView>
    </View>
  );
};

export default SellerScreen;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexDirecion: "row",
    backgroundColor: colors.light,
    alignItems: "center",
    justifyContent: "flex-start",
    padding: 20,
    flex: 1,
  },
  topBarContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 20,
  },
  toBarText: {
    fontSize: 15,
    fontWeight: "600",
  },
  screenNameContainer: {
    marginTop: 10,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: 10,
  },
  screenNameText: {
    fontSize: 30,
    fontWeight: "800",
    color: colors.muted,
  },
  screenNameParagraph: {
    marginTop: 5,
    fontSize: 15,
  },
  bodyContainer: {
    width: "100%",
    flexDirecion: "row",
    backgroundColor: colors.light,
    alignItems: "center",
    justifyContent: "flex-start",
    flex: 1,
  },
  emptyView: {
    height: 20,
  },
  ListContiainerEmpty: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  secondaryTextSmItalic: {
    fontStyle: "italic",
    fontSize: 15,
    color: colors.muted,
  },  
  OptionsContainer: {
    width: "100%",
  },
});
