import {
  StyleSheet,
  Text,
  StatusBar,
  View,
  ScrollView,
  TouchableOpacity,
  RefreshControl,
} from "react-native";
import React, { useState, useEffect } from "react";
import { colors, messages, network, formatDateStringToHumanReadable } from "../../constants";
import { Ionicons } from "@expo/vector-icons";
import CustomInput from "../../components/CustomInput";
import ProgressDialog from "react-native-progress-dialog";
import { useToast } from "react-native-toast-notifications";
import BasicProductList from "../../components/BasicProductList";
import { useAuthStore } from "../../states/store";
import AskLogin from "../../components/AskLogin"
const ViewAllTransactionsScreen = ({ navigation, route }) => {
  const user = useAuthStore((state) => state.user);

  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [refeshing, setRefreshing] = useState(false);
  const [alertType, setAlertType] = useState("error");
  const [label, setLabel] = useState("Yükleniyor...");
  const [sales, setSales] = useState([]);
  const [filterItem, setFilterItem] = useState("");
  const [foundItems, setFoundItems] = useState([]);

  //method call on pull refresh
  const handleOnRefresh = () => {
    setRefreshing(true);
    fetchSales();
    setRefreshing(false);
  };

  //method to navigate to order detail screen of specific order
  const handleSaleDetails = (saleId) => {
    navigation.push("viewsaledetails", {
      saleId: saleId
    });
  };

  //method the fetch the order data from server using API call
  const fetchSales = () => {
    var myHeaders = new Headers();
    myHeaders.append("x-auth-token", user?.token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    setIsLoading(true);
    fetch(`${network.serverip}/admin/transactions`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          setSales(result.data);
          setFoundItems(result.data);
        } else {
          // toast.show(messages.needLoginMessage, { placement: "bottom", type: "danger_with_close_button" })
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.show(messages.genericErrorMessage, { placement: "bottom", type: "danger_with_close_button" })
        console.log("error", error);
      });
  };

  //method to filer the orders for by title [search bar]
  const filter = () => {
    const keyword = filterItem;
    if (keyword !== "") {
      const results = sales?.filter((item) => {
        return (item?._id.toLowerCase().includes(keyword.toLowerCase()) || item?.buyerEmail.toLowerCase().includes(keyword.toLowerCase()));
      });
      setFoundItems(results);
    } else {
      setFoundItems(sales);
    }
  };
  //filter the data whenever filteritem value change
  useEffect(() => {
    filter();
  }, [filterItem]);


  //fetch the orders on initial render
  useEffect(() => {
    fetchSales();
  }, [user]);

  return (
    <View style={styles.container}>
      <ProgressDialog visible={isLoading} label={label} />
      <StatusBar backgroundColor={colors.primary}></StatusBar>
      <View style={styles.TopBarContainer}>
        <TouchableOpacity
          onPress={() => {
            navigation.navigate("admindashboard");
          }}
        >
          <Ionicons
            name="arrow-back-circle-outline"
            size={30}
            color={colors.muted}
          />
        </TouchableOpacity>
      </View>
      <View style={styles.screenNameContainer}>
        <View>
          <Text style={styles.screenNameText}>Bütün Platform Geneli Satışlarınızı Görün</Text>
        </View>
        <View>
          <Text style={styles.screenNameParagraph}>Bütün Platform Geneli Satışları Burada Görebilirsiniz.</Text>
        </View>
      </View>
      <CustomInput
        radius={5}
        placeholder={"Sipariş Numarası veya Alıcı Emaili ile Ara..."}
        value={filterItem}
        setValue={setFilterItem}
      />
      <ScrollView
        style={{ flex: 1, width: "100%", padding: 2 }}
        showsVerticalScrollIndicator={false}
        refreshControl={
          <RefreshControl refreshing={refeshing} onRefresh={handleOnRefresh} />
        }
      >
        {foundItems && foundItems.length > 0 ? (
          <>
          {filterItem && <Text>{`Aranan Sipariş Numarası veya Alıci Emaili: ${filterItem}`}</Text>}
          {foundItems.map((sale, index) => {
            return (
              <View key={index} style={styles.saleContainer}>
                <View>
                  <View style={styles.innerRow}>
                    <Text style={styles.primaryText}>Sipariş # {sale._id}</Text>
                    <View style={styles.timeDateContainer}>
                      <Text style={styles.secondaryTextSm}>
                        Sipariş Edildi : {formatDateStringToHumanReadable(sale?.createdAt)}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={styles.itemsContainer}>
                  {sale.items.map((item, index) => {
                    return (
                      <View key={index} style={styles.innerRow}>
                        <BasicProductList seller={item?.sellerUser?.username} quantity={item.quantity} description={item.product.description} imageUri={`${network.serverip}/uploads/${item.product.filename}`} title={item.product.title} price={item.product.price} status={item.status} />
                      </View>
                    )
                  })}
                </View>
                <View style={styles.innerRow}>
                  <TouchableOpacity style={styles.detailButton} onPress={() => handleSaleDetails(sale._id)}>
                    <Text>Detaylar</Text>
                  </TouchableOpacity>
                </View>
              </View>
            )
          })}
          </>
        ) : (
          filterItem === "" ? (
            <>
              <View style={styles.noSalesContainer}>
                <Text style={styles.noSalesViewText}>Hiçbir Satış Yapmadınız</Text>
              </View>
              {!user && <View style={{ margin: 5 }}><AskLogin /></View>}
            </>
          ) : (
            <>
              <Text>{`Sipariş bulunamadı. Aranan sipariş numarası:  ${filterItem}`}</Text>
            </>

          ))}
      </ScrollView>
    </View>
  );
};

export default ViewAllTransactionsScreen;

const styles = StyleSheet.create({
  noSalesContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  noSalesViewText: {
    fontSize: 18, // Adjust the font size as needed
    fontWeight: 'bold', // Bold text
    marginTop: 5,
    color: "black",
    padding: 2,
    backgroundColor: "yellow",
  },
  secondaryTextSm: {
    fontSize: 11,
    color: colors.muted,
    fontWeight: "bold",
  },
  secondaryText: {
    fontSize: 14,
    color: colors.muted,
    fontWeight: "bold",
  },
  tertiaryText: {
    fontSize: 14,
  },
  timeDateContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  innerRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: 'wrap',
    alignItems: "center",
    width: "100%",
  },
  primaryText: {
    fontSize: 15,
    color: colors.dark,
    fontWeight: "bold",
  },
  saleContainer: {
    display: "flex",
    backgroundColor: colors.white,
    borderRadius: 10,
    padding: 10,
    marginBottom: 10,
    elevation: 1,
  },
  itemsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    height: "auto",
    backgroundColor: colors.white,
    borderRadius: 10,
    padding: 10,
    marginBottom: 10,
    elevation: 1,
  },
  container: {
    flexDirecion: "row",
    backgroundColor: colors.light,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    flex: 1,
  },
  TopBarContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  formContainer: {
    flex: 2,
    justifyContent: "flex-start",
    alignItems: "center",
    display: "flex",
    width: "100%",
    flexDirecion: "row",
    padding: 5,
  },

  buttomContainer: {
    width: "100%",
  },
  bottomContainer: {
    marginTop: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  screenNameContainer: {
    marginTop: 10,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  screenNameText: {
    fontSize: 30,
    fontWeight: "800",
    color: colors.muted,
  },
  screenNameParagraph: {
    marginTop: 5,
    fontSize: 15,
  },
  detailButton: {
    marginTop: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
    borderWidth: 1,
    padding: 5,
    borderColor: colors.muted,
    color: colors.muted,
    width: 100,
  },
});
