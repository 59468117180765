import {
  StyleSheet,
  Text,
  StatusBar,
  View,
  ScrollView,
  TouchableOpacity,
  RefreshControl,
  Alert,
} from "react-native";
import React, { useState, useEffect } from "react";
import { colors, messages, network } from "../../constants";
import { Ionicons } from "@expo/vector-icons";
import { AntDesign } from "@expo/vector-icons";
import ProductList from "../../components/ProductList/ProductList";
import CustomInput from "../../components/CustomInput";
import ProgressDialog from "react-native-progress-dialog";
import AlertPolyfill from "../../components/AlertPolyfill/AlertPolyfill";
import { useToast } from "react-native-toast-notifications";
import { useIsFocused } from '@react-navigation/native';
import { useAuthStore } from "../../states/store";

import AskLogin from "../../components/AskLogin";
const ViewMyProductsScreen = ({ navigation, route }) => {
  const isFocused = useIsFocused();
  const toast = useToast()
  const user = useAuthStore((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [alertType, setAlertType] = useState("error");

  const [label, setLabel] = useState("Yükleniyor...");
  const [products, setProducts] = useState([]);
  const [foundItems, setFoundItems] = useState([]);
  const [filterItem, setFilterItem] = useState("");

  var myHeaders = new Headers();
  myHeaders.append("x-auth-token", user?.token);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  var ProductListRequestOptions = {
    method: "GET",
    redirect: "follow",
    headers: myHeaders
  };

  //method call on pull refresh
  const handleOnRefresh = () => {
    setRefreshing(true);
    fetchProducts();
    setRefreshing(false);
  };

  //method to delete the specific order
  const handleDelete = (id) => {
    setIsLoading(true);
    console.log(`${network.serverip}/delete-product?id=${id}`);
    fetch(`${network.serverip}/delete-product?id=${id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          fetchProducts();
          toast.show("Ürün başarıyla silindi", { placement: "bottom", type: "success_with_close_button" });
        } else {
          toast.show("Ürünü silerken bir hata oluştu. Lütfen sonra tekrar deneyin", { placement: "bottom", type: "danger_with_close_button" });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.show("Ürünü silerken bir hata oluştu. Lütfen sonra tekrar deneyin", { placement: "bottom", type: "danger_with_close_button" });
        console.log("error", error);
      });
  };

  //method for alert
  const showConfirmDialog = (id) => {
    return AlertPolyfill(
      "Ürün Silmeyi Onayla",
      "Bu ürünü silmek istediginizden emin misiniz?",
      [
        {
          text: "Ürünü Sil",
          onPress: () => {
            handleDelete(id);
          },
        },
        {
          text: "Vazgeç",
        },
      ]
    );
  };

  //method the fetch the product data from server using API call
  const fetchProducts = () => {
    setIsLoading(true);
    fetch(`${network.serverip}/me/products`, ProductListRequestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          setProducts(result.data);
          setIsLoading(false);
        } else {
          // toast.show(messages.needLoginMessage, { placement: "bottom", type: "danger_with_close_button" });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        toast.show(messages.genericErrorMessage, { placement: "bottom", type: "danger_with_close_button" });
        setIsLoading(false);
      });
  };

  //method to filer the orders for by title [search bar]
  const filter = () => {
    const keyword = filterItem;
    if (keyword !== "") {
      const results = products?.filter((product) => {
        return product?.title.toLowerCase().includes(keyword.toLowerCase());
      });
      setFoundItems(results);
    } else {
      setFoundItems(products);
    }
  };

  // //filter the data whenever filteritem value change
  // useEffect(() => {
  //   // filter();
  // }, [filterItem]);

  //fetch the categories on initial render
  useEffect(() => {
    // if (user) {
    fetchProducts();
    // }
  }, [isFocused, user]);

  return (
    <View style={styles.container}>
      <ProgressDialog visible={isLoading} label={label} />
      <StatusBar backgroundColor={colors.primary}></StatusBar>
      <View style={styles.TopBarContainer}>
        <TouchableOpacity
          onPress={() => {
            navigation.navigate("tabs", { screen: "sell" })
          }}
        >
          <Ionicons
            name="arrow-back-circle-outline"
            size={30}
            color={colors.muted}
          />
        </TouchableOpacity>
        {/* <TouchableOpacity
          onPress={() => {
            navigation.navigate("addproduct");
          }}
        >
          <AntDesign name="plussquare" size={30} color={colors.muted} />
        </TouchableOpacity> */}
      </View>
      <View style={styles.screenNameContainer}>
        <View>
          <Text style={styles.screenNameText}>Satışa Sunduğum Ürünler</Text>
        </View>
        <View>
          <Text style={styles.screenNameParagraph}>Satışa Sunduğunuz Kitaplar Burada Gözükecektir.</Text>
        </View>
      </View>
      {/* <CustomInput
        radius={5}
        placeholder={"Ara..."}
        value={filterItem}
        setValue={setFilterItem}
      /> */}
      <ScrollView
        style={{ flex: 1, width: "100%" }}
        showsVerticalScrollIndicator={false}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={handleOnRefresh} />
        }
      >
        {products && products.length == 0 ? (
          <>
            <View style={styles.noProductsContainer}>
              <Text style={styles.noProductsViewText}>Hiçbir Ürün Satışa Sunmadınız</Text>
            </View>
            {!user && <View style={{ margin: 5 }}><AskLogin /></View>}
          </>
        ) : (
          products.map((product, index) => {
            return (
              <ProductList
                key={index}
                image={`${network.serverip}/uploads/${product?.filename}`}
                title={product?.title}
                category={product?.category}
                price={product?.price}
                author={product?.author}
                quantity={product?.quantity}
                condition={product?.condition}
                visitCount={product?.visitCount}
                description={`${product.description.slice(0, 25)}...`.replace("\n", " ")}
                onPressView={() => {
                  navigation.navigate("productdetail", { productId: product._id })
                  console.log("view is working " + product._id);
                }}
                onPressEdit={() => {
                  navigation.navigate("editproduct", {
                    product: product
                  });
                }}
                onPressDelete={() => {
                  showConfirmDialog(product._id);
                }}
              />
            );
          })
        )}
      </ScrollView>
    </View>
  );
};

export default ViewMyProductsScreen;

const styles = StyleSheet.create({
  noProductsContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  noProductsViewText: {
    fontSize: 18, // Adjust the font size as needed
    fontWeight: 'bold', // Bold text
    marginTop: 5,
    color: "black",
    padding: 2,
    backgroundColor: "yellow",
  },
  container: {
    flexDirecion: "row",
    backgroundColor: colors.light,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    flex: 1,
  },
  TopBarContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  formContainer: {
    flex: 2,
    justifyContent: "flex-start",
    alignItems: "center",
    display: "flex",
    width: "100%",
    flexDirecion: "row",
    padding: 5,
  },

  buttomContainer: {
    width: "100%",
  },
  bottomContainer: {
    marginTop: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  screenNameContainer: {
    marginTop: 10,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  screenNameText: {
    fontSize: 30,
    fontWeight: "800",
    color: colors.muted,
  },
  screenNameParagraph: {
    marginTop: 5,
    fontSize: 15,
  },
});
