import React from "react";
import { View, StyleSheet } from "react-native";

const SkeletonPlaceholder = () => {
    return (
        <View>
            <View style={styles.skeletonContainer}>
                <View style={styles.skeletonBox} />
                <View style={styles.skeletonBox} />
                <View style={styles.skeletonBox} />
            </View>
            <View style={styles.skeletonContainer}>
                <View style={styles.skeletonBox} />
                <View style={styles.skeletonBox} />
                <View style={styles.skeletonBox} />
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    skeletonContainer: {
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
        marginVertical: 20,
    },
    skeletonBox: {
        backgroundColor: "#e0e0e0",
        width: 150,
        height: 100,
        margin: 10,
        borderRadius: 10,
    },
});

export default SkeletonPlaceholder;
