import {
    StyleSheet,
    Image,
    Text,
    View,
    StatusBar,
    KeyboardAvoidingView,
    ScrollView,
    TouchableOpacity
} from "react-native";

import React, { useEffect, useState } from "react";
import { colors, messages, network } from "../../constants";
import CustomInput from "../../components/CustomInput";
import header_logo from "../../assets/logo/logo.png";
import CustomButton from "../../components/CustomButton";
import ProgressDialog from "react-native-progress-dialog";
import { Ionicons } from "@expo/vector-icons";
import { useToast } from "react-native-toast-notifications";
import { useAuthStore } from '../../states/store';
const BankDetailsScreen = ({ navigation, route }) => {
    const toast = useToast();
    const login = useAuthStore((state) => state.login);
    const logout = useAuthStore((state) => state.logout);
    const user = useAuthStore((state) => state.user);

    const [bankName, setBankName] = useState("");
    const [branchAddress, setBranchAddress] = useState("");
    const [recipientName, setRecipientName] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [accountNumberConfirmation, setAccountConfirmation] = useState("");
    const [userBankName, setUserBankName] = useState("");
    const [userBranchAddress, setUserBranchAddress] = useState("");
    const [userRecipientName, setUserRecipientName] = useState("");
    const [userAccountNumber, setUserAccountNumber] = useState("");
    const [maskedBankInfo, setMaskedBankInfo] = useState("")
    const [isLoading, setIsLoading] = useState(false);
    const getToken = (obj) => {
        try {
            setUser(JSON.parse(obj));
        } catch (e) {
            setUser(obj);
            return obj.token;
        }
        return JSON.parse(obj).token;
    };


    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-auth-token", user?.token);

    var raw = JSON.stringify({
        bankName: bankName,
        branchAddress: branchAddress,
        recipientName: recipientName,
        accountNumber: accountNumber,
        userId: user?._id
    });


    const getBankDetails = () => {
        var getRequestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(network.serverip + "/bank", getRequestOptions) // API call
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    setIsLoading(false);
                    setMaskedBankInfo(result.bankDetails)
                    console.log("result", result)
                } else {
                    console.log("result from else failed", result)
                    // toast.show(messages.needLoginMessage, { placement: "bottom", type: "danger_with_close_button" })
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                toast.show(messages.genericErrorMessage, { placement: "bottom", type: "danger_with_close_button" })
                console.log("error", error);
            });
    }

    useEffect(() => {
        // if (user) {
        getBankDetails();
        // }
    }, []);

    //method to validate the user credentials and navigate to Home Screen / Dashboard
    const submitHandle = () => {
        setIsLoading(true);
        //[check validation] -- Start
        // if email does not contain @ sign
        if (bankName == "") {
            setIsLoading(false);
            toast.show("Lütfen banka ismini girin", { placement: "bottom", type: "danger_with_close_button" })
            return
        }
        if (branchAddress == "") {
            setIsLoading(false);
            toast.show("Lütfen şube adresini girin", { placement: "bottom", type: "danger_with_close_button" })
            return
        }
        if (recipientName == "") {
            setIsLoading(false);
            toast.show("Lütfen hesap sahibinin tam ismini girin", { placement: "bottom", type: "danger_with_close_button" })
            return
        }
        if (accountNumber == "") {
            setIsLoading(false);
            toast.show("Lütfen banka hesap numarasını girin", { placement: "bottom", type: "danger_with_close_button" })
            return
        }
        if (accountNumberConfirmation != accountNumber) {
            setIsLoading(false);
            toast.show("Hesap numaraları aynı değil", { placement: "bottom", type: "danger_with_close_button" })
            return
        }
        //[check validation] -- End
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };
        fetch(network.serverip + "/bank", requestOptions) // API call
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    setIsLoading(false);
                    console.log("result after adding data", result.data)
                    setMaskedBankInfo(result.bankDetails)
                    setBankName("")
                    setBranchAddress("")
                    setRecipientName("")
                    setAccountNumber("")
                    setAccountConfirmation("")
                    console.log("result", result)
                    toast.show("Hesap bilgileriniz başarıyla eklendi", { placement: "bottom", type: "success_with_close_button" })
                } else {
                    setIsLoading(false);
                    toast.show(messages.genericErrorMessage, { placement: "bottom", type: "danger_with_close_button" })
                }
            })
            .catch((error) => {
                setIsLoading(false);
                toast.show(messages.genericErrorMessage, { placement: "bottom", type: "danger_with_close_button" })
                console.log("error", error);
            });
    };

    return (
        <View style={styles.container}
        // behavior={Platform.OS === "ios" ? "padding" : "height"}
        >
            <ScrollView>
                <View style={styles.TopBarContainer}>
                    <TouchableOpacity
                        onPress={() => {
                            navigation.navigate('tabs', { screen: 'profile' });
                        }}
                    >
                        <Ionicons
                            name="arrow-back-circle-outline"
                            size={30}
                            color={colors.muted}
                        />
                    </TouchableOpacity>
                </View>
                <View style={styles.screenNameContainer}>
                    <Text style={styles.screenNameText}>Banka Bilgileriniz</Text>
                    <Text style={styles.screenNameParagraph}>- Kaydettiğiniz Banka Bilgileriniz:</Text>
                    <View style={styles.accountContainer}>
                        <Text style={styles.screenNameSavedAccount}>{maskedBankInfo ? maskedBankInfo : "Bulunmadı"}</Text>
                    </View>
                    <Text style={styles.screenNameParagraph}>- Banka bilgilerinizi burada girin. Satışlardan kazandığınız parayı bu banka hesabına yatıracağız.</Text>
                    <Text style={styles.screenNameParagraph}>- Satış kazancınızın %10'u Raf platformuna ve 1%'i partner bankamız Papara'ya gitmektedir.</Text>
                    <Text style={styles.screenNameParagraph}>- 1% kredi kartı komisyonu, partner şirket Papara tarafından belirlenmektedir ve bizim kontrolumuz dışındadır.</Text>
                    <Text style={styles.screenNameParagraph}>- Banka hesabı Türkiye'de olmalıdır. Uluslararası ödemeler şu an yapılmamaktadır.</Text>
                    <Text style={styles.screenNameParagraph}>- Kitabınız teslimat edildikten 5 gün sonra paranız hesabınıza yatacaktır.</Text>
                    <Text style={styles.screenNameParagraph}>- Banka bilgilerinizi güncellemek istiyorsanız bu formu doldurun.</Text>
                    <Text style={styles.screenNameParagraph}>- Eğer banka bilgilerinizi daha önce girdiyseniz, eski hesap bilgileriniz silinecektir.</Text>
                </View>
                <ProgressDialog visible={isLoading} label={"Yükleniyor..."} />
                <StatusBar backgroundColor={colors.primary}></StatusBar>
                <View style={styles.formContainer}>

                    <Text style={{ color: colors.muted }}>Banka Adı</Text>
                    <CustomInput
                        value={bankName}
                        setValue={setBankName}
                        placeholder={"Banka Adı"}
                        placeholderTextColor={colors.muted}
                        radius={5}
                    />
                    <Text style={{ color: colors.muted }}>Şube Adresi</Text>
                    <CustomInput
                        value={branchAddress}
                        setValue={setBranchAddress}
                        placeholder={"Şube Adresi"}
                        placeholderTextColor={colors.muted}
                        radius={5}
                    />
                    <Text style={{ color: colors.muted }}>Hesap Sahibinin Tam İsmi</Text>
                    <CustomInput
                        value={recipientName}
                        setValue={setRecipientName}
                        placeholder={"Hesap Sahibinin Tam İsmi"}
                        placeholderTextColor={colors.muted}
                        radius={5}
                    />
                    <Text style={{ color: colors.muted }}>Banka Numarası</Text>
                    <CustomInput
                        value={accountNumber}
                        setValue={setAccountNumber}
                        placeholder={"Banka Numarası"}
                        placeholderTextColor={colors.muted}
                        radius={5}
                    />
                    <Text style={{ color: colors.muted }}>Banka Numarası Tekrar Et</Text>
                    <CustomInput
                        value={accountNumberConfirmation}
                        setValue={setAccountConfirmation}
                        placeholder={"Banka Numarası Tekrar Et"}
                        placeholderTextColor={colors.muted}
                        radius={5}
                    />
                </View>
                <View style={styles.buttomContainer}>
                    <CustomButton text={"KAYDET"} onPress={submitHandle} />
                </View>
            </ScrollView>
        </View>
    );
};

export default BankDetailsScreen;

const styles = StyleSheet.create({
    accountContainer: {
        borderColor: "black",
        borderRadius: 8,
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start"
    },
    screenNameSavedAccount: {
        marginTop: 5,
        fontSize: 18, // Adjust the font size as needed
        fontWeight: 'bold', // Bold text
        color: "black",
        padding: 2,
        backgroundColor: "yellow",
    },
    screenNameParagraph: {
        marginTop: 5,
        fontSize: 15,
    },
    TopBarContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    container: {
        backgroundColor: colors.light,
        padding: 20,
        flex: 1,
    },
    welconeContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",
        height: "30%",
        // padding:15
    },
    formContainer: {
        flex: 3,
        justifyContent: "flex-start",
        alignItems: "center",
        display: "flex",
        width: "100%",
        flexDirecion: "row",
        padding: 5,
    },
    logo: {
        resizeMode: "contain",
        width: 80,
    },
    welcomeText: {
        fontSize: 42,
        fontWeight: "bold",
        color: colors.muted,
    },
    welcomeParagraph: {
        fontSize: 15,
        fontWeight: "500",
        color: colors.primary_shadow,
    },
    forgetPasswordContainer: {
        marginTop: 10,
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
    },
    ForgetText: {
        fontSize: 15,
        fontWeight: "600",
    },
    buttomContainer: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
    },
    bottomContainer: {
        marginTop: 10,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
    },
    signupText: {
        marginLeft: 2,
        color: colors.primary,
        fontSize: 15,
        fontWeight: "600",
    },
    screenNameContainer: {
        marginTop: 10,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
    },
    screenNameText: {
        fontSize: 30,
        fontWeight: "800",
        color: colors.muted,
    },
});
