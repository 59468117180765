import {
    StyleSheet,
    Text,
    StatusBar,
    View,
    ScrollView,
    TouchableOpacity,
    RefreshControl,
    Alert,
    Image
} from "react-native";
import React, { useState, useEffect } from "react";
import { colors, network, OFFER_STATUS } from "../../constants";
import { Ionicons } from "@expo/vector-icons";
import { AntDesign, MaterialIcons } from "@expo/vector-icons";
import ProgressDialog from "react-native-progress-dialog";
import AlertPolyfill from "../../components/AlertPolyfill/AlertPolyfill";
import { useToast } from "react-native-toast-notifications";
import * as actionCreators from "../../states/actionCreators/actionCreators";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { useAuthStore } from "../../states/store";

import { messages } from "../../constants";
import AskLogin from "../../components/AskLogin"
const offers_sample = [{ offerAmount: 13, offeredQuantity: 2, product: { title: "test", quantity: 3, author: "re. e", description: "good book" }, offerSentBy: "test@gmail.com" }]
const ViewOffersSentScreen = ({ navigation, route }) => {
    const user = useAuthStore((state) => state.user);
    const toast = useToast();
    const [isLoading, setIsLoading] = useState(false);
    const [refreshing, setRefreshing] = useState(false);
    const [label, setLabel] = useState("Yükleniyor...");
    const [offers, setOffers] = useState([]);
    const [productWithAcceptedOffer, setProductWithAcceptedOffer] = useState();
    const dispatch = useDispatch();
    const { addCartItem } = bindActionCreators(actionCreators, dispatch);

    var myHeaders = new Headers();
    myHeaders.append("x-auth-token", user?.token);

    var ProductListRequestOptions = {
        method: "GET",
        redirect: "follow",
        headers: myHeaders
    };

    //method call on pull refresh
    const handleOnRefresh = () => {
        setRefreshing(true);
        fetchOffersReceived();
        setRefreshing(false);
    };

    //method to decline the specific offer
    const handleCancelOffer = (id) => {

        setIsLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("x-auth-token", user?.token);
        var cancelRequestOptions = {
            method: "POST",
            headers: myHeaders,
            redirect: "follow",
        };
        fetch(`${network.serverip}/offer/${id}/cancel`, cancelRequestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    fetchOffersSent();
                    toast.show("Teklif başarıyla iptal edildi ve alıcı haberdar edildi. ", {
                        placement: "bottom",
                        type: "success_with_close_button"
                    });
                } else {
                    toast.show("Teklifi iptal ederken bir hata oluştu. Lütfen sonra tekrar deneyin", {
                        placement: "bottom",
                        type: "danger_with_close_button"
                    });
                }
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.show("Teklifi reddederken bir hata oluştu. Lütfen sonra tekrar deneyin", {
                    placement: "bottom",
                    type: "danger_with_close_button"
                });
                console.log("error", error);
            });
    };

    const showPayConfirmDialog = (id) => {
        return AlertPolyfill(
            "Teklifiniz Satıcı Tarafindan Kabul Edildi",
            "Bu teklifi tamamlamak için kredi kartı bilgilerinizi girmenizi isteyeceğiz. Ürünü sepetinize ekleyeceğiz ve bir sonraki ekranda teklif edilen fiyatta ödeme yapabileceksiniz",
            [
                {
                    text: "Teklifi tamamla",
                    onPress: () => {
                        handleCompleteOffer(id);
                    },
                },
                {
                    text: "Vazgeç",
                },
            ]
        );
    }

    const handleCompleteOffer = (id) => {
        var completeOfferHeader = new Headers();
        completeOfferHeader.append("x-auth-token", user?.token);


        setIsLoading(true);
        console.log("handleCompleteOffer clicked", id);
        fetch(`${network.serverip}/offer/${id}/validate`, {
            method: "POST",
            headers: completeOfferHeader
        })
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    console.log("setProductWithAcceptedOffer result.data", result.data)
                    setProductWithAcceptedOffer(result.data);
                    addCartItem(result.data);

                } else {
                    toast.show("Teklifi tamamlarken ederken bir hata oluştu. Lütfen sonra tekrar deneyin", {
                        placement: "bottom",
                        type: "danger_with_close_button"
                    });
                }
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.show("Teklifi tamamlarken bir hata oluştu. Lütfen sonra tekrar deneyin", {
                    placement: "bottom",
                    type: "danger_with_close_button"
                });
                console.log("error", error);
            });
        navigation.navigate("checkout")
    }

    //method for alert
    const showCancelConfirmDialog = (id) => {
        return AlertPolyfill(
            "Teklifi İptal Etmeyi Onayla",
            "Bu teklifi iptal etmek istediğinizden emin misiniz? Satıcı kabul etmiş olsa dahi teklifi iptal edebilirsiniz.",
            [
                {
                    text: "Teklifi İptal Et",
                    onPress: () => {
                        handleCancelOffer(id);
                    },
                },
                {
                    text: "Vazgeç",
                },
            ]
        );
    };


    //method the fetch the product data from server using API call
    const fetchOffersSent = () => {
        setIsLoading(true);
        fetch(`${network.serverip}/offers/${user?._id}/sent`, ProductListRequestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    setOffers(result.data);
                    setIsLoading(false);
                } else {
                    // toast.show(messages.needLoginMessage, {
                    //     placement: "bottom",
                    //     type: "danger_with_close_button"
                    // });
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                toast.show("Bir hata oluştu. Lütfen daha sonra tekrar deneyin.", {
                    placement: "bottom",
                    type: "danger_with_close_button"
                });
                setIsLoading(false);
            });

    };

    //method to filer the orders for by title [search bar]
    const filter = () => {
        const keyword = filterItem;
        if (keyword !== "") {
            const results = products?.filter((product) => {
                return product?.title.toLowerCase().includes(keyword.toLowerCase());
            });
            setFoundItems(results);
        } else {
            setFoundItems(products);
        }
    };

    // //filter the data whenever filteritem value change
    // useEffect(() => {
    //   // filter();
    // }, [filterItem]);

    //fetch the categories on initial render
    useEffect(() => {
        // if (user) {
        fetchOffersSent();
        // }
    }, []);

    return (
        <View style={styles.container}>
            <ProgressDialog visible={isLoading} label={label} />
            <StatusBar backgroundColor={colors.primary}></StatusBar>
            <View style={styles.TopBarContainer}>
                <TouchableOpacity
                    onPress={() => {
                        navigation.navigate("tabs", { screen: "profile" })
                    }}
                >
                    <Ionicons
                        name="arrow-back-circle-outline"
                        size={30}
                        color={colors.muted}
                    />
                </TouchableOpacity>
            </View>
            <View style={styles.screenNameContainer}>
                <View>
                    <Text style={styles.screenNameText}>Gönderdiğim Teklifler</Text>
                </View>
                <View>
                    <Text style={styles.screenNameParagraph}>- Teklif vermek için kredi kartına gerek yoktur.</Text>
                    <Text style={styles.screenNameParagraph}>- Satıcı teklifiniz hakkında bilgilendirilecek.</Text>
                    <Text style={styles.screenNameParagraph}>- Teklifleriniz istediginiz zaman iptal edebilirsiniz</Text>
                    <Text style={styles.screenNameParagraph}>- İstediğiniz sayıda teklif yapabilirsiniz.</Text>
                    <Text style={styles.screenNameParagraph}>- Ödemenizi teklif kabul edildikten sonra isteyeceğiz.</Text>
                    <Text style={styles.screenNameParagraph}>- Teklifinizi her zaman iptal edebilirsiniz, onaylanmış olsa bile.</Text>
                    <Text style={styles.screenNameParagraph}>- Gönderdiğiniz teklifleri burada görebilirsiniz.</Text>
                </View>
            </View>
            <ScrollView
                style={{ flex: 1, width: "100%" }}
                showsVerticalScrollIndicator={false}
                refreshControl={
                    <RefreshControl refreshing={refreshing} onRefresh={handleOnRefresh} />
                }
            >
                {offers.length == 0 ? (
                    <>
                        <View style={styles.noOfferViewContainer}>
                            <Text style={styles.noOfferViewText}>{`Hiçbir Teklif Yapmadınız`}</Text>
                        </View>
                        {!user && <View style={{ margin: 5 }}><AskLogin /></View>}
                    </>
                ) : (
                    offers.map((offer, index) => {
                        {/**  Sent offers can be pending, accepted, declined and cancelled.*/ }
                        var styleForStatus = styles.pendingStatus;
                        if (offer.status == OFFER_STATUS.ACCEPTED) {
                            styleForStatus = styles.acceptedStatus;
                        } else if (offer.status == OFFER_STATUS.DECLINED || offer.status == OFFER_STATUS.CANCELED) {
                            styleForStatus = styles.declinedStatus;
                        } else if (offer.status == OFFER_STATUS.PENDING) {
                            styleForStatus = styles.pendingStatus;
                        }
                        return (
                            <View key={index} style={styles.offerContainer}>
                                <View style={styles.innerOfferContainer}>
                                    <View>
                                        <Image source={{ uri: `${network.serverip}/uploads/${offer.productId.filename}` }} style={styles.productImage} />
                                    </View>
                                    <View style={styles.bidItem}>
                                        <Text style={styles.productTitle}>Teklif Edilen Ürün: {offer.productId.title}</Text>
                                        <View style={styles.offerInfoItem}>
                                            <Text style={styles.offerInfoText}>Teklif Fiyatı: </Text>
                                            <Text style={styles.priceText}>{offer.offerAmount}₺</Text>
                                        </View>
                                        <View style={styles.offerInfoItem}>
                                            <Text style={styles.offerInfoText}>Almak İstediğiniz Adet: </Text>
                                            <Text>{offer.offerQuantity} tane</Text>
                                        </View>
                                        <View style={styles.offerInfoItem}>
                                            <Text style={styles.offerInfoText}>Tekliften Önceki Fiyat: </Text>
                                            <Text>{offer.productId.price}₺</Text>
                                        </View>
                                        <View style={styles.offerInfoItem}>
                                            <Text style={styles.offerInfoText}>Ürün Sahibi: </Text>
                                            <Text>{offer.recipientId.username}</Text>
                                        </View>
                                        <View style={styles.offerInfoItem}>
                                            <Text style={styles.offerInfoText}>Satıcının Karari: </Text>
                                            <Text style={[styleForStatus, styles.offerStatusText]}>{offer.status}</Text>
                                        </View>
                                        <View style={styles.offerInfoItem}>
                                            <Text style={styles.offerInfoText}>Satıcı kabul etmiş olsa dahi, teklifinizi iptal edebilirsiniz</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.buttonContainer}>
                                    {offer.status === OFFER_STATUS.ACCEPTED &&
                                        <TouchableOpacity style={styles.acceptButton} onPress={() => showPayConfirmDialog(offer._id)}>
                                            <Text>ÖDEME YAP</Text>
                                            <MaterialIcons name="payment" size={24} color="#008000" />
                                        </TouchableOpacity>
                                    }
                                    <TouchableOpacity style={styles.declineButton} onPress={() => showCancelConfirmDialog(offer._id)}>
                                        <Text>İPTAL ET</Text>
                                        <AntDesign name="closecircleo" size={30} color="red" />
                                    </TouchableOpacity>
                                </View>
                            </View>
                        );
                    }))}
            </ScrollView>
        </View>
    )
};


export default ViewOffersSentScreen;

const styles = StyleSheet.create({
    offerStatusText: {
        fontSize: 16,
        fontWeight: "bold"
    },
    defaultStatus: {
        color: "black",
        backgroundColor: "yellow"
    },
    acceptedStatus: {
        color: "green",
    },
    declinedStatus: {
        color: "red",
    },
    pendingStatus: {
        color: "blue",
        backgroundColor: "yellow"
    },
    acceptButton: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "white",
        padding: 10,
        margin: 20,
        borderRadius: 8,
        borderColor: "black",
        borderWidth: 3,
        alignItems: "center"
    },
    declineButton: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "white",
        padding: 10,
        margin: 20,
        borderRadius: 8,
        borderColor: "black",
        borderWidth: 3,
        alignItems: "center"
    },
    priceText: {
        fontSize: 15,
        fontWeight: "bold",
        color: colors.primary,
    },
    productTitle: {
        fontSize: 14,
        fontWeight: "bold",
    },
    noOfferViewContainer: {
        borderColor: "black",
        borderRadius: 8,
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start"
    },
    offerInfoItem: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    noOfferViewText: {
        fontSize: 18, // Adjust the font size as needed
        fontWeight: 'bold', // Bold text
        marginTop: 5,
        color: "black",
        padding: 2,
        backgroundColor: "yellow",
    },
    productName: {
        fontSize: 18, // Adjust the font size as needed
        fontWeight: 'bold', // Bold text
        marginBottom: 8,
    },
    bidAmount: {
        fontSize: 16,
        marginBottom: 8,
        color: "red"
    },
    originalPrice: {
        fontSize: 20, // Adjust the font size as needed
        fontWeight: 'bold', // Bold text
        color: 'blue', // Customize the color as desired
    },
    offerInfoText: {
        fontSize: 13,
        fontWeight: "500",
        color: colors.muted,
    },
    offerContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: colors.white,
        padding: 5,
        borderRadius: 5,
        marginTop: 10,
    },
    innerOfferContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    container: {
        flexDirecion: "row",
        backgroundColor: colors.light,
        alignItems: "center",
        justifyContent: "center",
        padding: 20,
        flex: 1,
    },
    TopBarContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    formContainer: {
        flex: 2,
        justifyContent: "flex-start",
        alignItems: "center",
        display: "flex",
        width: "100%",
        flexDirecion: "row",
        padding: 5,
    },
    buttonContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        padding: 10
    },
    screenNameContainer: {
        marginTop: 10,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
    },
    screenNameText: {
        fontSize: 30,
        fontWeight: "800",
        color: colors.muted,
    },
    screenNameParagraph: {
        marginTop: 5,
        fontSize: 15,
    },
});
