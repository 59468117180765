import {
  StyleSheet,
  Text,
  View,
  StatusBar,
  ScrollView
} from "react-native";
import React, { useEffect, useState } from "react";
import UserProfileCard from "../../components/UserProfileCard/UserProfileCard";
import { Ionicons, FontAwesome } from "@expo/vector-icons";
import OptionList from "../../components/OptionList/OptionList";
import { colors } from "../../constants";
import { useAuthStore } from "../../states/store";

const UserProfileScreen = ({ navigation, route }) => {
  const user = useAuthStore((state) => state.user);
  const logout = useAuthStore((state) => state.logout);
  const isAdmin = useAuthStore((state) => state.isAdmin);
  return (
    <View style={styles.container}>
      <StatusBar backgroundColor={colors.primary}></StatusBar>
      <View style={styles.TopBarContainer}>
        {/* <TouchableOpacity onPress={() => navigation.navigate("tabs")}>
          <Ionicons name="home-outline" size={30} color={colors.primary} />
        </TouchableOpacity> */}
      </View>
      <View style={styles.screenNameContainer}>
        <Text style={styles.screenNameText}>Hesap & Ayarlar</Text>
      </View>
      <View style={styles.UserProfileCardContainer}>
        <UserProfileCard
          Icon={Ionicons}
          name={user?.username}
          email={user?.email}
        />
      </View>
      <ScrollView style={styles.OptionsContainer}>
        <OptionList
          text={"Sipariş Ettiğim Kitaplar"}
          Icon={Ionicons}
          iconName={"list"}
          onPress={() => navigation.navigate("viewpurchases")}
        />
        <OptionList
          text={"Gönderdiğim Teklifler"}
          Icon={Ionicons}
          iconName={"send"}
          onPress={() => navigation.navigate("viewofferssent")}
        />
        <OptionList
          text={"Para Yatan Banka Hesabınız"}
          Icon={FontAwesome}
          iconName={"bank"}
          onPress={() => navigation.navigate("bank")}
        />
        {/* <OptionList
          text={"Wishlist"}
          Icon={Ionicons}
          iconName={"heart"}
          onPress={() => navigation.navigate("mywishlist")}
        /> */}
        <OptionList
          text={"Hata, Öneri veya Sorun Bildir"}
          Icon={Ionicons}
          iconName={"bug-sharp"}
          onPress={() => navigation.navigate("feedback")}
        />
        {/* <OptionList
          text={"Yardım Merkezi"}
          Icon={Ionicons}
          iconName={"help-circle"}
          onPress={() => console.log("working....")}
        />  */}
        <OptionList
          text={"Şifre Düzenleme & Hesap Silme"}
          Icon={Ionicons}
          iconName={"person"}
          onPress={() => navigation.navigate("myaccount")}
        />
        {isAdmin && (
        <OptionList
          text={"Admin Paneli"}
          Icon={Ionicons}
          iconName={"person"}
          onPress={() => navigation.navigate("admindashboard")}
        />
        )}
        {/* <OptionList
          text={"Kullanım Koşulları & Hakkımızda"}
          Icon={Ionicons}
          iconName={"md-information-sharp"}
          onPress={() => navigation.navigate("corporate")}
        />
        <OptionList
          text={"Sıkça Sorulan Sorular"}
          Icon={Ionicons}
          iconName={"help-circle-outline"}
          onPress={() => navigation.navigate("faq")}
        />
        <OptionList
          text={"Veri Koru Sözleşmesi"}
          Icon={Ionicons}
          iconName={"shield-checkmark-outline"}
          onPress={() => navigation.navigate("privacy")}
        /> */}
        <OptionList
          text={"Çıkış Yap"}
          Icon={Ionicons}
          iconName={"log-out"}
          onPress={async () => {
            logout();
            navigation.replace("landing");
          }}
        />
      </ScrollView>
    </View>
  );
};

export default UserProfileScreen;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexDirecion: "row",
    backgroundColor: colors.light,
    alignItems: "center",
    justifyContent: "flex-start",
    padding: 20,
    flex: 1,
  },
  TopBarContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  UserProfileCardContainer: {
    width: "100%",
    height: "10%",
  },
  screenNameContainer: {
    marginTop: 10,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: 10,
  },
  screenNameText: {
    fontSize: 30,
    fontWeight: "800",
    color: colors.muted,
  },
  OptionsContainer: {
    width: "100%",
  },
});
