import {
  StyleSheet,
  Image,
  Text,
  View,
  StatusBar,
  KeyboardAvoidingView,
  ScrollView,
  TouchableOpacity,
  ActivityIndicator
} from "react-native";

import React, { useState } from "react";
import { colors, messages, network } from "../../constants";
import CustomInput from "../../components/CustomInput";
import header_logo from "../../assets/logo/logo.png";
import CustomButton from "../../components/CustomButton";
import ProgressDialog from "react-native-progress-dialog";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Ionicons } from "@expo/vector-icons";
import { useToast } from "react-native-toast-notifications";
import { useAuthStore } from "../../states/store";

const LoginScreen = ({ navigation }) => {
  const login = useAuthStore((state) => state.login);
  const logout = useAuthStore((state) => state.logout);
  const user = useAuthStore((state) => state.user);
  // const user = useAuthStore((state) => state.user);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    email: email,
    password: password,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  //method to validate the user credentials and navigate to Home Screen / Dashboard
  const loginHandle = () => {
    setIsLoading(true);
    //[check validation] -- Start
    // if email does not contain @ sign
    if (email == "") {
      setIsLoading(false);
      toast.show("Lütfen emailinizi girin", {
        placement: "bottom",
        type: "danger_with_close_button"
      });
      return
    }
    if (password == "") {
      toast.show("Lütfen şifrenizi girin", {
        placement: "bottom",
        type: "danger_with_close_button"
      });
      setIsLoading(false);
      return
    }
    if (!email.includes("@")) {
      setIsLoading(false);
      toast.show("Email geçerli değildir", {
        placement: "bottom",
        type: "danger_with_close_button"
      });
      return
    }
    // length of password must be equal or greater than 6 characters
    if (password.length < 6) {
      setIsLoading(false);
      toast.show("Şifreniz en az 6 karakter olmalıdır", {
        placement: "bottom",
        type: "danger_with_close_button"
      });
      return
    }
    //[check validation] -- End

    fetch(network.serverip + "/login", requestOptions) // API call
      .then((response) => response.json())
      .then(async (result) => {
        if (result.success) {
          // await setUserData(result.data)
          console.log("user.data")
          login(result.data);
          // _storeData(result.data);
          setIsLoading(false);
          navigation.navigate("tabs", { screen: "buy" }); // navigate to home page
        } else {
          setIsLoading(false);
          toast.show("Hatalı şifre. Lütfen tekrar deneyin.", {
            placement: "bottom",
            type: "danger_with_close_button"
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.show(messages.genericErrorMessage, {
          placement: "bottom",
          type: "danger_with_close_button"
        });
        console.log("error", error);
      });
  };

  return (
      <KeyboardAvoidingView
        // behavior={Platform.OS === "ios" ? "padding" : "height"}
        style={styles.container}
      >
        <ProgressDialog visible={isLoading} label={"Yükleniyor..."} />
        <StatusBar backgroundColor={colors.primary}></StatusBar>
        <View style={styles.TopBarContainer}>
          <TouchableOpacity
            onPress={() => {
              if (navigation.canGoBack()) {
                navigation.goBack();
              } else {
                navigation.navigate("landing");
              }
            }}
          >
            <Ionicons
              name="arrow-back-circle-outline"
              size={30}
              color={colors.muted}
            />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              if (navigation.canGoBack()) {
                navigation.goBack();
              } else {
                navigation.navigate("landing");
              }
            }}
          >
            <Ionicons
              name="close-outline"
              size={30}
              color={colors.muted}
            />
          </TouchableOpacity>
        </View>

        <ScrollView style={{ flex: 1, width: "100%" }}>
          <View style={styles.welconeContainer}>
            <View>
              <Text style={styles.welcomeText}>Raf</Text>
              <Text style={styles.welcomeParagraph}>
                Al. Sat. İkinci El Kitap.
              </Text>
            </View>
            <View>
              <Image style={styles.logo} source={header_logo} />
            </View>
          </View>
          <View style={styles.screenNameContainer}>
            <Text style={styles.screenNameText}>Giriş Yap</Text>
          </View>
          <View style={styles.formContainer}>
            <CustomInput
              value={email}
              setValue={setEmail}
              placeholder={"E-mail"}
              placeholderTextColor={colors.muted}
              radius={5}
            />
            <CustomInput
              value={password}
              setValue={setPassword}
              secureTextEntry={true}
              placeholder={"Şifre"}
              placeholderTextColor={colors.muted}
              radius={5}
            />
            <View style={styles.forgetPasswordContainer}>
              <Text
                onPress={() => navigation.navigate("forgetpassword")}
                style={styles.ForgetText}
              >
                Şifremi Unuttum
              </Text>
            </View>
          </View>
        </ScrollView>
        <View style={styles.buttomContainer}>
          <CustomButton
            isAuthPage={true}
            text={"GİRİŞ YAP"}
            onPress={() => {
              loginHandle()
            }} />
        </View>
        <View style={styles.bottomContainer}>
          <Text>Bir hesabınız yok mu?</Text>
          <Text
            onPress={() => navigation.navigate("signup")}
            style={styles.signupText}
          >
            Üye Ol
          </Text>
        </View>
      </KeyboardAvoidingView>
  );
};

export default LoginScreen;

const styles = StyleSheet.create({
  TopBarContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  container: {
    width: "100%",
    flexDirecion: "row",
    backgroundColor: colors.light,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    flex: 1,
  },
  welconeContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    height: "30%",
    // padding:15
  },
  formContainer: {
    flex: 3,
    justifyContent: "flex-start",
    alignItems: "center",
    display: "flex",
    width: "100%",
    flexDirecion: "row",
    padding: 5,
  },
  logo: {
    resizeMode: "contain",
    width: 80,
  },
  welcomeText: {
    fontSize: 42,
    fontWeight: "bold",
    color: colors.muted,
  },
  welcomeParagraph: {
    fontSize: 15,
    fontWeight: "500",
    color: colors.primary_shadow,
  },
  forgetPasswordContainer: {
    marginTop: 10,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  ForgetText: {
    fontSize: 15,
    fontWeight: "600",
  },
  buttomContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  bottomContainer: {
    marginTop: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  signupText: {
    marginLeft: 2,
    color: colors.primary,
    fontSize: 15,
    fontWeight: "600",
  },
  screenNameContainer: {
    marginTop: 10,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  screenNameText: {
    fontSize: 30,
    fontWeight: "800",
    color: colors.muted,
  },
});
