import {
  StyleSheet,
  StatusBar,
  View,
  TouchableOpacity,
  Text,
  ScrollView,
  Modal,
  TextInput,
  KeyboardAvoidingView
} from "react-native";
import { Ionicons } from "@expo/vector-icons";
import React, { useState, useEffect, useRef, useCallback } from "react";
import BasicProductList from "../../components/BasicProductList/BasicProductList";
import { colors, messages, network, ORDER_STATUS } from "../../constants";
import CustomButton from "../../components/CustomButton";
import { useSelector, useDispatch } from "react-redux";
import * as actionCreators from "../../states/actionCreators/actionCreators";
import { bindActionCreators } from "redux";
import AsyncStorage from "@react-native-async-storage/async-storage";
import CustomInput from "../../components/CustomInput";
import ProgressDialog from "react-native-progress-dialog";
import { useToast } from "react-native-toast-notifications";
import { useCheckoutStore, useAuthStore } from '../../states/store' // import Zustand store


const CheckoutScreen = ({ navigation, route }) => {
    // Access Zustand store
    const {
      buyerFirstName,
      buyerLastName,
      email,
      phone,
      streetAddress,
      district,
      city,
      postalCode,
      cardNumber,
      expiryMonth,
      expiryYear,
      cvv,
      setFormData,
    } = useCheckoutStore()
  

  const login = useAuthStore((state) => state.login);
  const logout = useAuthStore((state) => state.logout);
  const user = useAuthStore((state) => state.user);
  
  const [isLoading, setIsLoading] = useState(false);
  const cartProduct = useSelector((state) => state.product);
  console.log("cartProduct", cartProduct)
  const dispatch = useDispatch();
  const { emptyCart } = bindActionCreators(actionCreators, dispatch);
  const creditCardRef = useRef();
  const [deliveryCost, setDeliveryCost] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const toast = useToast();

  const handleFormChange = (field, value) => {
    setFormData(field, value) // Persist data in Zustand store
  }

  //method to remove the userData from aysnc storage and navigate to login
  const handleLogout = async () => {
    logout();
    navigation.replace("login");
  };


  const validateEntries = () => {
    if (streetAddress && district && city && postalCode && phone && email && cardNumber && expiryMonth && expiryYear && cvv && cartProduct) {
      return true;
    } else {
      return false;
    }
  }

  //method to handle checkout
  const handleCheckout = async () => {
    if (creditCardRef.current) {
      const { error, data } = creditCardRef.current.submit();
      console.log('CARD ERROR: ', error);
      console.log('CARD DATA: ', data);
    }

    setIsLoading(true);
    var myHeaders = new Headers();
    try {
      myHeaders.append("x-auth-token", user?.token);
      myHeaders.append("Content-Type", "application/json");

      var payload = [];
      var totalAmount = 0;

      // fetch the cart items from redux and set the total cost

      cartProduct.forEach((product) => {
        let obj = {
          product: product._id,
          price: product.price,
          quantity: product.quantity,
          status: ORDER_STATUS.SIPARIS_EDILDI
        };
        totalAmount += (product.price);
        payload.push(obj);
      });
    } catch (error) {
      setIsLoading(false);
      toast.show(messages.genericErrorMessage, { placement: "bottom", type: "danger_with_close_button" })
      console.log("error", error);
    }

    var raw = JSON.stringify({
      items: payload,
      totalAmount: totalAmount,
      discount: 0,
      payment_type: "cod",
      district: district,
      city: city,
      postalCode: postalCode,
      streetAddress: streetAddress,
      buyerFirstName: buyerFirstName,
      buyerLastName: buyerLastName,
      buyerPhone: phone,
      buyerEmail: email,
      cvv: cvv,
      cardNumber: cardNumber,
      expiryMonth: expiryMonth,
      expiryYear: expiryYear
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };


    fetch(network.serverip + "/checkout", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("Checkout=>", result);
        if (result.err === "jwt expired") {
          setIsLoading(false);
          handleLogout();
        }
        if (result.success == true) {
          setIsLoading(false);
          emptyCart("empty");

          navigation.replace("orderconfirm");
        } else {
          setIsLoading(false);
          toast.show(messages.genericErrorMessage, { placement: "bottom", type: "danger_with_close_button" })
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.show(messages.genericErrorMessage, { placement: "bottom", type: "danger_with_close_button" })
        console.log("error", error);
      });
  };

  // set total cost on initital render
  useEffect(() => {
    let accumulatorForTotalCost = 0

    cartProduct.forEach((product) => {
      accumulatorForTotalCost += product.price * product.quantity;
    })

    setTotalCost(accumulatorForTotalCost);
    console.log("totalCost needs to be total", cartProduct, accumulatorForTotalCost)
  }, [cartProduct]);

  return (
    <KeyboardAvoidingView style={styles.container}>
      <StatusBar backgroundColor={colors.primary}></StatusBar>
      <ProgressDialog visible={isLoading} label={"Sipariş Veriliyor..."} />
      <View style={styles.topBarContainer}>
        <TouchableOpacity
          onPress={() => {
            if (navigation.canGoBack()) {
              navigation.goBack(); // leave goBack since we can come here from viewofferssent, or cart
            } else {
              navigation.navigate("cart")
            }
          }}
        >
          <Ionicons
            name="arrow-back-circle-outline"
            size={30}
            color={colors.muted}
          />
        </TouchableOpacity>
        <View></View>
        <View></View>
      </View>
      <ScrollView style={styles.bodyContainer} nestedScrollEnabled={true}>
        <Text style={styles.primaryText}>Sipariş Özeti</Text>

        <ScrollView
          style={styles.orderSummaryContainer}
          nestedScrollEnabled={true}
        >
          {cartProduct.map((product, index) => (
            <BasicProductList
              key={index}
              imageUri={`${network.serverip}/uploads/${product.filename}`}
              title={product?.title}
              price={product?.price}
              seller={product?.sellerUser?.username}
              quantity={product.quantity}
              description={product?.description}
              status={product?.status}
            />
          ))}
        </ScrollView>
        <View>
          <Text style={styles.primaryText}>Toplam</Text>
        </View>
        <View style={styles.totalOrderInfoContainer}>
          <View style={styles.list}>
            <Text style={styles.label}>Sipariş</Text>
            <Text>{totalCost}₺</Text>
          </View>
          <View style={styles.list}>
            <Text style={styles.label}>Kargo Parası</Text>
            <Text style={styles.tertiaryTextSm}>0 ₺ {"("}Bedava Kargo{")"}</Text>
          </View>
          <View style={styles.list}>
            <Text style={styles.label}>KDV</Text>
            <Text style={styles.tertiaryTextSm}>0 ₺ {"("}Fiyatlara KDV dahildir{")"}</Text>
          </View>
          <View style={styles.list}>
            <Text style={styles.primaryTextSm}>Toplam</Text>
            <Text style={styles.secondaryTextSm}>{totalCost}₺</Text>
          </View>
        </View>
        <View>
          <Text style={styles.primaryText}>Gönderim Adresi</Text>
        </View>
        <View style={styles.listContainer}>
          <Text style={styles.label}>Alıcı Adı</Text>
          <TextInput style={styles.input} placeholderTextColor={colors.muted} placeholder={"Alıcı Adını girin"} value={buyerFirstName}   onChangeText={(text) => handleFormChange('buyerFirstName', text)}></TextInput>
          <Text style={styles.label}>Alıcı Soyadı</Text>
          <TextInput style={styles.input} placeholderTextColor={colors.muted} placeholder={"Alıcı Soyadını girin"} value={buyerLastName} onChangeText={(text) => handleFormChange('buyerLastName', text)}></TextInput>
          <Text style={styles.label}>E-posta</Text>
          <TextInput style={styles.input} placeholderTextColor={colors.muted} placeholder={"E-postanızı girin"} value={email} onChangeText={(text) => handleFormChange('email', text)}></TextInput>
          <Text style={styles.label}>Telefon</Text>
          <TextInput style={styles.input} placeholderTextColor={colors.muted} placeholder={"Telefonuzu girin"} value={phone} onChangeText={(text) => handleFormChange('phone', text)}></TextInput>
        </View>
        <View>
          <Text style={styles.primaryText}>Adres</Text>
        </View>
        <View style={styles.listContainer}>
          {/* Street Address */}
          <Text style={styles.label}>Sokak Adresi</Text>
          <TextInput
            placeholder="Örneğin: Turgutlu Mahallesi Atatürk Caddesi No: 123"
            style={styles.input}
            placeholderTextColor={colors.muted}
            value={streetAddress}
            onChangeText={(text) => handleFormChange('streetAddress', text)}
          />

          {/* District (İlçe) */}
          <Text style={styles.label}>İlçe</Text>
          <TextInput
            placeholder="Örneğin: Kadıköy"
            placeholderTextColor={colors.muted}
            style={styles.input}
            value={district}
            onChangeText={(text) => handleFormChange('district', text)}
          />

          {/* City (Şehir) */}
          <Text style={styles.label}>Şehir</Text>
          <TextInput
            placeholder="Örneğin: İstanbul"
            placeholderTextColor={colors.muted}
            style={styles.input}
            value={city}
            onChangeText={(text) => handleFormChange('city', text)}
          />

          {/* Postal Code (Posta Kodu) */}
          <Text style={styles.label}>Posta Kodu</Text>
          <TextInput
            placeholder="Örneğin: 34700"
            placeholderTextColor={colors.muted}
            style={styles.input}
            value={postalCode}
            onChangeText={(text) => handleFormChange('postalCode', text)}
            keyboardType="numeric" // Ensures the user can only enter numbers
          />
        </View>

        <View>
          <Text style={styles.primaryText}>Ödeme Bilgileri</Text>
        </View>
        <View style={styles.listContainer}>
          {/* <View style={styles.list}>
            <Text style={styles.secondaryTextSm}>Ödeme Yöntemi</Text>
            <Text style={styles.primaryTextSm}>Kredi Karti İle</Text>
          </View> */}
          {/* <CreditCard ref={creditCardRef} /> */}
          {/* <CreditCartInput /> */}
       
            <Text style={styles.label}>Kredi Kart Numarası</Text><Text style={styles.subText}>(Arada boşluk bırakmayın)</Text>
            <TextInput
              style={styles.input}
              placeholder="XXXX XXXX XXXX XXXX"
              placeholderTextColor={colors.muted}
              value={cardNumber}
              maxLength={16}
              minLength={16}
              autoComplete="cc-number"
              onChangeText={(text) => handleFormChange('cardNumber', text)}
              keyboardType="numeric"
            />
            <Text style={styles.label}>Son Kullanma Tarihi Ayı</Text><Text style={styles.subText}>(İki sayıyla belirtin. Mesela üçüncü ay ise 03, onuncu ay ise 10)</Text>
            <TextInput
              style={styles.input}
              placeholder="MM"
              placeholderTextColor={colors.muted}
              value={expiryMonth}
              autoComplete="cc-exp-month"
              maxLength={2}
              minLength={2}
              onChangeText={(text) => handleFormChange('expiryMonth', text)}
              keyboardType="numeric"
            />
            <Text style={styles.label}>Son Kullanma Tarihi Yılı</Text>
            <TextInput
              style={styles.input}
              placeholder="YYYY"
              placeholderTextColor={colors.muted}
              value={expiryYear}
              autoComplete="cc-exp-year"
              maxLength={4}
              minLength={4}
              onChangeText={(text) => handleFormChange('expiryYear', text)}
              keyboardType="numeric"
            />
            <Text style={styles.label}>CVV Kodu</Text>
            <TextInput
              style={styles.input}
              placeholder="XXX"
              placeholderTextColor={colors.muted}
              value={cvv}
              maxLength={3}
              minLength={3}
              autoComplete="cc-csc"
              onChangeText={(text) => handleFormChange('cvv', text)}
              keyboardType="numeric"
              secureTextEntry={true}
            />
          {/* <CreditCardInput onChange={handleCreditCardInput}/> */}
        </View>

        <View style={styles.emptyView}></View>
      </ScrollView>
      <View style={styles.buttomContainer}>
        <CustomButton
          onPress={() => {
            handleCheckout();
          }}
          text={"SİPARİŞ VER"} disabled={!validateEntries()}
        />
      </View>
    </KeyboardAvoidingView>
  );
};

export default CheckoutScreen;

const styles = StyleSheet.create({
  label: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  subText: {
    color: colors.muted,
    fontWeight: 'normal',
    fontSize: 14,
  },
  input: {
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    borderRadius: 5,
    paddingHorizontal: 10,
    marginBottom: 16,
  },
  container: {
    width: "100%",
    flexDirecion: "row",
    backgroundColor: colors.light,
    alignItems: "center",
    justifyContent: "flex-start",
    paddingBottom: 0,
    flex: 1,
  },
  topBarContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 20,
  },
  toBarText: {
    fontSize: 15,
    fontWeight: "600",
  },
  bodyContainer: {
    flex: 1,
    width: "100%",
    paddingLeft: 20,
    paddingRight: 20,
  },
  customInput: {
    borderStyle: 'solid',
    borderRadius: 2,
    borderColor: 'black'
  },
  orderSummaryContainer: {
    backgroundColor: colors.white,
    borderRadius: 10,
    padding: 10,
    maxHeight: 220,
  },
  totalOrderInfoContainer: {
    borderRadius: 10,
    padding: 10,
    backgroundColor: colors.white,
  },
  primaryText: {
    marginBottom: 5,
    marginTop: 5,
    fontSize: 20,
    fontWeight: "bold",
  },
  list: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: colors.white,
    height: 50,
    borderBottomWidth: 1,
    borderBottomColor: colors.light,
    padding: 10,
  },
  primaryTextSm: {
    fontSize: 15,
    fontWeight: "bold",
    color: colors.primary,
  },
  secondaryTextSm: {
    fontSize: 15,
    fontWeight: "bold",
  },
  tertiaryTextSm: {
    fontSize: 15,
    fontWeight: "bold",
    color: "green",
  },
  listContainer: {
    backgroundColor: colors.white,
    borderRadius: 10,
    padding: 10,
  },
  buttomContainer: {
    width: "100%",
    padding: 20,
    paddingLeft: 30,
    paddingRight: 30,
  },
  emptyView: {
    width: "100%",
    height: 20,
  },
  modelBody: {
    flex: 1,
    display: "flex",
    flexL: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  modelAddressContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
    width: 320,
    height: 400,
    backgroundColor: colors.white,
    borderRadius: 20,
    elevation: 3,
  },
});
