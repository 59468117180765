import { StyleSheet, Text, View, TouchableOpacity } from "react-native";
import React, { setState, useState, useEffect } from "react";
import { colors } from "../../constants";
import { MaterialIcons } from '@expo/vector-icons';

const CustomAlert = ({key, message, type }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, [visible]);


  console.log("Is visible:", visible, message); // Add this line for debugging

  return (
    <View style={{ width: "100%" }}>
      {message !== "" && visible ? (
        <View style={[styles.alertContainer, styles[`alertContainer_${type}`]]}>
          <Text style={styles.alertText}>{message}</Text>
          <TouchableOpacity
            onPress={() => {
              setVisible(false);
            }}
          >
            <MaterialIcons name="cancel" size={24} color="black" />
          </TouchableOpacity>
        </View>
      ) : null}
    </View>
  );
};

export default CustomAlert;

const styles = StyleSheet.create({
  alertContainer: {
    padding: 5,
    marginTop: 5,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    backgroundColor: colors.orange,
  },
  alertContainer_error: {
    backgroundColor: colors.primary,
  },
  alertContainer_success: {
    backgroundColor: colors.success,
  },
  alertText: {
    color: colors.white,
    fontSize: 16,
    fontWeight: "600",
  },
});
