import Routes from "./routes/Routes";
import { Provider } from "react-redux";
import { store } from "./states/store";
import { ToastProvider } from 'react-native-toast-notifications'
import Toast from 'react-native-toast-notifications';
import { View, Text, TouchableOpacity } from "react-native"
export default function App() {
  console.reportErrorsAsExceptions = false;

  return (
    <>
    <Toast ref={(ref) => global['toast'] = ref} />
    <ToastProvider
      style={{ width: 200 }}
      offsetBottom={100}
      renderType={{
        danger_with_close_button: (toast) => (
          <View
            style={{
              maxWidth: "100%",
              paddingVertical: 10,
              backgroundColor: "rgb(211, 47, 47)",
              marginVertical: 4,
              borderRadius: 8,
              justifyContent: "center",
              paddingHorizontal: 16,
              flexDirection: "row",
            }}
          >
            <Text style={{ color: "white", fontWeight: "500", marginRight: 16 }}>{toast.message}</Text>
            <TouchableOpacity
              onPress={() => toast.onHide()}
              style={{
                marginLeft: "auto",
                width: 25,
                height: 25,
                borderRadius: 5,
                backgroundColor: "#333",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text style={{ color: "#fff", fontWeight: "500", marginBottom: 2.5 }}>
                x
              </Text>
            </TouchableOpacity>
          </View>
        ),
        success_with_close_button: (toast) => (
          <View
            style={{
              maxWidth: "100%",
              paddingVertical: 10,
              backgroundColor: "rgb(46, 125, 50)",
              borderRadius: 8,
              justifyContent: "center",
              paddingHorizontal: 16,
              flexDirection: "row",
            }}
          >
            <Text style={{ color: "white", fontWeight: "500", marginRight: 16 }}>{toast.message}</Text>
            <TouchableOpacity
              onPress={() => toast.onHide()}
              style={{
                marginLeft: "auto",
                width: 25,
                height: 25,
                borderRadius: 5,
                backgroundColor: "#333",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text style={{ color: "#fff", fontWeight: "500", marginBottom: 2.5 }}>
                x
              </Text>
            </TouchableOpacity>
          </View>
        ),
      }}>
        <Provider store={store}>
          <Routes />
        </Provider>
    </ToastProvider>
    </>
  );
}
