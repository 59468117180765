import {
  StyleSheet,
  Text,
  StatusBar,
  View,
  TouchableOpacity,
  TextInput,
  ScrollView
} from "react-native";
import React, { useState, useEffect } from "react";
import { colors, messages, network, ORDER_STATUS, formatDateStringToHumanReadable, customStylesForStepIndicator } from "../../constants";
import { Ionicons, FontAwesome } from "@expo/vector-icons";
import ProgressDialog from "react-native-progress-dialog";
import BasicProductList from "../../components/BasicProductList/BasicProductList";
import CustomButton from "../../components/CustomButton";
import DropDownPicker from "react-native-dropdown-picker";
import { useToast } from "react-native-toast-notifications";
import CustomInput from "../../components/CustomInput";
import Ratings from "../../components/Ratings";
import AlertPolyfill from "../../components/AlertPolyfill/AlertPolyfill";
import CustomIconButton from "../../components/CustomIconButton/CustomIconButton";
import StepIndicator from "react-native-step-indicator";
import RefreshIconAndStatus from "../../components/RefreshIconAndStatus/RefreshIconAndStatus";
import { useAuthStore } from "../../states/store";
import AskLogin from "../../components/AskLogin";

const ViewPurchaseDetailsScreen = ({ navigation, route }) => {
  const toast = useToast();
  const { purchaseId } = route.params;
  const [purchaseDetail, setPurchaseDetail] = useState();
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const user = useAuthStore((state) => state.user);
  console.log("purchaseId", purchaseId, "user", user); // this is now just id

  const [isLoading, setIsLoading] = useState(false);
  const [label, setLabel] = useState("Yükleniyor...");
  const [handleRefresh, setHandleRefresh] = useState(0)
  // start as null and pass to Ratings object. If user rates, we will update the Ratings objects.
  const [updatedSellerRating, setUpdatedSellerRating] = useState(null);
  const [sellerRatingCount, setSellerRatingCount] = useState(null);
  const [statusDisable, setStatusDisable] = useState(false);
  const [isRatingSubmitted, setIsRatingSubmitted] = useState(false);
  const [items, setItems] = useState([
    { label: ORDER_STATUS.PENDING, value: ORDER_STATUS.PENDING },
    { label: ORDER_STATUS.SHIPPED, value: ORDER_STATUS.SHIPPED },
    { label: ORDER_STATUS.DELIVERED, value: ORDER_STATUS.DELIVERED },
  ]);

  const labels = ["Ödeme", "Kargo", "Teslim"];
  const [trackingState, setTrackingState] = useState(1);

  const determineStepForStepIndicator = (status) => {
    console.log("determineStepForStepIndicator status", status)
    var stepNumber = 1
    if (status == ORDER_STATUS.PENDING) {
      stepNumber = 1
    } else if (status == ORDER_STATUS.SHIPPED) {
      stepNumber = 2
    } else if (status == ORDER_STATUS.DELIVERED) {
      stepNumber = 3
    }
    return stepNumber
  }

  const handleRatingSubmission = (productId, rating) => {

    return AlertPolyfill(
      `${rating} Yıldız Vermeyi Onayla`,
      `Bu satıcıya ${rating} vermek istediğinizden emin misiniz? Sipariş başına tek oylama hakkınız vardır ve daha sonra değiştiremezsiniz.`,
      [
        {
          text: "Onayla",
          onPress: () => {
            submitRating(productId, rating);
          },
        },
        {
          text: "Vazgeç",
        },
      ]
    );
  }

  const submitRating = (productId, rating) => {
    setIsLoading(true);
    console.log("purchaseDetail", purchaseDetail, "rating", rating, "productId", productId);
    var myHeaders = new Headers();
    myHeaders.append("x-auth-token", user?.token);
    myHeaders.append("Content-Type", "application/json")

    var rateRequestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify({ ratingValue: rating, productId: productId })
    };

    fetch(`${network.serverip}/orders/${purchaseId}/rate-seller`, rateRequestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          setIsLoading(false);
          toast.show("Oylamanız Başarıyla Gönderildi", { placement: "bottom", type: "success_with_close_button" });
          {/**result looks like this
          //   {
          //     "success": true,
          //     "message": "rating is successfully applied",
          //     "updatedSellerRatings": 4,
          //     "updatedSellerTotalRatingsCount": 5
          // }
        */}
          setUpdatedSellerRating(result.updatedSellerRatings);
          setSellerRatingCount(result.updatedSellerTotalRatingsCount);
          setIsRatingSubmitted(true); // we dont want to allow multiple submissions. Otherwise, users would just click 5 star repeatedly.
        } else if (result.message === "Buyer already rated seller for this product.") {
          setIsLoading(false);
          AlertPolyfill(
            `Çoktan Oylamanızı Verdiniz`,
            `Bu satıcıya çoktan oy verdiniz ve bir daha oy veremezsiniz`,
            [
              {
                text: "Tamam",
                onPress: () => {
                },
              },
            ]
          );
          return;
        } else {
          setIsLoading(false);
          toast.show(messages.genericErrorMessage, {
            placement: "bottom",
            type: "danger_with_close_button"
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.show(messages.genericErrorMessage, {
          placement: "bottom",
          type: "danger_with_close_button"
        });
        console.log("error", error);
      });
  }

  const fetchPurchaseDetails = () => {
    var myHeaders = new Headers();
    myHeaders.append("x-auth-token", user?.token);
    myHeaders.append("Content-Type", "application/json")

    var purchaseDetailOptions = {
      method: "GET",
      headers: myHeaders,
    };
    console.log("tata")
    fetch(`${network.serverip}/purchases/${purchaseId}`, purchaseDetailOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("order result", result)
        if (result.success) {
          setIsLoading(false);
          {/**result looks like this
          //   {
          //     "success": true,
          //     "message": "rating is successfully applied",
          //     "updatedSellerRatings": 4,
          //     "updatedSellerTotalRatingsCount": 5
          // }
        */}
          setPurchaseDetail(result.data);
        } else {
          setIsLoading(false);
          toast.show(messages.genericErrorMessage, {
            placement: "bottom",
            type: "danger_with_close_button"
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.show(messages.genericErrorMessage, {
          placement: "bottom",
          type: "danger_with_close_button"
        });
        console.log("error", error);
      });
  }
  //method to convert the Data into dd-mm-yyyy format
  const dateFormat = (datex) => {
    let t = new Date(datex);
    const date = ("0" + t.getDate()).slice(-2);
    const month = ("0" + (t.getMonth() + 1)).slice(-2);
    const year = t.getFullYear();
    const hours = ("0" + t.getHours()).slice(-2);
    const minutes = ("0" + t.getMinutes()).slice(-2);
    const seconds = ("0" + t.getSeconds()).slice(-2);
    const time = tConvert(`${hours}:${minutes}:${seconds}`);
    const newDate = `${date}-${month}-${year}, ${time}`;

    return newDate;
  };

  const handleReportProblem = (selectedItem) => {
    console.log("inside handleReportProblem")
    console.log("selectedItem", selectedItem)
    setIsLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-auth-token", user?.token);

    var checkDisputeBody = JSON.stringify({
      disputedOrderNumber: selectedItem.orderNumber,
      productId: selectedItem.product._id,
    });

    var checkDisputeRequestOptions = {
      method: "POST",
      headers: myHeaders,
      body: checkDisputeBody
    };

    fetch(
      `${network.serverip}/${user?._id}/purchases/check-dispute-window`,
      checkDisputeRequestOptions
    ) //API call
      .then((response) => response.json())
      .then((result) => {
        if (result.success == true) {
          setIsLoading(false);
          navigation.navigate("dispute", { disputedOrderNumber: selectedItem.orderNumber, product: selectedItem.product })
        } else {
          setIsLoading(false);
          toast.show("Ürün teslim edildi ve üzerinden 5 gün geçti. Bu nedenle, artık bu ürünle ilgili herhangi bir sorun bildiremezsiniz", {
            placement: "bottom",
            type: "danger_with_close_button"
          });
          console.log("error", error);
        }
      })
      .catch((error) => {
        toast.show(messages.genericErrorMessage, { placement: "bottom", type: "danger_with_close_button" })
        console.log("error", error);
        setIsLoading(false);
      });

    };
    // calculate the total cost and set the all requried variables on initial render
    useEffect(() => {
      const fetchDetails = async () => {
        try {
          console.log("Fetching purchase details...");
          fetchPurchaseDetails();
        } catch (error) {
          console.error("Error in fetching purchase details:", error);
        }
      };
    
      fetchDetails();
    }, [purchaseId]);
    

    useEffect(() => {
    }, [handleRefresh]);

    return (
      <View style={styles.container}>
        <ProgressDialog visible={isLoading} label={label} />
        <StatusBar backgroundColor={colors.primary}></StatusBar>
        <View style={styles.TopBarContainer}>
          <TouchableOpacity
            onPress={() => {
              navigation.navigate("viewpurchases");
            }}
          >
            <Ionicons
              name="arrow-back-circle-outline"
              size={30}
              color={colors.muted}
            />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              setIsLoading(true);
              setHandleRefresh((prevState => prevState + 1))
              setIsLoading(false);
            }
            }
            style={styles.refreshTimeContainer}>
            <FontAwesome name="refresh" size={24} color="black" />
            <Text style={styles.secondarytextSm}>{formatDateStringToHumanReadable(Date.now())}</Text>
          </TouchableOpacity>
        </View>
        <View style={styles.screenNameContainer}>
          <View>
            <Text style={styles.screenNameText}>Sipariş Detayları</Text>
          </View>
          <View>
            <Text style={styles.screenNameParagraph}>
              Siparişiniz hakkında tüm detayları burada görebilirsiniz
            </Text>
          </View>
        </View>
        {purchaseDetail ? (
        <View
          style={styles.bodyContainer}
        >
          <View>
            <Text style={styles.containerNameText}>Sipariş Bilgileri</Text>
          </View>
          <View style={styles.orderInfoContainer}>
            <Text style={styles.secondarytextMedian}>
              Sipariş # {purchaseDetail?._id}
            </Text>
            <Text style={styles.secondarytextMedian}>Toplam: {purchaseDetail?.totalAmount}₺</Text>
            <Text style={styles.secondarytextMedian}>
              Sipariş Edildi : {formatDateStringToHumanReadable(purchaseDetail?.createdAt)}
            </Text>
            {/* {purchaseDetail?.shippedOn && (
            <Text style={styles.secondarytextSm}>
              Postaya Verildi: {formatDateStringToHumanReadable(purchaseDetail?.shippedOn)}
            </Text>
          )}
          {purchaseDetail?.deliveredOn && (
            <Text style={styles.secondarytextSm}>
              Teslim Edildi: {formatDateStringToHumanReadable(purchaseDetail?.deliveredOn)}
            </Text>
          )} */}
          </View>
          <View style={styles.containerNameContainer}>
            <View>
              <Text style={styles.containerNameText}>Gönderim ve Fatura Adresi</Text>
            </View>
          </View>
          <View style={styles.ShipingInfoContainer}>
            <Text style={styles.secondarytextMedian}>
              Alıcı Adı: {purchaseDetail?.buyerFirstName}
            </Text>
            <Text style={styles.secondarytextMedian}>
              Alıcı Soyadı: {purchaseDetail?.buyerLastName}
            </Text>
            <Text style={styles.secondarytextMedian}>
              Alıcı Telefonu: {purchaseDetail?.buyerPhone}
            </Text>
            <Text style={styles.secondarytextMedian}>
              Alıcı Emaili: {purchaseDetail?.buyerEmail}
            </Text>
            <Text style={styles.secondarytextMedian}>
              Alıcı Adresi: {`${purchaseDetail?.streetAddress}, ${purchaseDetail?.district}, ${purchaseDetail?.city}, ${purchaseDetail?.postalCode}`}
            </Text>
          </View>
          <View style={styles.containerNameContainer}>
            <View>
              <Text style={styles.containerNameText}>Teslimat Detayları</Text>
            </View>
          </View>
          <View style={styles.orderItemsContainer}>
            <View style={styles.orderItemContainer}>
              <View
                style={styles.orderSummaryContainer}
              >
                {purchaseDetail?.items?.map((item, index) => (
                  <View style={styles.orderSummaryContainerForEachProduct} key={index}>
                    {/* <View style={{ marginTop: 15, width: "100%" }}> */}
                    <StepIndicator
                      customStyles={customStylesForStepIndicator}
                      currentPosition={determineStepForStepIndicator(item.status)}
                      stepCount={3}
                      labels={labels}
                    />
                    {/* </View> */}
                    <BasicProductList
                      seller={item?.sellerUser?.username}
                      quantity={item.quantity}
                      description={item.product.description}
                      imageUri={`${network.serverip}/uploads/${item.product.filename}`}
                      title={item.product.title}
                      price={item.product.price}
                      status={item.status}
                      createdAt={item.createdAt}
                      deliveredOn={item.deliveredOn}
                      shippedOn={item.shippedOn}
                    />
                    <TouchableOpacity styles={styles.ratingsContainer}>
                      <Ratings
                        customStyle={styles.ratingsContainer}
                        labelText={"Satıcıyı Oylayınız"}
                        // readOnly={isRatingSubmitted}
                        initialRatingsCount={item?.sellerUser?.ratingsCount}
                        initialRating={item?.sellerUser?.ratings} // Initial rating value
                        updatedRating={updatedSellerRating || item?.sellerUser?.ratings}
                        updatedRatingsCount={sellerRatingCount || item?.sellerUser?.ratingsCount}
                        onRatingChange={(newRating) => {
                          // Handle the new rating value here
                          handleRatingSubmission(item?.product?._id, newRating)
                          console.log('New Rating:', newRating);
                          console.log("product inside map", product)
                        }}
                      />
                    </TouchableOpacity>
                    <TouchableOpacity style={{ backgroundColor: colors.lightpink, borderRadius: 3, width: "fit-content" }}>
                      <CustomIconButton text={"Problem Bildir"} image={require("../../assets/icons/warning.png")} onPress={() => handleReportProblem(item)} />
                    </TouchableOpacity>
                    <Text style={{ color: "black", opacity: 0.8 }}>Sipariş tamamlandıktan sonra 5 güne kadar bir sorun bildirebilirsiniz.</Text>
                  </View>
                ))}
              </View>
            </View>
          </View>
          <View style={styles.emptyView}></View>
        </View>
        ) :  <>
        <View style={styles.bodyContainer}>
        <View style={styles.noPurchasesContainer}>
          <Text style={styles.noPurchasesViewText}>
            Sipariş bulunamadı.
          </Text>
        </View>
        {!user && <View style={{ margin: 5 }}><AskLogin /></View>}
        </View>
      </>}
      </View>
    )
};

  export default ViewPurchaseDetailsScreen;

  const styles = StyleSheet.create({
    noPurchasesContainer:{
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start"
    },
    noPurchasesViewText: {
        fontSize: 18, // Adjust the font size as needed
        fontWeight: 'bold', // Bold text
        marginTop: 5,
        color: "black",
        padding: 2,
        backgroundColor: "yellow",
    },
    refreshTimeContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    },
    orderSummaryContainerForEachProduct: {
      borderWidth: 2,
      borderColor: "black",
      borderRadius: 4,
      padding: 2,
      marginBottom: 20
    },
    ratingsContainer: {
      paddingLeft: 10,
      flexWrap: 'wrap'
    },
    container: {
      flexDirecion: "row",
      backgroundColor: colors.light,
      alignItems: "center",
      justifyContent: "center",
      padding: 20,
      flex: 1,
    },
    TopBarContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },

    screenNameContainer: {
      marginTop: 10,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      marginBottom: 5,
    },
    screenNameText: {
      fontSize: 30,
      fontWeight: "800",
      color: colors.muted,
    },
    screenNameParagraph: {
      marginTop: 10,
      fontSize: 15,
    },
    bodyContainer: { flex: 1, width: "100%", padding: 5 },
    ShipingInfoContainer: {
      marginTop: 5,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      backgroundColor: colors.white,
      padding: 10,
      borderRadius: 10,
      borderColor: colors.muted,
      elevation: 5,
      marginBottom: 10,
    },
    containerNameContainer: {
      marginTop: 10,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
    },
    containerNameText: {
      fontSize: 18,
      fontWeight: "800",
      color: colors.muted,
    },
    secondarytextSm: {
      color: colors.muted,
      fontSize: 13,
    },
    orderItemsContainer: {
      marginTop: 5,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      backgroundColor: colors.white,
      padding: 10,
      borderRadius: 10,

      borderColor: colors.muted,
      elevation: 3,
      marginBottom: 20,
    },
    orderItemContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    orderItemText: {
      fontSize: 13,
      color: colors.muted,
    },
    orderSummaryContainer: {
      backgroundColor: colors.white,
      borderRadius: 10,
      padding: 10,
      maxHeight: 220,
      width: "100%",
      marginBottom: 5,
    },
    mainBottomContainer: {
      width: "100%"
    },
    bottomContainer: {
      backgroundColor: colors.white,
      width: "100%",
      height: 70,
      borderTopLeftRadius: 10,
      borderTopEndRadius: 10,
      elevation: 5,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",

      paddingLeft: 10,
      paddingRight: 10,
    },
    orderInfoContainer: {
      marginTop: 5,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      backgroundColor: colors.white,
      padding: 10,
      borderRadius: 10,

      borderColor: colors.muted,
      elevation: 1,
      marginBottom: 10,
    },
    primarytextMedian: {
      color: colors.primary,
      fontSize: 15,
      fontWeight: "bold",
    },
    secondarytextMedian: {
      color: colors.muted,
      fontSize: 15,
      fontWeight: "bold",
    },
    emptyView: {
      height: 200,
      marginTop: 200,
    },
  });
