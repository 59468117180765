import {
  StyleSheet,
  Text,
  View,
  StatusBar,
  TouchableOpacity,
  Alert,
  WebView,
  ScrollView
} from "react-native";
import React, { useState } from "react";
import UserProfileCard from "../../components/UserProfileCard/UserProfileCard";
import { Ionicons } from "@expo/vector-icons";
import { MaterialIcons } from "@expo/vector-icons";
import { network, colors } from "../../constants";

const CookiesScreen = ({ navigation, route }) => {
  const [showBox, setShowBox] = useState(true);
  const [error, setError] = useState("");

  return (
    <View style={styles.container}>
      <StatusBar backgroundColor={colors.primary}></StatusBar>
      <View style={styles.TopBarContainer}>
        <TouchableOpacity
          onPress={() => {
            navigation.goBack(null);
          }}
        >
          <Ionicons
            name="arrow-back-circle-outline"
            size={30}
            color={colors.muted}
          />
        </TouchableOpacity>
      </View>
      <View style={styles.screenNameContainer}>
        <Text style={styles.screenNameText}>Çerez Politikası</Text>
      </View>
      <View style={styles.OptionsContainer}>
        <ScrollView>
        <View style={styles.primaryTextContainer}>
          <Text style={styles.primaryText}>Çerez Politikası</Text>
        </View>
          <Text>
            {`
         
          Bu metin, 6698 sayılı Kişisel Verilerin Korunması Kanunu'nun ("Kanun") 10. maddesi ile Aydınlatma Yükümlülüğünün Yerine Getirilmesinde Uyulacak Usul ve Esaslar Hakkında Tebliğ kapsamında veri sorumlusu sıfatıyla Raftan Yazılım İnternet Eğitim Ticaret Limited Şirketi ("Raftan Kitap") tarafından hazırlanmıştır

          Raftan Kitap olarak https://raftankitap.com.tr/ adresli internet sitesi ("Site") içerisinde yer alan bazı alanlarda birinci ve üçüncü taraf çerezler kullanmaktayız. Bu Çerez Aydınlatma Metni ("Aydınlatma Metni"), Raftan Kitap tarafından yönetilen Site için geçerli olup, çerezler Aydınlatma Metni'nde açıklanan şekilde kullanılacaktır.

          Bu Aydınlatma Metni'nin amacı, internet sitemizde kullanılan çerezlerin cihazınıza yerleştirilmesi aracılığıyla otomatik yolla elde edilen kişisel verilerin işlenmesine ilişkin olarak, hangi amaçlarla hangi tür çerezleri kullandığımızı ve bu çerezleri nasıl yönetebileceğiniz hakkında sizlere bilgi vermektir. İnternet sitemizde kullandığımız zorunlu çerezler haricindeki çerezler için kullanıcıların açık rızaları alınmakta ve kullanıcılara istedikleri her zaman açık rızaya ilişkin tercihlerini değiştirebilme olanağı sağlanmaktadır.

          Kullanıcılar, çerez yönetim paneli üzerinden internet sitemizde kullanılan çerez çeşitlerini görebilmekte ve zorunlu çerezler dışında kalan tüm çerezler için "aktif" veya "inaktif" seçenekleri ile tercihlerini belirleyebilmektedir. Yine bu panel üzerinden kullanıcılar tercihlerini her zaman değiştirme imkanına sahiptir.

          Çerez Nedir?

          Çerezler bir internet sitesi tarafından cihazınızda oluşturulan ve isim-değer formatında veri barındıran küçük metin dosyalarıdır. Çerezler, ziyaret ettiğiniz internet sitesinin cihazınızda bilgi saklamasını ve bu bilgilerin sonraki ziyaretleriniz sırasında kullanmasını mümkün kılmaktadır. Bir internet sitesi tarafından oluşturulan çerezler siteye erişim için kullandığınız internet tarayıcısı tarafından saklanmakta olup çerezler, ziyaretçilere ilişkin isim, cinsiyet veya açık adres gibi kişisel verileri içermezler. Çerezlerin içerdiği bilgilere ancak aynı internet tarayıcısını kullanmanız halinde ve yalnızca ilgili çerezi oluşturan internet sitesi https://www.mngkargo.com.tr/ tarafından erişilebilmektedir

          Çerezler günümüzde, internet teknolojilerinin önemli bir parçası haline gelmiştir ve temel işlevleri çevrimiçi ziyaretçinin tercihlerinin hatırlanması ve bağlantı sırasında cihazın tanınması olup, neredeyse her internet sitesinde çerez kullanımı söz konusudur

          Çerezleri Kim, Nasıl Gönderir?

          Çerezler, gezintiniz sırasında cihazınızda bulunan internet tarayıcınız (Chrome, Firefox, Safari, Edge vb.) ile Raftan Kitap bünyesindeki internet sitesi sunucuları arasında kurulan iletişim vasıtasıyla gönderilmektedir.

          Çerez Tipleri:

          • Sahipliklerine göre:

          • Birinci Taraf Çerezler Doğrudan ziyaret edilen web sitesi veya Raftan Kitap tarafından cihaza yerleştirilmektedir.

          • Üçüncü Taraf Çerezler Raftan Kitap ile iş birliği içerisinde olan, reklam veren veya analitik sistem gibi üçüncü bir tarafça cihaza yerleştirilen çerezlerdir.

          • Aktif olduğu süreye göre:

          • Oturum Çerezleri Kullanıcının tarayıcıyı kapatması veya oturumun sona ermesi süresine kadar depolanabilecek verileri sağlamaktadır. Kısa süreli çerezlerdir.

          • Kalıcı Çerezler Çerezin kullanıcı tarafından silinme tarihine kadar veya çerez için belirli son kullanma tarihine kadar sürücüde yer alan çerezlerdir. Kalıcı çerezlerin kodlarında değişken süreli, yazılı bir son kullanma tarihi vardır.

          • Kullanım amaçlarına göre:

          • Zorunlu Çerezler Web sitesinde gezinmek ve sitenin güvenli alanlarına erişim gibi özelliklerin kullanılması için gerekli olan çerezlerdir.

          • İşlevsel (Tercih) Çerezleri Web sitesinde geçmişte yapılan seçimlerin (kullanıcı adı bilgisi, parola bilgisi, hangi dilin tercih edildiği vb.) ne olduğunu hatırlamasını sağlar. Web sitesine otomatik girişi sağlayan tercih çerezleri, işlevsel çerezler olarak da adlandırılmaktadır..

          • İstatistik Çerezleri Performans çerezleri olarak da bilinen istatistik çerezleri, web sitesinin kullanımına ilişkin anonim, istatistiksel veri sağlayan çerezlerdir. İstatistik çerezleri ile kullanıcının web sitesini nasıl kullandığı, ziyaret ettiği sayfalar, tıkladığı bağlantılar depolanabilmektedir. Anonim hale getirilen istatistik çerezleri, web sitesi kullanıcısını tanımlamak için kullanılmamaktadır. Bu çerezlerin amacı, web sitesini daha işlevsel hale getirmektir.

          • Pazarlama Çerezleri İlgi alanlarınızın bir profilini oluşturmak ve diğer sitelerdeki alakalı reklamları göstermek amacıyla faaliyet gösteren firmalar tarafından kullanılabilen çerezlerdir.

          Kişisel Verilerin Hangi Amaçlarla İşleneceği ve Hukuki Sebepler

          Zorunlu çerezler, talep etmiş olduğunuz bir bilgi toplumu hizmetinin (log-in olma, form doldurma ve gizlilik tercihlerinin hatırlanması) yerine getirilebilmesi amacıyla kullanılmaktadır. Bu çerezler aracılığıyla toplanan kişisel verileriniz, Kanun'un 5. maddesinin 2. fıkrasının (c) bendi "Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin işlenmesinin gerekli olması" veya (f) bendi "İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu olması" kapsamında işlenmektedir.

          Reklam ve Pazarlama çerezleri, iş ortaklarımız tarafından ilgi alanlarınıza göre profilinizin çıkarılması ve size ilgili reklamlar göstermek amacıyla kullanılmaktadır. Bu çerezler aracılığıyla toplanan kişisel verileriniz, Kanun'un 5. maddesinin 1. fıkrası kapsamında açık rızanızın alınması suretiyle işlenmektedir.

          Performans / Analitik çerezler, internet sitelerinde kullanıcıların davranışlarını analiz etmek amacıyla istatistiki ölçümüne imkân veren çerezlerdir. Bu çerezler, sitenin iyileştirilmesi için sıklıkla kullanılmakta olup, bu duruma reklamların ilgili kişiler üzerindeki etkisinin ölçümü de dâhildir. İnternet sitesi sahipleri tarafından, tekil ziyaretçilerin sayısını tahmin etmek, bir internet sayfasına götüren en önemli arama motoru anahtar kelimelerini tespit etmek veya internet sitesinde gezinme durumunu izlemek için kullanılmaktadırlar. Bu çerezler aracılığıyla toplanan kişisel verileriniz, Kanun'un 5. maddesinin 1. fıkrası kapsamında açık rızanızın alınması suretiyle işlenmektedir.

          İşlevsel çerezler, internet sayfamızı daha işlevsel kılmak ve kişiselleştirmek (gizlilik tercihleriniz hariç olmak üzere diğer tercihlerinizin siteye tekrar girdiğinizde hatırlanmasını sağlamak) amaçlarıyla kullanılmaktadır. Bu çerezler aracılığıyla toplanan kişisel verileriniz, Kanun'un 5. maddesinin 1. fıkrası kapsamında açık rızanızın alınması suretiyle işlenmektedir.

          Sitemizde Kullanılan Çerezlere İlişkin Bilgiler

          İnternet sitemizde yer alan çerezlere ilişkin bilgiler için lütfen tıklayınız.

          Çerezler Aracılığıyla İşlenen Kişisel Verilerin Aktarılması

          Çerezler aracılığı ile yürütülen faaliyetlerimiz kapsamında kişisel verileriniz; Raftan Kitap hizmetlerinin size özelleştirilerek geliştirilmesi, pazarlama faaliyetlerinin yürütülebilmesi, iş faaliyetlerinin yürütülmesi ve denetimi, mal ve hizmet satın alım süreçlerinin yürütülmesi amaçları ile tedarikçilerimize aktarılmaktadır.

          Ayrıca yukarıdaki tabloda belirtilen istatistik ve pazarlama çerezlerine onay vermeniz halinde kişisel verileriniz; saklama ve arşiv faaliyetlerinin yürütülmesi amacıyla sınırlı olarak yurt dışında yerleşik üçüncü taraf altyapı sağlayıcı Google ve Facebook aracılığıyla yurt dışındaki sunucularda işlenecektir. Bu kapsamda; tarafınıza ait kişisel veriler, açık rızanıza dayalı olarak, gerekli teknik ve idari tedbirler alınarak sunucuları yurt dışında bulunan hizmet sağlayıcılar tarafından işlenecektir.

          Çerezlerin Yönetimi

          İnternet sitemizi kullanabilmek için çerez kullanımı zorunlu değildir, fakat tarayıcınızı çerezleri kabul etmemeye ayarlamanız halinde kullanıcı deneyiminizin kalitesi düşebilir ve sitelerimizin çeşitli işlevleri bozulabilir. Özellikle teknik çerezler, Site'nin temel işlevlerini yerine getirebilmesini sağlamaktadır. Teknik çerezler Site'nin teknik olarak çalışmasını tesis ettiğinden bunları kapatmanız halinde, Site'de bazı fonksiyonların gereği gibi çalışmaması söz konusu olabilir.

          Çerez yönetim panelinde yer alan "Tercihleri Yönet" alanından ilgili çerez türlerine dair butonları tercihinize göre açık veya kapalı konuma getirerek "Ayarları Kaydet" veya ‘‘Hepsine İzin Ver'' butonlarına tıklayabilir, ayarlarınızı etkin hâle getirmek için sayfayı yenileyebilirsiniz.

          Tarayıcınızı; çerezleri tüm siteler veya belirli siteler için engelleyecek şekilde, çerez oluşturulduğunda uyarı verecek şekilde, üçüncü taraf çerezleri engelleyecek şekilde veya tüm çerezleri oturum çerezi gibi sayacak şekilde yapılandırabilirsiniz. Ek olarak, tarayıcınız üzerinden çerezleri silebilir veya tarayıcınızda saklanan çerezlerin listesini ve değerlerini görebilirsiniz. Tarayıcınızın çerez yönetimi işlevleri hakkında detaylı bilgi için lütfen aşağıdaki ilgili linke tıklayarak tarayıcınızın internet sitesinden bilgi alınız.

          Ayrıca;

          • Google Analytics tarafından yönetilen Çerezleri kapatmak için tıklayınız.

          • Google tarafından sağlanan kişiselleştirilmiş reklam deneyimini yönetmek için tıklayınız.

          • Birçok firmanın reklam faaliyetleri için kullandığı çerezler bakımından tercihler Your Online Choices üzerinden yönetilebilir.

          Çerezler ile ilgili daha detaylı bilgi için, https://www.aboutcookies.org veya https://www.youronlinechoices.eu/ adresini ziyaret edebilir ya da "Privacy Badger" uygulamasını kullanabilirsiniz (https://www.eff.org/tr/privacybadger).

          Çerez ayarlarını değiştirmek için tıklayınız.

          İlgili Kişilerin Talepleri

          Kanun'un 11. maddesi kapsamındaki haklarınız ve kişisel verilerinizin işlenmesi hakkında ayrıntılı bilgi için Aydınlatma Metni'ni inceleyebilirsiniz. Raftan Kitap çerezlerle ilgili kullanım şartlarını ve Çerez Aydınlatma Metni 'ni değiştirme hakkını saklı tutar.
            `}
          </Text>
          </ScrollView>
      </View>
    </View>
  );
};

export default CookiesScreen;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexDirecion: "row",
    backgroundColor: colors.light,
    alignItems: "center",
    justifyContent: "flex-start",
    padding: 20,
    flex: 1,
  },
  TopBarContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  UserProfileCardContianer: {
    width: "100%",
    height: "25%",
  },
  primaryTextContainer: {
    padding: 20,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "100%",
    paddingTop: 10,
    paddingBottom: 10,
  },
  primaryText: {
    fontSize: 20,
    fontWeight: "bold",
  },
  screenNameContainer: {
    marginTop: 10,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: 10,
  },
  screenNameText: {
    fontSize: 30,
    fontWeight: "800",
    color: colors.muted,
  },
  OptionsContainer: {
    width: "100%",
    flex: 1,
    justifyContent:'center'
  },
});
