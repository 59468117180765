import { StyleSheet, Image, Text, View, StatusBar } from "react-native";
import React, { useEffect, useState } from "react";
import SuccessImage from "../../assets/image/success.png";
import CustomButton from "../../components/CustomButton";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {colors} from "../../constants";
import { useAuthStore } from "../../states/store";

const OrderConfirmScreen = ({ navigation }) => {
  const user = useAuthStore((state) => state.user);

  return (
    <View style={styles.container}>
      <StatusBar backgroundColor={colors.primary}></StatusBar>
      <View style={styles.imageContainer}>
        <Image source={SuccessImage} style={styles.Image} />
      </View>
      <Text style={styles.secondaryText}>Siparişiniz onaylandı</Text>
      <View>
        <CustomButton
          text={"SİPARİŞLERİMİ GÖR"}
          onPress={() => navigation.navigate("viewpurchases")}
        />
      </View>
    </View>
  );
};

export default OrderConfirmScreen;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: colors.light,
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: 40,
    flex: 1,
  },
  imageContainer: {
  },
  Image: {
    width: 400,
    height: 300,
  },
  secondaryText: {
    fontSize: 20,
    fontWeight: "bold",
  },
});
