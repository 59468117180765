import {
  StyleSheet,
  StatusBar,
  View,
  TouchableOpacity,
  Text,
  Image,
  FlatList,
  RefreshControl,
  ScrollView,
  SafeAreaView,
  TextInput,
  Button,
  ActivityIndicator,
  useWindowDimensions,
  Animated,
  Platform,
} from "react-native";
import { AntDesign, Ionicons } from "@expo/vector-icons";
import React, { useEffect, useState, useCallback } from "react";
import cartIcon from "../../assets/icons/cart_beg.png";
import scanIcon from "../../assets/icons/scan_icons.png";
import easybuylogo from "../../assets/logo/logo.png";
import { colors, messages } from "../../constants";
import CustomIconButton from "../../components/CustomIconButton/CustomIconButton";
import ProductCard from "../../components/ProductCard/ProductCard";
import { network } from "../../constants";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreaters from "../../states/actionCreators/actionCreators";
import { SliderBox } from "react-native-image-slider-box";
import DropDownPicker from "react-native-dropdown-picker";
import AsyncStorage from "@react-native-async-storage/async-storage";
import CartIconAndStatus from "../../components/CartIconAndStatus/CartIconAndStatus";
import ProgressDialog from "react-native-progress-dialog";
import { MaterialCommunityIcons } from '@expo/vector-icons';
import jwt_decode from "jwt-decode";
import { useToast } from "react-native-toast-notifications";
import { FontAwesome } from '@expo/vector-icons';
import { useAuthStore } from "../../states/store";
import { useIsFocused } from "@react-navigation/native";
// Source: https://www.freecodecamp.org/news/css-media-queries-breakpoints-media-types-standard-resolutions-and-more
// 320px — 480px: Mobile devices
// 481px — 768px: iPads, Tablets
// 769px — 1024px: Small screens, laptops
// 1025px — 1200px: Desktops, large screens
// 1201px and more —  Extra large screens, TV

const types = [
  {
    _id: "62fe244f58f7aa8230817f89",
    title: "Popüler",
    tag: "popular",
    image: require("../../assets/icons/trending-up.png"),
  },
  {
    _id: "62fe243858f7aa8230817f86",
    title: "Keşfet",
    tag: "discover",
    image: require("../../assets/icons/discover.png"),
  },
  {
    _id: "62fe241958f7aa8230817f83",
    title: "Yeni Eklenen Ürünler",
    tag: "recent",
    image: require("../../assets/icons/newly_added.png"),
  },
  {
    _id: "62fe241958f7aa8230817f81",
    title: "Hepsi",
    tag: "all",
    image: require("../../assets/icons/all_books.png")
  }
];

const itemsForConditionDropdown = [
  {
    label: 'Mükemmel',
    value: 'Mükemmel',
  },
  {
    label: 'İyi',
    value: 'İyi',
  },
  {
    label: 'Kabul Edilir',
    value: 'Kabul Edilir',
  }];

const slides = [
  require("../../assets/image/banners/anime.png"),
  require("../../assets/image/banners/ayt-kitaplari.jpg"),
  require("../../assets/image/banners/cocuk-kitaplari.png"),
  require("../../assets/image/banners/fantastik-kitaplar.png"),
  require("../../assets/image/banners/kpss-kitaplari.jpg"),
  require("../../assets/image/banners/netflix.png"),
  require("../../assets/image/banners/okula-donus.jpg"),
  require("../../assets/image/banners/okula-yardimci.jpg"),
  require("../../assets/image/banners/tyt-kitaplari.jpg")
];

const OFFLINE_PRODUCTS = [
  {
    "_id": "650cbf27f3203c2d40388b07",
    "title": "Spy Family",
    "price": 185,
    "filename": "1695334183748-298967412.jpg",
    "quantity": 0,
    "tags": ["popular"],
  },
  {
    "_id": "6520b7bf3fa6cfe6974581b5",
    "title": "Kalbin Dongusu",
    "price": 12,
    "filename": "1696643007194-724129447.jpg",
    "quantity": 0,
    "tags": ["popular"],
  },
  {
    "_id": "6510ff28c87099cb484182ff",
    "title": "testing crono",
    "price": 121,
    "filename": "1695612712706-620453785.jpg",
    "quantity": 0,
    "tags": ["popular", "discover"],
  },
  {
    "_id": "65022bb7b89c9b3de472db9d",
    "title": "InterviewRamp",
    "price": 102.5,
    "filename": "1694646584084-705958017.jpg",
    "quantity": 917,
    "tags": ["popular"],
  },
  {
    "_id": "650fda35c9649fa56e2573c5",
    "title": "Siyam 3 - Küllerinden",
    "price": 220,
    "filename": "1695538243781-509861295.jpg",
    "quantity": null,
    "tags": ["popular", "discover"],
  },
  {
    "_id": "64fa843a46c2bf1aca44e8ba",
    "title": "5. Sınıf Süper İkili Sosyal Bilgiler Seti",
    "price": 3000,
    "filename": "1695524065874-487145810.jpg",
    "quantity": 9998,
    "tags": ["popular"],
  },
  {
    "_id": "64fa8d2d46c2bf1aca44e94a",
    "title": "8. Sınıf Süper İkili Matematik Seti",
    "price": 360,
    "filename": "1695416367040-590331635.jpg",
    "quantity": 0,
    "tags": ["popular", "discover"],
  },
  {
    "_id": "6511f8f9428567e7f34e8359",
    "title": "Var misin ",
    "price": 344,
    "filename": "1695676665860-453252876.jpg",
    "quantity": 0,
    "tags": ["popular", "recent"],
  },
  {
    "_id": "64fa855646c2bf1aca44e8c7",
    "title": "Gayriresmi Harry Potter Yemek Kitabi",
    "price": 400,
    "filename": "1695416042623-488104676.jpg",
    "quantity": 0,
    "tags": ["popular"],
  },
  {
    "_id": "651f3c5befdd9be35f3f898b",
    "title": "Kutup Yıldızı 5: Güz Fırtınası",
    "price": 199,
    "filename": "1696643429401-344610903.jpg",
    "quantity": 989,
    "tags": ["popular"],
  },
  {
    "_id": "650dee97f4a0e3d74e8134b3",
    "title": "Yabancı: Elyel",
    "price": 1000,
    "filename": "1695416409980-952064219.jpg",
    "quantity": 2,
    "tags": ["popular"],
  },
  {
    "_id": "64fa8cdd46c2bf1aca44e947",
    "title": "Alaskaya Giderken",
    "price": 360,
    "filename": "1695416394203-846068607.jpg",
    "quantity": 0,
    "tags": ["popular"],
  },
  {
    "_id": "64fa8d6146c2bf1aca44e94f",
    "title": "8. Sınıf Süper İkili Fen Bilimleri Seti",
    "price": 300,
    "filename": "1695416374122-677019179.jpg",
    "quantity": 0,
    "tags": ["popular"],
  },
  {
    "_id": "6506c6b5969c1deadd549cc7",
    "title": "Kurtlara Söyle Eve Döndüm",
    "price": 120,
    "filename": "1694942901294-209008922.jpg",
    "quantity": null,
    "tags": ["popular"],
  },
  {
    "_id": "64fa8c9546c2bf1aca44e944",
    "title": "Kayıp Eczacı",
    "price": 150,
    "filename": "1695416349854-272629792.jpg",
    "quantity": 0,
    "tags": ["popular"],
  },
  {
    "_id": "650d440af0ab35e968905d1f",
    "title": "Gece Yarısı Kütüphanesi",
    "price": 250,
    "filename": "1695368202649-997023365.jpg",
    "quantity": 0,
    "tags": [],
  },
  {
    "_id": "6785c3a610ffe7eabde5bb07",
    "title": "Çin’de Modern Medeniyet ve Kültür Anlayışı",
    "price": 200,
    "filename": "1736819622323-441027950.jpg",
    "quantity": 989,
    "tags": ["discover", "recent"],
  }
];
const NO_PRODUCT_FOUND_TEXT_WITHOUT_FILTERS = "Platformda Kitap Bulunmamıştır"
const HomeScreen = ({ navigation, route }) => {
  const toast = useToast();
  const windowWidth = useWindowDimensions().width;
  const windowHeight = useWindowDimensions().height;
  const user = useAuthStore((state) => state.user);
  const cartproduct = useSelector((state) => state.product);
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([])
  const [chosenCategory, setChosenCategory] = useState("")
  const [itemsForCategoriesDropdown, setItemsForCategoriesDropdown] = useState([])
  const [openCategoryDropdownPicker, setOpenCategoryDropdownPicker] = useState(false);
  const { addCartItem } = bindActionCreators(actionCreaters, dispatch);
  const [isLoading, setIsLoading] = useState(false);
  const [foundProducts, setFoundProducts] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const [searchItems, setSearchItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState()
  const [open, setOpen] = useState(false);
  const [alertType, setAlertType] = useState("error");
  const [orders, setOrders] = useState([]);
  const [label, setLabel] = useState("Yükleniyor...");
  const [searchPhrase, setSearchPhrase] = useState("")
  const [loadMoreForInfiniteScroll, setLoadMoreForInfiniteScroll] = useState(false);
  const [pageNum, setPageNum] = useState(1);
  const [size, setSize] = useState(3);
  const [numColumns, setNumColumns] = useState(3);
  const [searchSectionVisible, setSearchSectionVisible] = useState(false);
  const [lastBatch, setLastBatch] = useState()
  const [reachedEnd, setReachedEnd] = useState(false);
  const [minPrice, setMinPrice] = useState();
  const [maxPrice, setMaxPrice] = useState();
  const [openForConditionDropdown, setOpenForConditionDropdown] = useState(false);
  const [chosenCondition, setChosenCondition] = useState("")
  const [toggleFilterContainer, setToggleFilterContainer] = useState(false);
  const [itemsFilteredByType, setItemsFilteredByType] = useState([])
  const [noProductFoundText, setNoProductFoundText] = useState(NO_PRODUCT_FOUND_TEXT_WITHOUT_FILTERS)
  const calcSkip = () => {
    return (pageNum - 1) * size;
  }
  const determineNumColumns = () => {
    // 320px
    // 480px
    // 768px
    // 1024px
    if (windowWidth < 480) {
      setNumColumns(1)
    } else if (windowWidth < 768) {
      setNumColumns(3)
    } else if (windowWidth < 1024) {
      setNumColumns(4)
    } else {
      setNumColumns(4);
    }
    return numColumns;
  }

  //method to navigate to product detail screen of a specific product
  const handleProductPress = (product) => {
    navigation.navigate("productdetail", { productId: product._id });
  };

  //method to add to cart (redux)
  const handleAddToCart = (product) => {
    console.log("same product we need from home screen", product)
    addCartItem(product);
    toast.show("Ürün sepetinize eklendi. Sepeti görüntülemek için sağ üstteki SEPETİNİZ simgesine tıklayabilirsiniz.", { placement: "bottom", type: "success_with_close_button" })
  };

  const handleTagClick = (clickedItem) => {
    if (clickedItem.tag == "all") {
      if (foundProducts.length === 0) {
        setNoProductFoundText(NO_PRODUCT_FOUND_TEXT_WITHOUT_FILTERS);
      }
      return null;
    }
    const filteredItems = itemsFilteredByType.filter((product) => {
      return product.tags.includes(clickedItem.tag);
    });
    setFoundProducts(filteredItems);
    if (filteredItems.length === 0) {
      setNoProductFoundText(`${clickedItem.title} Bölümünde Kitap Bulunmamıştır.`);
    }
  };
  
  const fetchProducts = () => {
    setLoadMoreForInfiniteScroll(true);


    // fetch(`${network.serverip}/products?page=${pageNum}&size=2`, requestOptionsForProducts) //API call\
    // infinite scrolling: https://stackoverflow.com/a/54072093
    // dummy data for test: https://dummyjson.com/products?limit=${size}&skip=${calcSkip()}
  
    // fetch(`${network.serverip}/products?page=${pageNum}&size=2`, requestOptionsForProducts) //API call\
    // infinite scrolling: https://stackoverflow.com/a/54072093
    // dummy data for test: https://dummyjson.com/products?limit=${size}&skip=${calcSkip()}
    if (lastBatch === 0) {
      return null;
    }
  
    var myHeadersForAllProducts = new Headers();
    var requestOptionsForProducts = {
      method: "GET",
      headers: myHeadersForAllProducts,
      redirect: "follow",
    };
  
    fetch(`${network.serverip}/products?limit=${size}&skip=${calcSkip()}`, requestOptionsForProducts)
      .then((response) => response.json())
      .then((result) => {
        if (result?.products.length > 0) {
          setFoundProducts(prevState => {
            // Deduplicate by checking if the product already exists
            const newProducts = result.products.filter(product => 
              !prevState.some(existingProduct => existingProduct._id === product._id)
            );
            setItemsFilteredByType([...prevState, ...newProducts]);
            return [...prevState, ...newProducts];
          });
          setPageNum(pageNum + 1);
          setLastBatch(result.products.length);
        } else if (result.products.length === 0 && lastBatch !== 0 && lastBatch !== undefined) {
          setReachedEnd(true);
        }
      })
      .catch((error) => {
        // We are in OFFLINE mode. Show offline products and stop making 
        // requests to the server
        setFoundProducts(OFFLINE_PRODUCTS);
        setReachedEnd(true);
        // toast.show(messages.genericErrorMessage, { type: "danger_with_close_button", placement: "bottom" });
        // console.log("error", error);
      });
    setLoadMoreForInfiniteScroll(false);
  };
  

  const clearFilter = () => {
    setToggleFilterContainer(false);
    setChosenCondition("");
    setChosenCategory("");
    setMinPrice()
    setMaxPrice()
    setOpenForConditionDropdown(false);
    setOpenCategoryDropdownPicker(false);
  }

  const handleResetSearch = () => {
    setSearchItems([]);
    setToggleFilterContainer(false)
    setSearchPhrase("");
    setSearchSectionVisible(false);
    clearFilter();
  }

  //method call on pull refresh
  const handleOnRefresh = () => {
    setRefreshing(true);
    fetchProducts();
    setRefreshing(false);
  };

  // //fetch order from server using API call
  // const fetchOrders = () => {
  //   var myHeadersForOrders = new Headers();
  //   let token = getToken(user);
  //   myHeadersForOrders.append("x-auth-token", token);

  //   var requestOptionsForOrders = {
  //     method: "GET",
  //     headers: myHeadersForOrders,
  //     redirect: "follow",
  //   };
  //   setIsLoading(true);
  //   fetch(`${network.serverip}/orders`, requestOptionsForOrders)
  //     .then((response) => response.json())
  //     .then((result) => {
  //       if (result?.err === "jwt expired") {
  //         logout();
  //       }
  //       if (result.success) {
  //         setOrders(result.data);
  //       }
  //       setIsLoading(false);
  //     })
  //     .catch((error) => {
  //       setIsLoading(false);
  //       console.log("error", error);
  //     });
  // };
  //Method : Fetch category data from using API call and store for later you in code
  const fetchCategories = () => {
    var myHeaders = new Headers();
    // myHeaders.append("x-auth-token", user?.token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    setIsLoading(true);
    var payload = [];
    fetch(`${network.serverip}/categories`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          result.categories.forEach((cat) => {
            let obj = {
              label: cat,
              value: cat,
            };
            payload.push(obj);
          });
          setItemsForCategoriesDropdown(payload);
        } else {
          //   toast.show(messages.genericErrorMessage, {
          //     placement: "bottom",
          //     type: "danger_with_close_button"
          // });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        // toast.show(messages.genericErrorMessage, {
        //   placement: "bottom",
        //   type: "danger_with_close_button"
        // });
        console.log("error", error);
      });
  };
  const isMobile = () => {
    return Platform.OS == "android" || Platform.OS == "ios"
  }
  //convert user to json and fetch products in initial render
  useEffect(() => {
    setIsLoading(true);
    fetchCategories();
    fetchProducts();
    determineNumColumns();
    setIsLoading(false);
  }, [toast]);


  const renderFooter = () => {
    if (reachedEnd) {
      return null
    }
    return <ActivityIndicator animating={true} size="large" />
  };

  const NonFlickeringListItem = useCallback(({ item, index, }) => {
    return (
      <View
        key={item._id}
        style={{ marginLeft: 5, marginBottom: 10, marginRight: 5, flexBasis: "auto", display: 'flex' }}
      >
        <ProductCard
          name={item.title}
          image={`${network.serverip}/uploads/${item.filename}`}
          price={item.price}
          quantity={item.quantity}
          onPress={() => handleProductPress(item)}
          onPressSecondary={() => handleAddToCart(item)}
        />
      </View>
    );
  }, []);

  const handleClear = () => {
    setSearchItems([]);
    setSearchPhrase("");
    setSearchSectionVisible(false)
  }
  const handleSearch = async () => {
    setIsLoading(true);
    const filterAdded = chosenCategory !== "" || chosenCondition !== "" || minPrice !== undefined || maxPrice !== undefined;
  
    if (searchPhrase === "" && !filterAdded) {
      toast.show("Lütfen bir arama kelimesi girin veya filtre seçin", { placement: "bottom", type: "danger_with_close_button" });
      setIsLoading(false);
      return;
    }
  
    setSearchSectionVisible(true);
  
    var myHeadersForSearchProducts = new Headers();
    myHeadersForSearchProducts.append("x-auth-token", user?.token);
  
    var myRequestOptionsForSearchProducts = {
      method: "GET",
      headers: myHeadersForSearchProducts,
      redirect: "follow",
    };
  
    try {
      const response = await fetch(`${network.serverip}/search-product?searchWord=${searchPhrase}&minPrice=${minPrice}&maxPrice=${maxPrice}&condition=${chosenCondition}&category=${chosenCategory}`, myRequestOptionsForSearchProducts);
      if (response.ok) {
        const data = await response.json();
        setSearchItems(prevState => {
          // Ensure no duplicate products in search results
          return data.products.filter(product => !prevState.some(existingProduct => existingProduct._id === product._id));
        });
      } else {
        toast.show("Aramada bir hata oluştu. Lütfen sonra tekrar deneyiniz.", { placement: "bottom", type: "danger_with_close_button" });
        console.error('Failed to fetch data');
      }
      setIsLoading(false);
    } catch (error) {
      toast.show("Aramada bir hata oluştu. Lütfen sonra tekrar deneyiniz.", { placement: "bottom", type: "danger_with_close_button" });
      console.error('Failed to fetch data');
      setIsLoading(false);
    }
  };
  

  const handleNavigationToLandingPage = () => {
    navigation.navigate("landing");
  };

  return (
    <View style={styles.container}>
      <StatusBar backgroundColor={colors.primary}></StatusBar>
      <ProgressDialog visible={isLoading} label={"Yükleniyor..."} />
      <View style={styles.topBarContainer}>
        <TouchableOpacity onPress={(e) => handleNavigationToLandingPage()}>
          <Ionicons name="home-outline" size={30} color={colors.primary} />
        </TouchableOpacity>
        <View style={styles.topbarlogoContainer}>
          <Image source={easybuylogo} style={styles.logo} />
          <Text style={styles.topBarText}>Raf</Text>
        </View>
        <CartIconAndStatus navigation={navigation} />
      </View>
      <Text style={styles.welcomeParagraph}>Al. Sat. İkinci El Kitap.</Text>
      <View style={styles.bodyContainer}>
        <View style={styles.primaryTextContainer}>
          <Text style={styles.primaryText}>Kitap Ara</Text>
        </View>
        <View style={[styles.commonContainerBorder, styles.searchContainer]}>
          <View style={styles.inputContainer}>
            <Ionicons name="search" size={24} color="gray" style={styles.searchIcon} />
            <TextInput
              style={styles.input}
              placeholder="Kitap ismi veya yazar ismi arayın..."
              placeholderTextColor="gray"
              value={searchPhrase}
              onSubmitEditing={() => handleSearch()}
              onChangeText={(text) => setSearchPhrase(text)}
            />
          </View>
          <TouchableOpacity style={styles.searchButton} onPress={handleSearch}>
            <AntDesign name="arrowright" size={24} color="white" style={styles.searchIcon} />
          </TouchableOpacity>
          {/* {searchPhrase ?
            <TouchableOpacity style={styles.clearButton} onPress={handleClear}>
              <MaterialCommunityIcons name="close-outline" size={24} color="black" />
            </TouchableOpacity> : <></>} */}

        </View>
        <View style={styles.filterBar}>
          <View style={styles.filterButtonContainer}>
            <TouchableOpacity style={styles.filterButton} onPress={() => setToggleFilterContainer(true)}>
              <Text style={styles.filterButtonText}><FontAwesome name="filter" size={24} color="white" />Filtreler</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.filterButton} onPress={handleResetSearch}>
              <Text style={styles.filterButtonText}><FontAwesome name="repeat" size={24} color="white" /> Sıfırla</Text>
            </TouchableOpacity>
          </View>
        </View>
        {/* Filter Section*/}
        {toggleFilterContainer &&
          <View style={styles.filterContainer}>
            <View style={[styles.commonContainerBorder, styles.categoryFilterContainer]}>
              <DropDownPicker
                searchable={true}
                language="TR"
                placeholder={"Kitap Kategorisi Seçin"}
                open={openCategoryDropdownPicker}
                value={chosenCategory}
                disabled={false}
                items={itemsForCategoriesDropdown}
                setOpen={setOpenCategoryDropdownPicker}
                setValue={setChosenCategory}
                style={{ borderColor: "#fff", elevation: 5, zIndex: 999 }}
              />
            </View>
            <View style={[styles.priceInputContainer]}>
              <TextInput
                placeholder="Min ₺"
                keyboardType="number-pad"
                value={minPrice}
                style={[styles.commonContainerBorder, styles.priceInput]}
                onChangeText={(val) => {
                  setMinPrice(val.replace(/[^0-9]/g, ''))
                }}
              />
              <TextInput
                placeholder="Max ₺"
                keyboardType="number-pad"
                style={[styles.commonContainerBorder, styles.priceInput]}
                value={maxPrice}
                onChangeText={(val) => {
                  setMaxPrice(val.replace(/[^0-9]/g, ''))
                }}
              />
            </View>
            <View style={[styles.commonContainerBorder, styles.conditionFilterContainer]}>
              <DropDownPicker
                language="TR"
                placeholder={"Kitap Kondisyonu Seçin"}
                open={openForConditionDropdown}
                value={chosenCondition}
                items={itemsForConditionDropdown}
                setOpen={setOpenForConditionDropdown}
                setValue={setChosenCondition}
                disabledStyle={{
                  backgroundColor: colors.light,
                  borderColor: colors.white,
                }}
                style={{ borderColor: "#fff", elevation: 5, zIndex: 999 }}
              />
            </View>
          </View>
        }
        {/* <View style={styles.typeContainer}>
          <FlatList
            showsHorizontalScrollIndicator={false}
            contentContainerStyle={styles.flatListTypeContainer}
            horizontal={true}
            data={type}
            keyExtractor={(item, index) => `${item}-${index}`}
            renderItem={({ item, index }) => (
              <View style={{ marginBottom: 10 }} key={index}>

              </View>
            )}
          />
          <View style={styles.emptyView}></View>
        </View> */}

        {/* Search Results START */}
        {searchSectionVisible &&
          (searchItems.length === 0 ? (
            <View style={styles.productCardContainerEmpty}>
              <Text style={styles.productCardContainerEmptyText}>Aramadan Kitap Bulunmamıştır</Text>
            </View>
          ) : (
            <View style={styles.productCardContainerFound}>
              <Text style={styles.productCardContainerFoundText}>Aramadan {searchItems.length} Kitap Bulunmuştur.</Text>
              <View style={styles.searchResultsContainer}>
                <FlatList
                  refreshControl={
                    <RefreshControl
                      refreshing={refreshing}
                      onRefresh={handleOnRefresh}
                    />
                  }
                  showsHorizontalScrollIndicator={false}
                  initialNumToRender={5}
                  horizontal={false}
                  numColumns={numColumns}
                  data={searchItems}
                  keyExtractor={(item) => `${item._id}`}
                  renderItem={({ item, index }) => (
                    <ProductCard
                      name={item.title}
                      image={`${network.serverip}/uploads/${item.filename}`}
                      price={item.price}
                      onPress={() => handleProductPress(item)}
                      onPressSecondary={() => handleAddToCart(item)}
                    />
                  )}
                  refreshing={refreshing}
                />
              </View>
            </View>))}
        {/* Search Results END */}

        {/* All Books Available in Platform START */}
        < View style={[styles.titleAndTypeContainer, styles.primaryTextContainer]}>
          <View style={(isMobile() || windowWidth < 480) ? styles.titleForAllBooksMobile : styles.titleForAllBooksWeb}>
            <Text style={styles.primaryText}>Bütün Kitaplar</Text>
          </View>
          {/* <View style={styles.typeContainer}> */}
          <FlatList
            showsHorizontalScrollIndicator={false}
            // contentContainerStyle={styles.flatListTypeContainer}
            contentContainerStyle={{ borderRadius: 10, backgroundColor: colors.light, borderWidth: 3, borderColor: 'white', justifyContent: 'center' }}
            horizontal={true}
            data={types}
            keyExtractor={(item, index) => `${item}-${index}`}
            renderItem={({ item, index }) => (
              <CustomIconButton
                key={index}
                text={item.title}
                image={item.image}
                onPress={(type) => {
                  handleTagClick(item)
                }}
              />
            )}
          />
          {/* </View> */}

          {/* <View style={styles.typeContainer}>
            {types.map((type, index) => {
              return (
                <CustomIconButton
                  key={index}
                  text={type.title}
                  image={type.image}
                  onPress={(item) => {
                    handleTagClick(type)
                  }}
                />)
            })}
          </View> */}
        </View>
        {foundProducts.length === 0 ? (
          <View style={styles.productCardContainerEmpty}>
            <Text style={styles.productCardContainerEmptyText}>
              {noProductFoundText}
            </Text>
          </View>
        ) : (
          <View style={styles.productCardContainer}>
            <FlatList
              refreshControl={
                <RefreshControl
                  refreshing={refreshing}
                  onRefresh={handleOnRefresh}
                />
              }
              showsHorizontalScrollIndicator={false}
              initialNumToRender={10}
              numColumns={numColumns}
              horizontal={false}
              data={foundProducts}
              keyExtractor={(item) => `${item._id}`}
              renderItem={({ item, index }) => (
                NonFlickeringListItem({ item: item, index: index })
              )}
              onEndReached={fetchProducts}
              onEndReachedThreshold={0.1}
              ListFooterComponent={renderFooter}
              refreshing={loadMoreForInfiniteScroll}
            />
            {reachedEnd ? <View><Text style={{ opacity: 0.5 }}>Sona Ulaşıldı</Text></View> : <View><Text style={{ opacity: 0.5 }}>Daha Fazla Kitap İçin Aşağı kaydırmaya Devam Et</Text></View>}
            {/* <View style={styles.emptyView}></View> */}
          </View>
        )}
        {/* All Books Available in Platform END */}
        {/* My purchases START */}
        {/* <View style={styles.primaryTextContainer}>
          <Text style={styles.primaryText}>Sipariş Ettiğim Kitaplar</Text>
        </View>
        {orders.length == 0 ? (
          <>
          <View style={styles.emptyViewForOrders}></View>
          <View style={styles.ListContainerEmpty}>
            <Text style={styles.secondaryTextSmItalic}>
              Hiçbir kitap satın almadınız.
            </Text>
          </View>
          <View style={styles.emptyViewForOrders}></View>
          </>
        ) : (
          <ScrollView
            style={{ flex: 1, width: "100%", padding: 20 }}
            showsVerticalScrollIndicator={false}
            refreshControl={
              <RefreshControl
                refreshing={refreshing}
                onRefresh={handleOnRefresh}
              />
            }
          >
            {orders.map((order, index) => {
              return (
                <OrderList
                  item={order}
                  key={index}
                  onPress={() => handleOrderDetail(order)}
                />
              )
            })}
            <View style={styles.emptyView}></View>
          </ScrollView>
        )} */}
        {/* My purchases END */}
      </View>
    </View >
  )
}

export default HomeScreen;

const styles = StyleSheet.create({
  titleForAllBooksMobile: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  titleForAllBooksWeb: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    flex: 1
  },
  titleAndTypeContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  priceInput: {
    paddingHorizontal: 3,
    justifyContent: "center",
    flex: 3
  },
  filterBar: {
    flexDirection: 'row',
    display: "flex",
    width: "70%"
  },
  filterButton: {
    fontSize: 16,
    color: 'white',
    margin: 5,
    borderRadius: 4,
    padding: 5,
    backgroundColor: "rgb(81, 84, 96)"
  },
  filterButtonText: {
    color: 'white',
  },
  priceInputContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginRight: 4,
  },
  filterButtonContainer: {
    justifyContent: "flex-end",
    alignItems: "center",
    display: "flex",
    width: "100%",
    flexDirection: "row",
    padding: 5,
    zIndex: 999,
    borderRadius: 5
  },
  categoryFilterContainer: {
    flex: 2,
    zIndex: 999,
    marginRight: 4,
  },
  commonContainerBorder: {
    borderWidth: 3,
    borderColor: "black",
    borderRadius: 5,
  },
  filterContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginHorizontal: 7,
    width: "70%"
  },
  priceFilterContainer: {
    flex: 3,
    flexDirection: "column",
    alignItems: "center",
    borderRadius: 5,
    backgroundColor: "white",

  },
  conditionFilterContainer: {
    flex: 2,
    backgroundColor: "white",
    zIndex: 999,
  },
  productCardContainerFoundText: {
    fontSize: 15,
    fontStyle: "italic",
    color: colors.muted,
    fontWeight: "600",
  },
  productCardContainerFound: {
    padding: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: 240,
    paddingTop: 0,
    zIndex: -99
  },
  welcomeParagraph: {
    fontSize: 15,
    fontWeight: "500",
    color: colors.primary,
  },
  container: {
    width: "100%",
    flexDirecion: "row",
    backgroundColor: colors.light,
    alignItems: "center",
    justifyContent: "flex-start",
    paddingBottom: 0,
    flex: 1,
  },
  sTextItem: {
    height: 50,
    width: "100%",
    textAlign: "center",
    textAlignVertical: "center",
    fontSize: 18
  },
  topBarContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 20,
  },
  topBarText: {
    fontSize: 15,
    fontWeight: "600",
  },
  topbarlogoContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 20,
  },
  searchResultsContainer: {
    flex: 1,
    paddingLeft: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "80%",
    flexGrow: 1,
    // height: 240,
    marginLeft: 10,
    paddingTop: 0,
  },
  logoContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  emptyViewForOrders: {
    height: 30,
    width: "%100"
  },
  logo: {
    height: 30,
    width: 30,
    resizeMode: "contain",
  },
  secondaryText: {
    fontSize: 25,
    fontWeight: "bold",
  },
  searchContainer: {
    justifyContent: "space-between",
    flexDirection: 'row',
    alignItems: 'space-between',
    backgroundColor: '#fff',
    width: "70%",
    // marginHorizontal: 10,
    // paddingHorizontal: 10,
  },
  inputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  input: {
    flex: 1,
    height: 40,
  },
  searchButton: {
    backgroundColor: colors.primary,
    borderRadius: 5,
    padding: 10,
    // width: "5%",
    justifyContent: "center",
    alignItems: "center",
  },
  clearButton: {
    backgroundColor: colors.blue,
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
    minWidth: 32
  },
  buttonContainer: {
    width: "20%",
    justifyContent: "center",
    alignItems: "center",
  },
  scanButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.primary,
    borderRadius: 10,
    height: 40,
    width: "100%",
  },
  scanButtonText: {
    fontSize: 15,
    color: colors.light,
    fontWeight: "bold",
  },
  primaryTextContainer: {
    padding: 20,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    paddingTop: 10,
    paddingBottom: 10,
    zIndex: -999
  },
  primaryText: {
    fontSize: 20,
    fontWeight: "bold",
  },
  flatListTypeContainer: {
    width: "100%",
    height: 50,
    justifyContent: "center",
    marginTop: 10,
    zIndex: -999
    // marginLeft: 10,
  },
  promotiosSliderContainer: {
    margin: 5,
    height: 140,
    backgroundColor: colors.light,
    zIndex: -1
  },
  // typeContainer: {
  //   flex: 1,
  //   display: "flex",
  //   flexDirection: "row",
  //   justifyContent: "flex-start",
  //   zIndex: -999,
  //   flexWrap: "wrap"
  // },
  emptyView: { width: 30 },
  productCardContainer: {
    flex: 1,
    paddingLeft: 10,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "80%",
    flexGrow: 1,
    height: 240,
    marginLeft: 10,
    paddingTop: 0,
    zIndex: -999
  },
  productCardContainerEmpty: {
    padding: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: 240,
    marginLeft: 10,
    paddingTop: 0,
    zIndex: -99
  },
  productCardContainerEmptyText: {
    fontSize: 15,
    fontStyle: "italic",
    color: colors.muted,
    fontWeight: "600",
  },
  cartItemCountContainer: {
    position: "absolute",
    zIndex: 10,
    top: -10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 22,
    width: 22,
    backgroundColor: colors.danger,
    borderRadius: 11,
  },
  screenNameContainer: {
    padding: 20,
    paddingTop: 0,
    paddingBottom: 0,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  screenNameText: {
    fontSize: 30,
    fontWeight: "800",
    color: colors.muted,
  },
  screenNameParagraph: {
    marginTop: 5,
    fontSize: 15,
  },
  bodyContainer: {
    width: "100%",
    flexDirecion: "row",
    backgroundColor: colors.light,
    alignItems: "center",
    justifyContent: "flex-start",
    flex: 1,
  },
  emptyView: {
    height: 20,
  },
  ListContainerEmpty: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    marginBottom: 20
  },
  secondaryTextSmItalic: {
    fontStyle: "italic",
    fontSize: 15,
    color: colors.muted,
  },
});
