import {
    StyleSheet,
    Text,
    View,
    StatusBar,
    TouchableOpacity,
    Alert,
    WebView,
    ScrollView
} from "react-native";
import React, { useState } from "react";
import UserProfileCard from "../../components/UserProfileCard/UserProfileCard";
import { Ionicons } from "@expo/vector-icons";
import { MaterialIcons } from "@expo/vector-icons";
import { network, colors } from "../../constants";

const DistanceSellingContractScreen = ({ navigation, route }) => {
    const [showBox, setShowBox] = useState(true);
    const [error, setError] = useState("");

    return (
        <View style={styles.container}>
            <StatusBar backgroundColor={colors.primary}></StatusBar>
            <View style={styles.TopBarContainer}>
                <TouchableOpacity
                    onPress={() => {
                        navigation.goBack(null);
                    }}
                >
                    <Ionicons
                        name="arrow-back-circle-outline"
                        size={30}
                        color={colors.muted}
                    />
                </TouchableOpacity>
            </View>
            <View style={styles.screenNameContainer}>
                <Text style={styles.screenNameText}>Mesafeli Satış Sözleşmesi</Text>
            </View>
            <View style={styles.OptionsContainer}>
                <ScrollView>
                    <View style={styles.primaryTextContainer}>
                        <Text style={styles.primaryText}>Mesafeli Satış Sözleşmesi</Text>
                    </View>
                    <Text>
                        {`
            MADDE 1 – SÖZLEŞMENİN KONUSU VE TARAFLAR
            1.1. İşbu sözleşme, aşağıda detay bilgileri bulunan ALICI’nın, SATICI tarafından işletilmekte olan WebSitesi; (bundan sonra Raf olarak anılacaktır) Raf üzerinden yapmış olduğu ürün ve hizmetlerin satışı ve ürünlerin teslimat adresine gönderimi ile ilgili olarak 6502 sayılı Tüketicilerin Korunması Hakkındaki Kanun ve Mesafeli Sözleşmeler Uygulama Esas ve Usulleri Hakkında Yönetmelik hükümleri gereğince tarafların hak, hukuk ve yükümlülüklerini tespit eder.

            1.2. ALICI, satışa konu mal veya hizmetlerin temel nitelikleri, satış fiyatı, ödeme şekli, teslimat koşulları ve satışa konu mal veya hizmetler ile ilgili tüm ön bilgiler ve “cayma” hakkı konusunda bilgi sahibi olduğunu, bu ön bilgileri elektronik ortamda teyit ettiğini ve sonrasında mal veya hizmetleri sipariş verdiğini iş bu sözleşme hükümlerince kabul ve beyan eder. Raf sitesinde ödeme sayfasında yer alan ön bilgilendirme ve fatura iş bu sözleşmenin ayrılmaz parçalarıdır.

            1.3. SATICI BİLGİLERİ
            Raftan Kitap
            www.raftankitap.com
            Adresi : 853 Sokak Ozyurtsever Ishani, 13/13B Konak/Izmir 35250
            Eposta : destek@raftankitap.com

            MADDE 2 – SÖZLEŞMENİN TARİHİ

            2.1. İşbu sözleşme ile ALICI’nın Raf üzerinde siparişinin tamamlandığı tarihte taraflarca anlaşma gerçekleştirilmiş sayılır.

            MADDE 3 – SÖZLEŞME KONUSU ÜRÜN VE HİZMETLER

            3.1. ALICI tarafından siparişi gerçekleştirilen ürün ve hizmetlerin detayları, vergiler dahil peşin satış tutarları ve adet bilgileri ödeme öncesi belirtilmektedir. Ödeme öncesi tabloda belirtilen ürünlerin tümü bundan sonra ÜRÜN olarak tanımlanmaktadır.

            MADDE 4 – ÜRÜNLERİN TESLİMATI

            4.1. ÜRÜN, ALICI’nın İNTERNET SİTESİ üzerinde belirttiği teslimat adresine veya gösterdiği adresteki kişi/kuruluşa, faturası ile birlikte paketlenmiş ve sağlam olarak en geç 10 gün içinde teslim edilir, zorunluluk arz eden hallerde bu süre uzayabilir.

            4.2. ÜRÜN, ALICI’dan başka bir kişi/kuruluşa teslim edilecek ise, teslim edilecek kişi/kuruluşun teslimatı kabul etmemesinden SATICI sorumlu tutulamaz.

            4.3. ALICI, ÜRÜN’ü teslim aldığı anda kontrol etmekle ve ÜRÜN’de kargodan kaynaklanan bir sorun gördüğünde, ÜRÜN’ü kabul etmemekle ve KARGO firması yetkilisine tutanak tutturmakla sorumludur. Aksi halde SATICI sorumluluk kabul etmeyecektir.

            MADDE 5 – ÖDEME ŞEKLİ

            5.1. ALICI, vadeli satışların sadece Bankalara ait kredi kartları ile yapılması nedeniyle, alıcı,ilgili faiz oranlarını ve temerrüt faizi ile ilgili bilgileri bankasından ayrıca teyit edeceğini, yürürlükte bulunan mevzuat hükümleri gereğince faiz ve temerrüt faizi ile ilgili hükümlerin Banka ve alıcı arasındaki kredi kartı sözleşmesi kapsamında uygulanacağını kabul, beyan ve taahhüt eder. Bankalar ve finansman kuruluşları gibi kredi kartı, taksit kart v.b. veren kuruluşlarca sağlanan vadeli / taksitli ödeme imkânları bir kredi ve/veya doğrudan anılan kuruluşça sağlanmış taksitli ödeme imkânıdır; bu çerçevede gerçekleşen ve SATICI’nın bedelini tamamen tahsil ettiği ÜRÜN satışları işbu Sözleşme’nin tarafları yönünden taksitli satış sayılmaz, peşin satıştır. SATICI’nın kanunen taksitle satış sayılan hallerdeki yasal hakları(taksitlerden herhangi birinin ödenmemesi halinde sözleşmeyi fesih ve/veya kalan borcun tümünün temerrüt faizi ile birlikte ödenmesini talep hakları dâhil) mevcut ve saklıdır.ALICI’nın temerrüdü durumunda aylık % 5 oranında temerrüt faizi tatbik edilir.

            MADDE 6 – GENEL HÜKÜMLER

            6.1. ALICI, İNTERNET SİTESİ’nde gösterilen ürünlerin temel nitelikleri, satış fiyatı ve ödeme şekli ile teslimata ilişkin ön bilgileri okuyup bilgi sahibi olduğunu ve elektronik ortamda satış için gerekli teyidi verdiğini kabul eder.

            6.2. ALICI; bu sözleşmeyi elektronik ortamda teyit etmekle, mesafeli sözleşmelerin akdinden önce, Satıcı tarafından Tüketici’ye verilmesi gereken adres, siparişi verilen ürünlere ait temel özellikler, ürünlerin vergiler dahil fiyatı, ödeme ve teslimat bilgilerini de doğru ve eksiksiz olarak edindiğini teyit etmiş olur.

            6.3. SATICI, sözleşme konusu ürünün sağlam, eksiksiz, siparişte belirtilen niteliklere uygun teslim edilmesinden sorumludur.

            6.5. SATICI, sipariş konusu ürün veya hizmetin yerine getirilmesinin imkânsızlaşması halinde sözleşme konusu yükümlülüklerini yerine getiremezse, bu durumu, sözleşmeden doğan ifa yükümlülüğünün süresi dolmadan tüketiciye bildirir ve ALICI’ya yapmış olduğu ödemeyi iade eder.

            6.6. Sözleşme konusu ürünün teslimatı için işbu sözleşmenin imzalanması ve bedelinin ALICI’nın tercih ettiği ödeme şekli ile ödenmiş olması şarttır. Herhangi bir nedenle ürün bedeli ödenmez veya banka kayıtlarında iptal edilir ise, SATICI ürünün teslimi yükümlülüğünden kurtulmuş kabul edilir.

            6.7. ÜRÜN teslimatı sonrasında herhangi bir sebepten dolayı, işlem yapılan kredi kartının ait olduğu Banka/finansman kurumunun ÜRÜN bedelini SATICI’ya ödememesi halinde, SATICI’nın ÜRÜN bedeli alacağını takip dahil diğer tüm akdi- kanuni hakları saklıdır.

            6.8. ÜRÜN’ün normal satış koşulları dışında olağanüstü durumlar (hava muhalefeti, deprem, sel, yangın gibi) nedeni ile 30 günlük süre zarfında teslim edilememesi ve gecikmenin 30 günü aşması söz konusu ise, SATICI teslimat ile ilgili olarak ALICI’yı bilgilendirir. Bu durumda ALICI siparişi iptal edebilir, benzer bir ürün sipariş edebilir veya olağanüstü durum sonuna kadar bekleyebilir. Sipariş iptallerinde ÜRÜN bedeli tahsil edilmiş ise iptalden itibaren 30 gün içinde ALICI’ya iade edilir. Kredi kartlı ödemelerde iade işlemi de ALICI kredi kartına iade sureti ile yapılır.

            MADDE 7 – CAYMA HAKKI

            7.1. ALICI; mal satışına ilişkin sözleşmelerde, ürünün kendisine veya gösterdiği adresteki kişi / kuruluşa teslim tarihinden itibaren 15 (onbeş) gün içerisinde hiçbir gerekçe göstermeksizin mali reddederek sözleşmeden cayma hakkını kullanabilir. Hizmet sunumuna ilişkin mesafeli sözleşmelerde ise, bu süre sözleşmenin imzalandığı tarihten itibaren başlar. Cayma hakki süresi sona ermeden önce, tüketicinin onayı ile hizmetin ifasına başlanan hizmet sözleşmelerinde cayma hakkı kullanılamaz. Cayma hakkının kullanımından kaynaklanan masraflar SATICI’ ya aittir.

            7.2. Cayma hakkının kullanılması için 15 (on beş) günlük süre içinde SATICI”ya iadeli taahhütlü posta, faks veya e-posta ile yazılı bildirimde bulunulması ve ürünün 8. madde başlığı altında düzenlenen “Cayma Hakkı Kullanılamayacak Ürünler” hükümleri çerçevesinde kullanılmamış olması şarttır. Bu hakkın kullanılması halinde;

            a) 3. kişiye veya ALICI’ya teslim edilen ürünün faturası,(İade edilmek istenen ürünün faturası kurumsal ise, iade faturası ile birlikte gönderilmesi gerekmektedir. Faturası kurumlar adına düzenlenen sipariş iadeleri İADE FATURASI kesilmediği takdirde tamamlanamayacaktır.)

            b) İade formu,

            c) İade edilecek ürünlerin kutusu, ambalajı, varsa standart aksesuarları ile birlikte eksiksiz ve hasarsız olarak teslim edilmesi gerekmektedir.

            d) SATICI, cayma bildiriminin kendisine ulaşmasından itibaren en geç 10 günlük süre içerisinde toplam bedeli ve ALICI”yı borç altına sokan belgeleri ALICI’ya iade etmek ve 20 günlük süre içerisinde mali iade almakla yükümlüdür.

            e) ALICI’nın kusurundan kaynaklanan bir nedenle malın değerinde bir azalma olursa veya iade imkansızlaşırsa ALICI kusuru oranında SATICI’nın zararlarını tazmin etmekle yükümlüdür.

            f) Cayma hakkının kullanılması nedeniyle SATICI tarafından düzenlenen kampanya limit tutarının altına düşülmesi halinde kampanya kapsamında faydalanılan indirim miktarı iptal edilir.

            MADDE 8 – CAYMA HAKKI KULLANILAMAYACAK ÜRÜNLER

            8.1. ALICI’nın istekleri veya açıkça onun kişisel ihtiyaçları doğrultusunda hazırlanan, niteliği itibarıyla geri gönderilmeye elverişli olmayan, iç giyim alt parçaları, mayo ve bikini altları, makyaj malzemeleri,tek kullanımlık ürünler,çabuk bozulma tehlikesi olan veya son kullanma tarihi geçme ihtimali olan mallar ile ses veya görüntü kayıtlarının, yazılım programlarının ve bilgisayar sarf malzemelerinin ambalajının ALICI tarafından açılmış olması halinde iadesi Yönetmelik gereği mümkün değildir. Kozmetik ve kişisel bakim ürünleri, iç giyim ürünleri, mayo, bikini, kitap, kopyalanabilir yazılım ve programlar, DVD, VCD, CD ve kasetler ile kırtasiye sarf malzemeleri (toner, kartuş, şerit vb.) ambalajı açılmamış, denenmemiş, bozulmamış ve kullanılmamış olmaları halinde iade edilebilir.

            MADDE 9 – DELİL ANLAŞMASI VE YETKİLİ MAHKEME

            9.1. Bu Sözleşme’den ve/veya uygulanmasından doğabilecek her türlü uyuşmazlığın çözümünde SATICI kayıtları (bilgisayar-ses kayıtları gibi manyetik ortamdaki kayıtlar dâhil) kesin delil oluşturur; Sanayi ve Ticaret Bakanlığınca ilan edilen değere kadar Tüketici Hakem Heyetleri, aşan durumlarda ALICI’nın ve SATICI’nın yerleşim yerindeki Tüketici Mahkemeleri ve İcra Müdürlükleri yetkilidir.

            9.2. ALICI bu sözleşmede ve ayrılmaz parçasını oluşturan sipariş formunda yazılı tüm koşulları ve açıklamaları okuduğunu, satış koşullarının ve sair tüm ön bilgileri aldığını, incelediğini ve tamamını kabul ettiğini beyan, kabul ve taahhüt eder.
            Güncellenme Tarihi: 08.10.2024
            `}
                </Text>
                </ScrollView>
            </View>
        </View>
    );
};

export default DistanceSellingContractScreen;

const styles = StyleSheet.create({
    container: {
        width: "100%",
        flexDirecion: "row",
        backgroundColor: colors.light,
        alignItems: "center",
        justifyContent: "flex-start",
        padding: 20,
        flex: 1,
    },
    TopBarContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    UserProfileCardContianer: {
        width: "100%",
        height: "25%",
    },
    primaryTextContainer: {
        padding: 20,
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        width: "100%",
        paddingTop: 10,
        paddingBottom: 10,
    },
    primaryText: {
        fontSize: 20,
        fontWeight: "bold",
    },
    screenNameContainer: {
        marginTop: 10,
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        marginBottom: 10,
    },
    screenNameText: {
        fontSize: 30,
        fontWeight: "800",
        color: colors.muted,
    },
    OptionsContainer: {
        width: "100%",
        flex: 1,
        justifyContent: 'center'
    },
});
