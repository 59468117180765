import { createStore, applyMiddleware } from "redux";
import reducers from "./reducers/index";
import thunk from "redux-thunk";

export const store = createStore(reducers, {}, applyMiddleware(thunk));


// store.js
import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import AsyncStorage from '@react-native-async-storage/async-storage'

const useCheckoutStore = create(
  persist(
    (set) => ({
      buyerFirstName: '',
      buyerLastName: '',
      email: '',
      phone: '',
      streetAddress: '',
      district: '',
      city: '',
      postalCode: '',
      cardNumber: '',
      expiryMonth: '',
      expiryYear: '',
      cvv: '',
      setFormData: (field, value) =>
        set((state) => ({ ...state, [field]: value })),
    }),
    {
      name: 'checkout-form', // key for AsyncStorage
      getStorage: () => AsyncStorage, // persisting in AsyncStorage
    }
  )
)

const useAuthStore = create(
    persist(
      (set) => ({
        isAuthenticated: false,
        isAdmin: false,
        user: null,
        login: (userData) => 
          set({ isAuthenticated: true, isAdmin: userData.isAdmin, user: userData }),
        logout: () => 
          set({ isAuthenticated: false, isAdmin: false, user: null }),
      }),
      {
        name: "auth-store", // Key for storage
        getStorage: () => AsyncStorage, // Persist in AsyncStorage
      }
    )
  );
  
export {useAuthStore, useCheckoutStore};

