import {
  StyleSheet,
  Text,
  View,
  StatusBar,
  TouchableOpacity,
  Alert,
  WebView,
  ScrollView
} from "react-native";
import React, { useState } from "react";
import UserProfileCard from "../../components/UserProfileCard/UserProfileCard";
import { Ionicons } from "@expo/vector-icons";
import { MaterialIcons } from "@expo/vector-icons";
import { network, colors } from "../../constants";

const Corporate = ({ navigation, route }) => {
  const [showBox, setShowBox] = useState(true);
  const [error, setError] = useState("");

  return (
    <View style={styles.container}>
      <StatusBar backgroundColor={colors.primary}></StatusBar>
      <View style={styles.TopBarContainer}>
        <TouchableOpacity
          onPress={() => {
            navigation.navigate("tabs", {screen: "profile"});
          }}
        >
          <Ionicons
            name="arrow-back-circle-outline"
            size={30}
            color={colors.muted}
          />
        </TouchableOpacity>
      </View>
      <View style={styles.screenNameContainer}>
        <Text style={styles.screenNameText}>Önemli Bilgiler</Text>
      </View>
      <View style={styles.OptionsContainer}>
        <ScrollView>
        <View style={styles.primaryTextContainer}>
          <Text style={styles.primaryText}>Kullanım Koşulları Anlaşması</Text>
        </View>
          <Text>
            {`
            RAF'a hoş geldiniz! İkinci el kitapları satın almak veya satmak için platformumuzu kullanarak, aşağıdaki koşullara uymayı kabul edersiniz:

            1. Hesap Kaydı:
            - Kayıt sırasında doğru ve eksiksiz bilgi sağlamalısınız.
            - Hesap kimlik bilgilerinizin gizliliğini koruma sorumluluğundasınız.

            2. Liste ve Satış:
            - Tüm kitap listeleri kitabın durumunu ve detaylarını doğru şekilde yansıtmalıdır.
            - Doğru fiyatlar belirlemek ve dürüst açıklamalar sağlamak sizin sorumluluğunuzdadır.

            3. Satın Alma ve Ödemeler:
            - Alıcılar, işlem sırasında anlaşıldığı şekilde ödemeleri tamamlamalıdır.
            - [Şirket Adınız], satılan kitapların kalitesinden sorumlu değildir. Alıcılar, listeleri gözden geçirmeli ve satıcılarla iletişime geçmelidir.

            4. Gizlilik:
            - Verilerinizi Gizlilik Politikamızda belirtildiği şekilde toplar ve kullanırız.
            - Kişisel bilgilerinizi onayınız olmadan üçüncü taraflarla paylaşmayız.

            5. Yasaklı İçerik:
            - Platformumuzda sahte, yasa dışı veya uygun olmayan kitaplar listelenemez.
            - Kılavuzlarımıza aykırı olan listeleri kaldırma hakkını saklı tutarız.

            6. Anlaşmazlıklar:
            - Alıcılar ve satıcılar anlaşmazlıkları dostane bir şekilde çözmeye teşvik edilir.
            - [Şirket Adınız], kullanıcılar arasındaki anlaşmazlıklardan sorumlu değildir.

            7. Fikri Mülkiyet:
            - Kullanıcılar, fikri mülkiyet haklarının sahibi olarak kalır.
            - Platformumuzu kullanarak, içeriğinizi görüntülemek için [Şirket Adınız]'a devredilemez bir lisans verirsiniz.

            8. Sorumluluk Sınırlaması:
            - [Şirket Adınız], platformumuzun kullanımından kaynaklanan herhangi bir zarardan sorumlu değildir.
            - Listelerin doğruluğunu, erişilebilirliğini veya kalitesini garanti etmiyoruz.

            9. Koşulların Değiştirilmesi:
            - Bu koşulları zaman zaman güncelleyebiliriz. Kullanıcılar değişikliklerden haberdar edilecektir.

            10. Uygulanabilir Hukuk:
            - Bu koşullar [Yargı Bölgeniz] yasalarına tabidir.

            Lütfen bu koşulları dikkatlice gözden geçirin. Platformumuzu kullanarak, bu koşulları okuduğunuzu ve anladığınızı kabul edersiniz.
            `}
          </Text>
          <View style={styles.primaryTextContainer}>
            <Text style={styles.primaryText}>Hakkımızda</Text>
          </View>
          <Text>
            {`
            Platformumuz, kitap tutkunlarını bir araya getirme misyonunu taşıyor ve ikinci el kitapları satın almak ve satmak isteyenlere anlamlı bir alan sunmayı amaçlıyor. İşte kim olduğumuz hakkında daha fazla bilgi:

            Misyonumuz:
            RAF ekibi olarak, sürdürülebilir okuma alışkanlıklarını teşvik etmeye ve her kitabın bir hikaye anlattığına inanıyoruz. Okuyucuları ve satıcıları anlamlı bir şekilde bir araya getirmek için buradayız.

            Değerlerimiz:
            - Topluluk: Okuma tutkusunu paylaşan kitap severlerin canlı bir topluluğunu oluşturmayı amaçlıyoruz.
            - Sürdürülebilirlik: Ön sevgili kitaplara yeni evler bulunmasıyla atıkları ve çevresel etkiyi azaltmaya katkıda bulunuyoruz.
            - Güven: Alıcılar ve satıcılar arasındaki tüm etkileşimlerde şeffaflığı ve dürüstlüğü önceliğimiz olarak kabul ediyoruz.

            Neden Bizi Seçmelisiniz:
            - Geniş Seçenek: Farklı türlerdeki ikinci el kitapları keşfetmek için geniş bir yelpazeye sahibiz.
            - Kolay İşlemler: Kullanıcı dostu platformumuz, kitapları satışa çıkarmayı ve satın almada kolaylaştırır.
            - Güvenli Ödemeler: İşlemlerinizi korumak için güvenli ödeme işlemi sağlıyoruz.

            `}
          </Text>
          <View style={styles.primaryTextContainer}>
            <Text style={styles.primaryText}>Yardım Merkezi</Text>
          </View>
          <Text>
            {`
          Bizimle İletişime Geçin:
          Sizden haber almak istiyoruz! Sorularınız, geri bildirimleriniz veya sadece merhaba demek için bize ulaşmaktan çekinmeyin. Sosyal medyada bizi takip ederek güncellemeleri, kitap önerilerini ve daha fazlasını bulabilirsiniz.
          - Emailimiz: destek@raftankitap.com 
            `}
          </Text>
          </ScrollView>
      </View>
    </View>
  );
};

export default Corporate;

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexDirecion: "row",
    backgroundColor: colors.light,
    alignItems: "center",
    justifyContent: "flex-start",
    padding: 20,
    flex: 1,
  },
  TopBarContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  UserProfileCardContianer: {
    width: "100%",
    height: "25%",
  },
  primaryTextContainer: {
    padding: 20,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "100%",
    paddingTop: 10,
    paddingBottom: 10,
  },
  primaryText: {
    fontSize: 20,
    fontWeight: "bold",
  },
  screenNameContainer: {
    marginTop: 10,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: 10,
  },
  screenNameText: {
    fontSize: 30,
    fontWeight: "800",
    color: colors.muted,
  },
  OptionsContainer: {
    width: "100%",
    flex: 1,
    justifyContent:'center'
  },
});
