import {
    StyleSheet,
    Text,
    View,
    StatusBar,
    TouchableOpacity,
    Alert,
    WebView,
    ScrollView
} from "react-native";
import React, { useState } from "react";
import UserProfileCard from "../../components/UserProfileCard/UserProfileCard";
import { Ionicons } from "@expo/vector-icons";
import { MaterialIcons } from "@expo/vector-icons";
import { network, colors } from "../../constants";

const PrivacyScreen = ({ navigation, route }) => {
    const [showBox, setShowBox] = useState(true);
    const [error, setError] = useState("");

    return (
        <View style={styles.container}>
            <StatusBar backgroundColor={colors.primary}></StatusBar>
            <View style={styles.TopBarContainer}>
                <TouchableOpacity
                    onPress={() => {
                        navigation.goBack(null);
                    }}
                >
                    <Ionicons
                        name="arrow-back-circle-outline"
                        size={30}
                        color={colors.muted}
                    />
                </TouchableOpacity>
            </View>
            <View style={styles.screenNameContainer}>
                <Text style={styles.screenNameText}>Gizlilik Sözleşmesi</Text>
            </View>
            <View style={styles.OptionsContainer}>
                <ScrollView>
                    <View style={styles.primaryTextContainer}>
                        <Text style={styles.primaryText}>Gizlilik Sözleşmesi</Text>
                    </View>
                    <Text>
                        {`
            Raf Satış Ticaret A.Ş. ("Şirket") olarak ticari faaliyetlerimizi yürüttüğümüz www.raftankitap.com web sitesi ve/veya mobil uygulaması ("Raf Platformu”) vasıtasıyla işlediğimiz kişisel verilere yönelik 6698 sayılı Kişisel Verilerin Korunması Kanunu’nun ("KVKK”) 10. maddesinden doğan aydınlatma yükümlülüğümüzü yerine getirmek amacıyla sizleri bilgilendirmek isteriz. Kişisel veri, kimliğinizi belirli ya da belirlenebilir kılan her türlü bilgi anlamına gelmektedir. Raf tarafından tarafından işlenen kişisel verileriniz, bunların işlenme amaçları, aktarılabileceği alıcı grupları, toplanma yöntemi, hukuki sebebi ve söz konusu kişisel verilere ilişkin haklarınız aşağıda yer almaktadır.
            `}
                    </Text>
                    <View style={styles.primaryTextContainer}>
                        <Text style={styles.primaryText}>A- RAF PLATFORMUNUN “ALICI” OLARAK KULLANILMASI HALİNDE İŞLENEN KİŞİSEL VERİLERİNİZ</Text>
                    </View>
                    <Text>
                        {`
             Raf tarafından hangi kişisel verileriniz işlenmektedir?
            Raf Platformunda yer alan Üyelik Koşullarını kabul ederek web sitesinden veya mobil uygulamadan üye olan ve Raf Platformunda yer alan ürünleri takip eden ve/veya satın alan kişiler “Alıcı” olarak tanımlanmaktadır. Raf Platformunda alıcı olmanız halinde aşağıdaki kişisel verileriniz işlenmektedir.
            
             Kimlik Bilgileriniz: TC kimlik numaranız, adınız, soyadınız, kullanıcı adınız
             İletişim Bilgileriniz: cep telefonu numaranız, üyelik hesabınıza eklediğiniz adres bilgileriniz, e-posta adresiniz,
             Müşteri İşlem Bilgileriniz: sipariş, ödeme ve fatura bilgileriniz, banka kartı veya kredi kartı bilgileriniz, teslimat işlem bilgileriniz, alışverişlerinizle ilgili gerçekleşen işlem geçmişi bilgisi, talep ve şikayet bilgileriniz, Raf Platformu’nda yer alan ürünlere ilişkin yorum yapmanız ve teklif vermeniz halinde yorumlarınız içerisinde bizzat paylaştığınız bilgiler başta olmak üzere platform üzerinden bizzat tarafınızca paylaşılan bilgiler, iletişim tercihleriniz, tercih edilen ürün bilgileriniz, Raf Destek ile chat kanalıyla veya çağrı merkezi ile yapılan görüşmelerde yer alan bilgiler,
             İşlem Güvenliği Bilgileriniz: IP adresi bilgisi, kullanıcı adı ve şifre bilgileri, çerez bilgileri, kimlik doğrulama bilgileri, internet erişim log bilgileri,
             Hukuki İşlem Bilgileriniz: Yetkili kişi, kurum ve kuruluşlar ile yazışmalardaki bilgiler, dava ve icra dosyalarındaki bilgiler, ihtarnamelerde yer alan bilgiler, yasal bilgi taleplerine verilen cevaplarda yer alan bilgiler,
             Pazarlama Bilgileriniz: alışveriş geçmişi bilgileri, yararlanılan kampanya bilgileri, profilleme ve segmentasyon bilgileriniz, çerez kayıtları
             İşitsel Kayıt Bilgileriniz: çağrı merkeziyle görüşmeniz halinde ses kaydınız
            
             Kişisel verilerinizin işlenmesinin amaçları ve toplama yöntemleri nelerdir?
            Kimlik, iletişim ve müşteri işlem bilgileriniz; sözleşmesel ilişkimiz kapsamında bizzat sizlerden, Şirketimize ait mobil uygulama ya da internet sitesinden elektronik ortamda otomatik olarak aşağıdaki amaçlarla toplanmakta ve işlenmektedir.
            
             Sözleşmenin kurulması ve ifasına yönelik süreçlerin yürütülmesi,
             Finans ve muhasebe süreçlerinin yürütülmesi ve denetimi,
             Şirketin iş faaliyetlerinin yürütülmesi ve denetimi,
             Faturalandırma süreçlerinin yürütülmesi,
             Lojistik, kargo faaliyetlerin yürütülmesi ve teslimat süreçlerinin takibinin yapılması,
             Raf Platformu üzerinden satın alım işlemlerinin gerçekleşmesi ve işlem yapanın kimlik bilgilerinin teyit edilmesi,
             Mal ve hizmet satış sonrası destek hizmetlerinin yürütülmesi,
             Açık rızanızın bulunması halinde, Raf Platformu nezdindeki gelişmeler, kampanyalar, fırsatlar ve yenilikler hakkında tarafınıza bilgi verilmesi, çeşitli pazarlama ve reklam faaliyetlerinde kullanabilmesi,   ilgi alanlarınızı dikkate alarak tercihlerinize uygun ürünlerin tarafınıza sunulması, başta olmak üzere sizlere daha iyi bir alışveriş deneyiminin sağlanması ve memnuniyetinizin arttırılmasına yönelik faaliyetlerin yürütülmesi,
             Pazarlama ve reklam süreçlerinin yürütülmesi, pazarlama ve analiz çalışmalarının yürütülmesi, reklam / kampanya / promosyon süreçlerinin yürütülmesi,
             Kullanıcı memnuniyetini artırmak, Raf Platformundan alışveriş yapan kullanıcılarımızı tanıyabilmek ve analiz çalışmalarında kullanabilmek, Şirketimizin sunduğu ürün ve hizmetlerin geliştirilmesi ve iyileştirilmesine yönelik faaliyetlerin yürütülmesi, anlaşmalı kuruluşlar aracılığıyla elektronik ortamda ve/veya fiziki ortamda anket çalışmaları yapılması,
             İletişim faaliyetlerinin yürütülmesi,
             Müşteri ilişkilerinin yönetimi süreçlerinin yürütülmesi, ürün ve hizmetlerimize ilişkin talep, şikayet ve önerilerinizin değerlendirilmesi ve çözümlenmesi, ürünlere yönelik bilgi almanızın sağlanması,
             Bilgi güvenliği süreçlerinin yürütülmesi,
             Saklama ve arşiv faaliyetlerinin yürütülmesi
             Raf platformuna ilişkin olarak operasyonlarının yürütülmesi ve güvenliğinin sağlanması,
             Faaliyetlerin mevzuata uygun yürütülmesinin sağlanması,
             Hukuk işlerinin takibi ve yürütülmesi,
             Yetkili kişi, kamu kurum ve kuruluşlarına bilgi verilmesi.
            İşlem güvenliği bilgileriniz; sözleşmesel ilişkimiz kapsamında bizzat sizlerden, Şirketimize ait mobil uygulama ya da internet sitesinden elektronik ortamda otomatik olarak aşağıdaki amaçlarla toplanmakta ve işlenmektedir.
            
             Sözleşmenin kurulması ve ifasına yönelik süreçlerin yürütülmesi,
             Şirketin iş faaliyetlerinin yürütülmesi ve denetimi,
             Raf Platformuna ilişkin olarak operasyonlarının yürütülmesi ve güvenliğinin sağlanması,
             Raf Platformu üzerinden satın alım işlemlerinin gerçekleşmesi ve işlem yapanın kimlik bilgilerinin teyit edilmesi,
             Müşteri ilişkilerinin yönetimi süreçlerinin yürütülmesi, ürün ve hizmetlerimize ilişkin talep, şikayet ve önerilerinizin değerlendirilmesi ve çözümlenmesi, ürünlere yönelik bilgi almanızın sağlanması,
             Bilgi güvenliği süreçlerinin yürütülmesi,
             Saklama ve arşiv faaliyetlerinin yürütülmesi
             Faaliyetlerin mevzuata uygun yürütülmesinin sağlanması,
             Hukuk işlerinin takibi ve yürütülmesi,
             Yetkili kişi, kurum ve kuruluşlara bilgi verilmesi.
            
            Hukuki işlem bilgileriniz; sözleşmesel ilişkimiz kapsamında bizzat sizlerden, Şirketimize ait mobil uygulama ya da internet sitesinden, yetkili mercilerden elektronik veya fiziki ortamda aşağıdaki amaçlarla toplanmakta ve işlenmektedir.
            
             Sözleşmenin kurulması ve ifasına yönelik süreçlerin yürütülmesi,
             Şirketin iş faaliyetlerinin yürütülmesi ve denetimi,
             Raf Platformuna ilişkin olarak operasyonlarının yürütülmesi ve güvenliğinin sağlanması,
             Raf Platformu üzerinden satın alım işlemlerinin gerçekleşmesi ve işlem yapanın kimlik bilgilerinin teyit edilmesi,
             İç Denetim/ Soruşturma / İstihbarat Faaliyetlerinin Yürütülmesi
             Saklama ve arşiv faaliyetlerinin yürütülmesi
             Faaliyetlerin mevzuata uygun yürütülmesinin sağlanması,
             Hukuk işlerinin takibi ve yürütülmesi,
             Yetkili kişi, kurum ve kuruluşlara bilgi verilmesi.
            Pazarlama bilgileriniz; sözleşmesel ilişkimiz kapsamında bizzat sizlerden, Şirketimize ait mobil uygulama ya da internet sitesinden elektronik ortamda otomatik olarak aşağıdaki amaçlarla toplanmakta ve işlenmektedir.
            
             Şirketin iş faaliyetlerinin yürütülmesi ve denetimi,
             Açık rızanızın bulunması halinde, Raf Platformu nezdindeki gelişmeler, kampanyalar, fırsatlar ve yenilikler hakkında tarafınıza bilgi verilmesi, çeşitli pazarlama ve reklam faaliyetlerinde kullanabilmesi,   ilgi alanlarınızı dikkate alarak tercihlerinize uygun ürünlerin tarafınıza sunulması, başta olmak üzere sizlere daha iyi bir alışveriş deneyiminin sağlanması ve memnuniyetinizin arttırılmasına yönelik faaliyetlerin yürütülmesi,
             Pazarlama ve reklam süreçlerinin yürütülmesi, pazarlama ve analiz çalışmalarının yürütülmesi, reklam / kampanya / promosyon süreçlerinin yürütülmesi,
             Kullanıcı memnuniyetini artırmak, Raf Platformundan alışveriş yapan kullanıcılarımızı tanıyabilmek ve analiz çalışmalarında kullanabilmek, Şirketimizin sunduğu ürün ve hizmetlerin geliştirilmesi ve iyileştirilmesine yönelik faaliyetlerin yürütülmesi, anlaşmalı kuruluşlar aracılığıyla elektronik ortamda ve/veya fiziki ortamda anket çalışmaları yapılması,
             İletişim faaliyetlerinin yürütülmesi,
             Saklama ve arşiv faaliyetlerinin yürütülmesi
             Raf Platformuna ilişkin olarak operasyonlarının yürütülmesi ve güvenliğinin sağlanması,
             Faaliyetlerin mevzuata uygun yürütülmesinin sağlanması,
             Yetkili kişi, kamu kurum ve kuruluşlarına bilgi verilmesi.
            İşitsel kayıt bilgileriniz; çağrı merkeziyle iletişime geçmeniz halinde otomatik olarak aşağıdaki amaçlarla toplanmakta ve işlenmektedir.
            
             Sözleşmenin kurulması ve ifasına yönelik süreçlerin yürütülmesi,
             Şirketin iş faaliyetlerinin yürütülmesi ve denetimi,
             İşlem yapanın kimlik bilgilerinin teyit edilmesi,
             Mal ve hizmet satış sonrası destek hizmetlerinin yürütülmesi,
             Pazarlama ve reklam süreçlerinin yürütülmesi, pazarlama ve analiz çalışmalarının yürütülmesi, reklam / kampanya / promosyon süreçlerinin yürütülmesi,
             İletişim faaliyetlerinin yürütülmesi,
             Müşteri ilişkilerinin yönetimi süreçlerinin yürütülmesi, ürün ve hizmetlerimize ilişkin talep, şikayet ve önerilerinizin değerlendirilmesi ve çözümlenmesi, ürünlere yönelik bilgi almanızın sağlanması,
             Bilgi güvenliği süreçlerinin yürütülmesi,
             Saklama ve arşiv faaliyetlerinin yürütülmesi
             Raf platformuna ilişkin olarak operasyonlarının yürütülmesi ve güvenliğinin sağlanması,
             Faaliyetlerin mevzuata uygun yürütülmesinin sağlanması,
             Hukuk işlerinin takibi ve yürütülmesi,
             Yetkili kişi, kamu kurum ve kuruluşlarına bilgi verilmesi.
            `}
            </Text>
            <View style={styles.primaryTextContainer}>
                <Text style={styles.primaryText}>B- RAF PLATFORMUNUN “SATICI” OLARAK KULLANILMASI HALİNDE İŞLENEN KİŞİSEL VERİLERİNİZ</Text>
            </View>
            <Text>{`
             Raf tarafından hangi kişisel verileriniz işlenmektedir?
            Raf Platformu'nda yer alan Üyelik Koşullarını kabul ederek kabul ederek web sitesinden veya mobil uygulamadan üye olan ve Raf Platformuna ürün ekleyerek satışa sunan kişiler “Satıcı” olarak tanımlanmaktadır. Raf Platformu'nda satıcı olmanız halinde aşağıdaki kişisel verileriniz işlenebilmektedir.
            
             Kimlik Bilgileriniz: TC kimlik numaranız, adınız, soyadınız, doğum yılınız,  kullanıcı adınız, satıcının şahıs şirketi olması halinde mersis numarası, ticaret sicil numarası, vergi kimlik numarası ve vergi dairesi bilgisi, satıcının sermaye şirketi olması halinde satıcı yetkilisine ait ad soyad bilgileri
             İletişim Bilgileriniz: cep telefonu numaranız, adres bilgileriniz, e-posta adresiniz, satıcının şahıs şirketi olması halinde KEP adresi, satıcının sermaye şirketi olması halinde satıcı yetkilisine ait telefon numarası
             Müşteri İşlem Bilgileriniz: sipariş, ödeme ve fatura bilgileriniz, banka IBAN bilgileriniz, teslimat işlem bilgileriniz, bakiye bilginiz, satış işlemlerinize ilişkin işlem geçmişi bilgisi, talep ve şikayet bilgileriniz, Raf Platformu'nda yer alan ürünlere ilişkin yorum yapmanız ve teklif vermeniz halinde yorumlarınız içerisinde paylaştığınız bilgiler başta olmak üzere Raf Platformu üzerinden bizzat tarafınızca paylaşılan bilgiler, Raf Destek ile chat kanalıyla veya çağrı merkezi ile yapılan görüşmelerde yer alan bilgiler, iletişim tercihleriniz
             İşlem Güvenliği Bilgileriniz: IP adresi bilgisi, kullanıcı adı ve şifre bilgileri, çerez bilgileri, kimlik doğrulama bilgileri, internet erişim log bilgileri,
             Hukuki İşlem Bilgileriniz: Yetkili kişi, kurum ve kuruluşlar ile yazışmalardaki bilgiler, dava ve icra dosyalarındaki bilgiler, ihtarnamelerde yer alan bilgiler, yasal bilgi taleplerine verilen cevaplarda yer alan bilgiler,
             Pazarlama Bilgileriniz: Satış işlemlerine ilişkin işlem geçmişi bilgileri, yararlanılan kampanya bilgileri,  profilleme ve segmentasyon bilgileri, çerez kayıtları
             Görsel ve İşitsel Kayıt Bilgileriniz: Çağrı merkeziyle görüşmeniz halinde ses kaydınız, kullanıcı hesabınız altında paylaştığınız fotoğraf ve video kayıtları ve bu kayıtlarda paylaştığınız diğer kişisel verileriniz
             Diğer: Raf Platformu üzerinden bağış yapmayı tercih etmeniz halinde bağış tutarınız, bağış yapılan kurum
            
             Kişisel verilerinizin işlenmesinin amaçları ve toplama yöntemleri nelerdir?
            Kimlik, iletişim ve müşteri işlem bilgileriniz; sözleşmesel ilişkimiz kapsamında bizzat sizlerden, Şirketimize ait mobil uygulama ya da internet sitesinden elektronik ortamda otomatik olarak aşağıdaki amaçlarla toplanmakta ve işlenmektedir.
            
             Sözleşmenin kurulması ve ifasına yönelik süreçlerin yürütülmesi,
             Finans ve muhasebe süreçlerinin yürütülmesi, Raf Platformu üzerinde sattığınız ürünlerin ödemesinin yapılması,
             Şirketin iş faaliyetlerinin yürütülmesi ve denetimi,
             Faturalandırma süreçlerinin yürütülmesi,
             Lojistik, kargo faaliyetlerin yürütülmesi ve teslimat süreçlerinin takibinin yapılması,
             Raf Platformu üzerinden satış işlemlerinin gerçekleşmesi, ürünlerini tanıtılmasına imkan sunulması
              İşlem yapanın bilgilerinin doğrulanması veya teyit edilmesi,
             Mal ve hizmet satış sonrası destek hizmetlerinin yürütülmesi,
             Açık rızanızın bulunması halinde, Raf Platformu nezdindeki gelişmeler, kampanyalar, fırsatlar ve yenilikler hakkında tarafınıza bilgi verilmesi, çeşitli pazarlama ve reklam faaliyetlerinde kullanabilmesi, sizlere daha iyi bir alışveriş deneyiminin sağlanması ve memnuniyetinizin arttırılmasına yönelik faaliyetlerin yürütülmesi,
             Pazarlama ve reklam süreçlerinin yürütülmesi, pazarlama ve analiz çalışmalarının yürütülmesi, reklam / kampanya / promosyon süreçlerinin yürütülmesi,
             Kullanıcı memnuniyetini artırmak, Raf Platformu'nda satış yapan kullanıcılarımızı tanıyabilmek ve analiz çalışmalarında kullanabilmek, Şirketimizin sunduğu ürün ve hizmetlerin geliştirilmesi ve iyileştirilmesine yönelik faaliyetlerin yürütülmesi, anlaşmalı kuruluşlar aracılığıyla elektronik ortamda ve/veya fiziki ortamda anket çalışmaları yapılması,
             İletişim faaliyetlerinin yürütülmesi,
             Kullanıcı ilişkilerinin yönetimi süreçlerinin yürütülmesi, ürün ve hizmetlerimize ilişkin talep, şikayet ve önerilerinizin değerlendirilmesi ve çözümlenmesi, ürünlere yönelik bilgi almanızın sağlanması,
             Bilgi güvenliği süreçlerinin yürütülmesi,
             Saklama ve arşiv faaliyetlerinin yürütülmesi
             Raf Platformu’na ilişkin olarak operasyonlarının yürütülmesi ve güvenliğinin sağlanması,
             Faaliyetlerin mevzuata uygun yürütülmesinin sağlanması,
             Hukuk işlerinin takibi ve yürütülmesi,
             Yetkili kişi, kamu kurum ve kuruluşlarına bilgi verilmesi.
            İşlem güvenliği bilgileriniz; sözleşmesel ilişkimiz kapsamında bizzat sizlerden, Şirketimize ait mobil uygulama ya da internet sitesinden elektronik ortamda otomatik olarak aşağıdaki amaçlarla toplanmakta ve işlenmektedir.
            
             Sözleşmenin kurulması ve ifasına yönelik süreçlerin yürütülmesi,
             Şirketin iş faaliyetlerinin yürütülmesi ve denetimi,
             Raf Platformuna ilişkin olarak operasyonlarının yürütülmesi ve güvenliğinin sağlanması,
             Raf Platformu üzerinden satış işlemlerinin gerçekleşmesi ve işlem yapanın bilgilerinin teyit edilmesi,
             Kullanıcı ilişkilerinin yönetimi süreçlerinin yürütülmesi, ürün ve hizmetlerimize ilişkin talep, şikayet ve önerilerinizin değerlendirilmesi ve çözümlenmesi, ürünlere yönelik bilgi almanızın sağlanması,
             Bilgi güvenliği süreçlerinin yürütülmesi,
             Saklama ve arşiv faaliyetlerinin yürütülmesi
             Faaliyetlerin mevzuata uygun yürütülmesinin sağlanması,
             Hukuk işlerinin takibi ve yürütülmesi,
             Yetkili kişi, kurum ve kuruluşlara bilgi verilmesi.
            
            Hukuki işlem bilgileriniz; sözleşmesel ilişkimiz kapsamında bizzat sizlerden, Şirketimize ait mobil uygulama ya da internet sitesinden, yetkili mercilerden elektronik veya fiziki ortamda aşağıdaki amaçlarla toplanmakta ve işlenmektedir.
            
             Sözleşmenin kurulması ve ifasına yönelik süreçlerin yürütülmesi,
             Şirketin iş faaliyetlerinin yürütülmesi ve denetimi,
             Raf Platformuna ilişkin olarak operasyonlarının yürütülmesi ve güvenliğinin sağlanması,
             Raf Platformu üzerinden satış işlemlerinin gerçekleşmesi ve işlem yapanın kimlik bilgilerinin teyit edilmesi,
             İç Denetim/ Soruşturma / İstihbarat Faaliyetlerinin Yürütülmesi
             Saklama ve arşiv faaliyetlerinin yürütülmesi
             Faaliyetlerin mevzuata uygun yürütülmesinin sağlanması,
             Hukuk işlerinin takibi ve yürütülmesi,
             Yetkili kişi, kurum ve kuruluşlara bilgi verilmesi.
            Pazarlama bilgileriniz; sözleşmesel ilişkimiz kapsamında bizzat sizlerden, Şirketimize ait mobil uygulama ya da internet sitesinden elektronik ortamda otomatik olarak aşağıdaki amaçlarla toplanmakta ve işlenmektedir.
            
             Şirketin iş faaliyetlerinin yürütülmesi ve denetimi,
             Açık rızanızın bulunması halinde, Raf Platformu nezdindeki gelişmeler, kampanyalar, fırsatlar ve yenilikler hakkında tarafınıza bilgi verilmesi, çeşitli pazarlama ve reklam faaliyetlerinde kullanabilmesi, sizlere daha iyi bir alışveriş deneyiminin sağlanması ve memnuniyetinizin arttırılmasına yönelik faaliyetlerin yürütülmesi,
             Pazarlama ve reklam süreçlerinin yürütülmesi, pazarlama ve analiz çalışmalarının yürütülmesi, reklam / kampanya / promosyon süreçlerinin yürütülmesi,
             Kullanıcı memnuniyetini artırmak, Raf Platformu'nda satış yapan kullanıcılarımızı tanıyabilmek ve analiz çalışmalarında kullanabilmek, Şirketimizin sunduğu ürün ve hizmetlerin geliştirilmesi ve iyileştirilmesine yönelik faaliyetlerin yürütülmesi, anlaşmalı kuruluşlar aracılığıyla elektronik ortamda ve/veya fiziki ortamda anket çalışmaları yapılması,
             İletişim faaliyetlerinin yürütülmesi,
             Saklama ve arşiv faaliyetlerinin yürütülmesi
             Raf Platformuna ilişkin olarak operasyonlarının yürütülmesi ve güvenliğinin sağlanması,
             Faaliyetlerin mevzuata uygun yürütülmesinin sağlanması,
             Yetkili kişi, kamu kurum ve kuruluşlarına bilgi verilmesi.
            Görsel ve İşitsel kayıt bilgileriniz; çağrı merkeziyle iletişime geçmeniz veya kullanıcı hesabınızda fotoğraf veya video paylaşmanız halinde otomatik olarak aşağıdaki amaçlarla toplanmakta ve işlenmektedir.
            
             Sözleşmenin kurulması ve ifasına yönelik süreçlerin yürütülmesi,
             Şirketin iş faaliyetlerinin yürütülmesi ve denetimi,
             Raf Platformu üzerinden satış işlemlerinin gerçekleşmesi ve satıcının ürünlerini tanıtmasına imkan sunulması,
             İş süreçlerinin geliştirilmesi ve iyileştirilmesi, içerik üretilmesi,
             İşlem yapanın bilgilerinin doğrulanması veya teyit edilmesi,
             Mal ve hizmet satış sonrası destek hizmetlerinin yürütülmesi,
             Pazarlama ve reklam süreçlerinin yürütülmesi, pazarlama ve analiz çalışmalarının yürütülmesi, reklam / kampanya / promosyon süreçlerinin yürütülmesi,
             İletişim faaliyetlerinin yürütülmesi,
             Kullanıcı ilişkilerinin yönetimi süreçlerinin yürütülmesi, ürün ve hizmetlerimize ilişkin talep, şikayet ve önerilerinizin değerlendirilmesi ve çözümlenmesi, ürünlere yönelik bilgi almanızın sağlanması,
             Bilgi güvenliği süreçlerinin yürütülmesi,
             Saklama ve arşiv faaliyetlerinin yürütülmesi
             Raf platformuna ilişkin olarak operasyonlarının yürütülmesi ve güvenliğinin sağlanması,
             Faaliyetlerin mevzuata uygun yürütülmesinin sağlanması,
             Hukuk işlerinin takibi ve yürütülmesi,
             Yetkili kişi, kamu kurum ve kuruluşlarına bilgi verilmesi.
            
            Diğer bilgileriniz; sözleşmesel ilişkimiz kapsamında bizzat sizlerden, Şirketimize ait mobil uygulama ya da internet sitesinden elektronik ortamda otomatik olarak aşağıdaki amaçlarla toplanmakta ve işlenmektedir.
            
             Sözleşmenin kurulması ve ifasına yönelik süreçlerin yürütülmesi,
             Sosyal sorumluluk ve sivil toplum aktivitelerinin yürütülmesi
             Faaliyetlerin mevzuata uygun yürütülmesi
             Yetkili kişi, kurum ve kuruluşlara bilgi verilmesi
           `}
            </Text>
            <View style={styles.primaryTextContainer}>
                <Text style={styles.primaryText}> C- KİŞİSEL VERİLERİNİZİN İŞLENMESİNİN HUKUKİ SEBEPLERİ NELERDİR?</Text>
            </View>
            <Text>{` 
            Raf tarafından yukarıda belirtilen kişisel verileriniz aşağıda yer verilen hukuki sebeplere dayanılarak işlenmektedir.
            
             6563 Elektronik Ticaretin Düzenlenmesi Hakkında Kanun, 6102 Sayılı Türk Ticaret Kanunu başta olmak üzere Şirketimizin tabi olduğu mevzuatta açıkça öngörülmüş olması halinde kimlik, iletişim, müşteri işlem, işlem güvenliği, pazarlama, hukuki işlem bilgileriniz işlenmektedir.
             Sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla, kişisel verilerinizin işlenmesinin gerekli olması hukuki sebebine dayanarak kimlik, iletişim, müşteri işlem, işlem güvenliği, görsel ve işitsel kayıt bilgileriniz işlenmektedir.
             Şirketimizin hukuki yükümlülüğünün yerine getirebilmesi için zorunlu olması hukuki sebebine dayanarak kimlik, iletişim, müşteri işlem, işlem güvenliği, hukuki işlem, görsel ve işitsel kayıt bilgileriniz işlenmektedir.
             Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması hukuki sebebine dayanarak kimlik, iletişim, müşteri işlem, işlem güvenliği, hukuki işlem, görsel ve işitsel kayıt bilgileriniz işlenmektedir.
            
            Açık rızanızın bulunması halinde; kimlik, iletişim, müşteri işlem ve pazarlama veri kategorisinde yer alan kişisel verileriniz pazarlama ve reklam faaliyetlerinde kullanılabilmesi, tercihlerinize uygun ürünlerin tarafınıza sunulması başta olmak üzere sizlere daha iyi bir alışveriş deneyiminin sağlanması ve memnuniyetinizin arttırılmasına yönelik faaliyetlerin yürütülmesi, Raf nezdindeki gelişmeler, fırsatlar ve yenilikler hakkında tarafınıza bilgi verilmesi, ticari elektronik iletiler gönderilmesi amacıyla işlenmektedir.
            `}
            </Text>
            <View style={styles.primaryTextContainer}>
                <Text style={styles.primaryText}>D- RAF KİŞİSEL VERİLERİNİZİ PAYLAŞIYOR MU?</Text>
            </View>
            <Text>{`
            Şirketimiz, kişisel verilerinizi “bilme gereği” ve “kullanma gereği” ilkelerine uygun olarak, gerekli veri minimizasyonunu sağlayarak ve gerekli teknik ve idari güvenlik tedbirlerini alarak işlemeye özen göstermektedir. İş faaliyetlerinin yürütülmesi veya denetimi, iş sürekliliğinin sağlanması, dijital altyapıların işletilmesi, bilgi güvenliğinin sağlanması farklı paydaşlarla sürekli veri akışını zaruri kıldığı için işlediğimiz kişisel verileri belirli amaçlarla üçüncü kişilere aktarmak durumundayız. Ayrıca, sözleşmesel ve kanuni yükümlülüklerimizi tam ve gereği gibi yerine getirebilmemiz için kişisel verilerinizin doğru ve güncel olması çok önemlidir. Bunun için de muhtelif iş ortaklarıyla ve hizmet sağlayıcılarla çalışmak durumundayız.
            
            Kişisel verileriniz, yukarıda belirtilen amaçlarla sınırlı olarak gerekli olduğu ölçüde:
            
             Kargo, lojistik faaliyetlerin yürütülmesi ve teslimat süreçlerinin takibinin yapılması amacıyla kargo firmalarıyla,
             Satın alım süreçlerinin yürütülmesi amacıyla hizmet sağlayıcılarımızla,
             Mal ve hizmet satış ve satış sonrası destek hizmetlerinin sunulması için çağrı merkezi hizmeti sunan iş ortakları ve hizmet sağlayıcılarıyla,
             Hizmetlerin kalite kontrol, şikâyet yönetimi ve risk analizi alanında hizmet sunan iş ortakları ve hizmet sağlayıcılarıyla,
             Finansal ve muhasebe süreçlerinin yönetimi, risklerin tespiti, değerlendirilmesi, dolandırıcılıkların önlenmesi için ilgili iş ortaklarımız, danışmanlarımız ve hizmet sağlayıcılarımızla, bankalarla, mali müşavirlerimizle,
             Ürünlerin tanıtımı amacıyla kullanıcı hesabında paylaşılan fotoğraf, video ve tüm içerikler Raf Platformu ana sayfasında veya diğer alanlarda Raf kullanıcılarıyla,
             Ticari elektronik iletilerin yönetilmesi amacıyla ileti yönetim sistemi, Ticaret Bakanlığı ve ileti gönderimi için aracı hizmet sağlayıcı ve iş ortakları ile,
             Bilişim altyapımızı sağlayan, işleten veya hizmet sunan iş ortaklarımızla ve hizmet sağlayıcılarımızla,
             Risk yönetimi ve finansal raporlama süreçlerinin yürütülmesi alanında hizmet sunan iş ortaklarımızla,
             Açık rızanızın bulunması halinde, web sitesi kullanım tercihleri ve gezinti geçmişi, profilleme ve segmentasyon yapılması ve kullanıcı ile tercihleri doğrultusunda iletişime geçilmesi, pazarlama, reklam, analiz süreçlerinin yönetilmesi  amacıyla ilgili hizmet sağlayıcı ve iş ortağıyla,
             Hukuki yükümlülüklerin yerine getirilmesi ve sözleşmenin ifası kapsamında topluluk şirketlerimiz, iştiraklerimiz, avukatlar, denetçiler, adli bilişim uzmanları, siber güvenlik danışmanları, vergi danışmanları ile danışmanlık ve hizmet aldığımız diğer üçüncü kişilerle ve iş ortaklarıyla,
             Talep halinde, düzenleyici ve denetleyici kurumlar, savcılık, mahkeme ve icra müdürlükleri ve diğer yetkili merciler ve kamu kurum ve kuruluşlarıyla,
             Yurtiçi ve/veya yurtdışında bulunan iştiraklerimizle, tedarikçilerimizle, iş ortaklarımızla, anlaşmalı olduğumuz bankalarla ve ürün veya hizmet aldığımız üçüncü kişilerle paylaşılabilmektedir.
             `}
             </Text>
             <View style={styles.primaryTextContainer}>
                 <Text style={styles.primaryText}>E- KİŞİSEL VERİLERİNİZİN KORUNMASINA YÖNELİK HAKLARINIZ</Text>
             </View>
             <Text>{`
            Bu Aydınlatma Metni’nin “Hak ve Talepleriniz İçin İletişim” bölümünde yer alan yöntemlerle Şirketimize başvurarak,
            
             Kişisel verilerinizin işlenip işlenmediğini öğrenme,
             İşlenmişse buna ilişkin bilgi talep etme,
             Kişisel verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,
             Yurt içinde veya yurt dışında aktarıldığı üçüncü kişileri bilme,
             Kişisel verilerin eksik veya yanlış işlenmiş olması halinde bunların düzeltilmesini isteme,
             KVKK’da öngörülen şartlar çerçevesinde kişisel verilerinizin silinmesini veya yok edilmesini isteme,
             Yukarıda belirtilen düzeltme, silinme ve yok edilme şeklindeki haklarınız uyarınca yapılan işlemlerin, kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,
             İşlenen kişisel verilerinizin münhasıran otomatik sistemler ile analiz edilmesi sureti ile aleyhinize bir sonucun ortaya çıkmasına itiraz etme,
             Kişisel verilerinizin ilgili mevzuata aykırı olarak işlenmesi sebebiyle zarara uğramanız hâlinde zararınızın giderilmesini talep etme haklarına sahipsiniz.
             `}
             </Text>
             <View style={styles.primaryTextContainer}>
                 <Text style={styles.primaryText}>F- HAK VE TALEPLERİNİZ İÇİN İLETİŞİM</Text>
             </View>
             <Text>{`
            Kişisel verilerinizle ilgili sorularınızı ve taleplerinizi, Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ’de belirtilen şartlara uygun bir şekilde düzenlenmiş olan; ad, soyad, TCKN, adres, telefon numaranız, imzanızı ve talep konusunu içeren Başvuru Formu veya bir dilekçeyle destek@raftankitap.com e-posta adresine email atarak Şirketimize iletebilirsiniz.
            **Başvuru formunun tebligat zarfına veya e-postanın konu kısmına “Kişisel Verilerin Korunması Kanunu İlgili Kişi Talebi” yazılmasını rica ederiz.
            
            AYDINLATMA METNİ HAKKINDA
            Şirket, işbu Aydınlatma Metnini Raf Platformunda, yürürlükteki mevzuatta veya Şirket uygulamalarında yapılabilecek değişiklikler çerçevesinde her zaman güncelleme hakkını saklı tutar.
            
            Güncellenme Tarihi: 08.10.2023 `}
                    </Text>
                </ScrollView>
            </View>
        </View>
    );
};

export default PrivacyScreen;

const styles = StyleSheet.create({
    container: {
        width: "100%",
        flexDirecion: "row",
        backgroundColor: colors.light,
        alignItems: "center",
        justifyContent: "flex-start",
        padding: 20,
        flex: 1,
    },
    TopBarContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    UserProfileCardContianer: {
        width: "100%",
        height: "25%",
    },
    primaryTextContainer: {
        padding: 20,
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        width: "100%",
        paddingTop: 10,
        paddingBottom: 10,
    },
    primaryText: {
        fontSize: 20,
        fontWeight: "bold",
    },
    screenNameContainer: {
        marginTop: 10,
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        marginBottom: 10,
    },
    screenNameText: {
        fontSize: 30,
        fontWeight: "800",
        color: colors.muted,
    },
    OptionsContainer: {
        width: "100%",
        flex: 1,
        justifyContent: 'center'
    },
});
