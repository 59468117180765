import {
  StyleSheet,
  StatusBar,
  View,
  TouchableOpacity,
  Text,
  ScrollView,
  FlatList,
  RefreshControl,
} from "react-native";
import React, { useState, useEffect } from "react";
import { Ionicons } from "@expo/vector-icons";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { colors, network } from "../../constants";
import CustomCard from "../../components/CustomCard";
import OptionList from "../../components/OptionList/OptionList";
import ProgressDialog from "react-native-progress-dialog";
import { useAuthStore } from "../../states/store";
import SkeletonPlaceholder from "../../components/SkeletonPlaceholder";
import { Dimensions } from "react-native";

const screenWidth = Dimensions.get("window").width;
const isMobile = screenWidth < 768; // Adjust breakpoint for mobile/laptop
const numColumns = isMobile ? 2 : 5;
const cardWidth = isMobile ? "45%" : `${100 / numColumns - 3}%`; // Account for spacing
const AdminDashboardScreen = ({ navigation, route }) => {
  const user = useAuthStore((state) => state.user);
  const logout = useAuthStore((state) => state.logout);
  const [label, setLabel] = useState("Loading...");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [refeshing, setRefreshing] = useState(false);


  var myHeaders = new Headers();
  myHeaders.append("x-auth-token", user?.token);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  //method the fetch the statistics from server using API call
  const fetchStats = () => {
    setIsLoading(true)
    fetch(`${network.serverip}/admin/dashboard`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success == true) {
          //set the fetched data to Data state
          setData([
            {
              id: 1,
              title: "Kullanicilar",
              value: result.data?.usersCount,
              iconName: "person",
              type: "primary",
              screenName: "viewusers",
            },
            {
              id: 2,
              title: "Satis Sayisi",
              value: result.data?.ordersCount,
              iconName: "cash-outline",
              type: "secondary",
              screenName: "viewalltransactions",
            },
            {
              id: 3,
              title: "Ürünler",
              value: result.data?.productsCount,
              iconName: "cart",
              type: "warning",
              screenName: "viewallproducts",
            },
            {
              id: 4,
              title: "Teklifler",
              value: result.data?.offersCount,
              iconName: "bar-chart-outline",
              type: "muted",
              screenName: "viewalloffers",
            },
            {
              id: 5,
              title: "Satıcıların Geliri",
              value: `₺${result.data?.sellersRevenue}`,
              iconName: "cash-outline",
              type: "success",
              screenName: "viewalltransactions",
            },
            {
              id: 6,
              title: "Platformun Payı",
              value: `₺${result.data?.platformRevenue}`,
              iconName: "cash",
              type: "success",
              screenName: "viewalltransactions",
            },
            {
              id: 7,
              title: "Toplam Ziyaret Sayısı",
              value: result.data?.totalClicks,
              iconName: "eye",
              type: "muted",
              screenName: "viewalltransactions",
            },
            {
              id: 8,
              title: "Geri İade Edilen Ürünler",
              value: result.data?.returnProducts,
              iconName: "return-down-back",
              type: "danger",
              screenName: "viewalltransactions"
            },
            {
              id: 9,
              title: "Satıcı Sayısı",
              value: result.data?.sellersCount,
              iconName: "people",
              type: "primary",
              screenName: "viewusers",
            },
            {
              id: 10,
              title: "Alıcı Sayısı",
              value: result.data?.buyersCount,
              iconName: "people",
              type: "primary",
              screenName: "viewusers",
            }]);
          setError("");
          setIsLoading(false);
        } else {
          console.log(result.err);
          if (result.err == "jwt expired") {
            logout();
          }
          setError(result.message);
          setIsLoading(false);
          setData([])
        }
      })
      .catch((error) => {
        setError(error.message);
        console.log("error", error);
        setIsLoading(false);
      });
  };

  //method call on Pull refresh
  const handleOnRefresh = () => {
    setRefreshing(true);
    fetchStats();
    setRefreshing(false);
  };

  //call the fetch function initial render
  useEffect(() => {
    fetchStats();
  }, []);

  return (
    <View style={styles.container}>
      <StatusBar></StatusBar>
      <ProgressDialog visible={isLoading} label={label} />
      <View style={styles.topBarContainer}>
        <TouchableOpacity
          onPress={() => {
            navigation.navigate("tabs", { screen: "profile" });
          }}
        >
          <Ionicons
            name="arrow-back-circle-outline"
            size={30}
            color={colors.muted}
          />
        </TouchableOpacity>
      </View>
      <View style={styles.screenNameContainer}>
        <View>
          <Text style={styles.screenNameText}>Admin Paneli</Text>
        </View>
      </View>
      <View style={styles.headingContainer}>
        <View>
          <Text style={styles.headingText}>İstatistikler</Text>
        </View>
      </View>
      <View style={{ height: '100%', flex: 1}}>
        {data.length > 0 ? (
          <ScrollView
            refreshControl={
              <RefreshControl
                refreshing={refeshing}
                onRefresh={handleOnRefresh}
              />
            }
            showsVerticalScrollIndicator={false} // Hides the vertical scrollbar
            showsHorizontalScrollIndicator={false} // Hides the horizontal scrollbar
            contentContainerStyle={styles.scrollViewCardContainer}
          >
            <View style={styles.cardContainer}>
              {data.map((item) => (
                <View key={item.id} style={styles.cardWrapper}>
                  <CustomCard
                    key={item.id}
                    iconName={item.iconName}
                    title={item.title}
                    value={item.value}
                    type={item.type}
                    onPress={() => {
                      navigation.navigate(item.screenName);
                    }}
                  />
                </View>
              ))}
            </View>
          </ScrollView>
        ) : <SkeletonPlaceholder />}
      </View>
      <View style={styles.headingContainer}>
        {/* <MaterialCommunityIcons name="menu-right" size={30} color="black" /> */}
        <Text style={styles.headingText}>Hareketler</Text>
      </View>
      <View style={{ flex: 1, width: "100%" }}>
        <ScrollView style={styles.actionContainer}>
          {/* <OptionList
              text={"Products"}
              Icon={Ionicons}
              iconName={"md-square"}
              onPress={() =>
                navigation.navigate("viewmyproducts")
              }
              onPressSecondary={() =>
                navigation.navigate("addproduct")
              }
              type="morden"
            /> */}
          {/* <OptionList
              text={"Categories"}
              Icon={Ionicons}
              iconName={"menu"}
              onPress={() =>
                navigation.navigate("viewcategories")
              }
              onPressSecondary={() =>
                navigation.navigate("addcategories")
              }
              type="morden"
            /> */}
          <OptionList
            text={"Bütün Satışlar"}
            Icon={Ionicons}
            iconName={"cart"}
            onPress={() =>
              navigation.navigate("viewalltransactions")
            }
          />
          <OptionList
            text={"Bütün Kullanıcılar"}
            Icon={Ionicons}
            iconName={"people-circle-sharp"}
            onPress={() =>
              navigation.navigate("viewusers")
            }
          />

          <View style={{ height: 20 }}></View>
        </ScrollView>
      </View>
    </View>
  );
};

export default AdminDashboardScreen;

const styles = StyleSheet.create({
  cardContainer: {
    flexDirection: "row",
    flexWrap: "wrap",      // Ensures cards wrap to the next row
    justifyContent: "space-between", // Proper spacing between cards
  },
  cardWrapper: {
    width: cardWidth,
    marginBottom: 15, // Vertical spacing between rows
    borderRadius: 8,
    alignItems: "center",
  },
  screenNameContainer: {
    marginTop: 10,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: 10,
  },
  screenNameText: {
    fontSize: 30,
    fontWeight: "800",
    color: colors.muted,
  },
  container: {
    width: "100%",
    backgroundColor: colors.light,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    flex: 1,
  },
  topBarContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  toBarText: {
    fontSize: 15,
    fontWeight: "600",
  },
  scrollViewCardContainer: {
    // flexDirection: "row",
    // flexWrap: "wrap",
    paddingHorizontal: 10, // Ensure proper padding inside the ScrollView
    paddingVertical: 10,   // Adds spacing at the top and bottom
    alignItems: "center",  // Centers the content horizontally

  },
  bodyContainer: {
    width: "100%",
  },
  headingContainer: {
    display: "flex",
    justifyContent: "flex-start",
    paddingLeft: 20,
    width: "100%",
    alignItems: "center",
    flexDirection: "row",
  },
  headingText: {
    fontSize: 20,
    color: colors.muted,
    fontWeight: "800",
  },
  actionContainer: { padding: 20, width: "100%", flex: 1 },
});
