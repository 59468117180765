import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { View, Text, StyleSheet, Image, TouchableOpacity, TextInput, Button, Linking, Pressable, ScrollView, StatusBar } from 'react-native';
import { Ionicons, AntDesign, MaterialCommunityIcons, Foundation } from '@expo/vector-icons';
import { colors } from "../../constants"
import googlePlayLogo from "../../assets/logo/google-store-badge.png";
import appleStoreLogo from "../../assets/logo/apple-store-badge.png";
import websiteBadge from "../../assets/logo/website-badge.png"
import bookPicture from "../../assets/image/landing-book.jpg"
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useAuthStore } from '../../states/store';
import iyzicoLogo from "../../assets/logo/iyzico-logo.svg"

const LandingScreen = ({ navigation }) => {
    const login = useAuthStore((state) => state.login);
    const logout = useAuthStore((state) => state.logout);
    const user = useAuthStore((state) => state.user);
    
    const [userLoggedIn, setUserLoggedIn] = useState(false);
    const handleLogin = () => {
        navigation.navigate("login");
    }
    const handleTour = () => {
        navigation.navigate("tabs", { screen: "buy" })
    }

    const handleHover = () => {

    }

    const handleLoggedInUser = () => {
        navigation.navigate("tabs", { screen: "buy" });
    }

    const handleSignUp = () => {
        navigation.navigate("signup");
    }

    const handleWebBadgeClick = () => {
        navigation.navigate("login");
    }

    const handleGoogleBadgePress = useCallback(async () => {
        // Checking if the link is supported for links with custom URL scheme.
        // Opening the link with some app, if the URL scheme is "http" the web link should be opened
        // by some browser in the mobile
        await Linking.openURL("https://play.google.com/store/apps/details?id=com.raftankitap.android");
    }, []);


    const handleAppleBadgePress = useCallback(async () => {
        // Checking if the link is supported for links with custom URL scheme.
        // Opening the link with some app, if the URL scheme is "http" the web link should be opened
        // by some browser in the mobile
        await Linking.openURL("https://apps.apple.com/app/idraf");
    }, []);


    //method to fetch the userData data from aync storage if there is any and login the Dashboard or Home Screen according to the user type
    const _retrieveData = () => {
        try {
            if (user !== null) {
                setUserLoggedIn(true);
            } else {
                setUserLoggedIn(false);
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    // check the userData and navigate to screens accordingly on initial render
    useEffect(() => {
        _retrieveData();
    }, []);

    return (
        <ScrollView style={styles.container}>
            <StatusBar backgroundColor={colors.primary}></StatusBar>
            {/* NavigationBar */}
            <View style={styles.navigationBar}>
                <Image source={require('../../assets/logo/logo_white.png')} style={styles.companyLogo} />
                <View style={styles.buttonContainer}>
                    {user ?
                        <TouchableOpacity onPress={(e) => handleLoggedInUser()} style={styles.button}>
                            <Text style={styles.buttonText}>UYGULAMAYA GİT</Text>
                        </TouchableOpacity>
                        : <Fragment>
                            <TouchableOpacity onPress={(e) => handleLogin()} style={styles.button}>
                                <Text style={styles.buttonText}>GİRİŞ YAP</Text>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={(e) => handleSignUp()} style={styles.button}>
                                <Text style={styles.buttonText}>ÜYE OL</Text>
                            </TouchableOpacity>
                        </Fragment>}
                </View>
            </View>

            {/* Header */}
            <View style={styles.header}>
                <Text style={styles.title}>RAF</Text>
                <Text style={styles.subtext}>Al. SAT. İKİNCİ EL KITAP</Text>

                {/* Store Badges */}
                <View style={styles.storeBadges}>
                    <TouchableOpacity style={styles.storeBadge} onPress={handleAppleBadgePress}>
                        <Image source={appleStoreLogo} style={styles.storeBadgeImage} />
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.storeBadge} onPress={handleGoogleBadgePress}>
                        <Image source={googlePlayLogo} style={styles.storeBadgeImage} />
                    </TouchableOpacity>
                </View>
                <TouchableOpacity style={styles.webBadge} onPress={handleWebBadgeClick}>
                    <Text style={styles.webBadgeText}>Websitesine Giriş Yap</Text>
                </TouchableOpacity>
            </View>

            {/* Guest Account FAQ */}
            <View style={styles.announcementContainer}>
                <Text style={styles.terms}>Tur At. Kendin Gör.</Text>
                <Pressable style={styles.button} onPress={(e) => handleTour()} onHoverIn={handleHover}>
                    <Text style={styles.buttonText}>ZİYARETÇİ GİRİŞİ YAP (HESAP GEREKMİYOR)</Text>
                </Pressable>
            </View >

            {/* Buyer FAQ */}
            <View style={styles.faq}>
                <Text style={styles.sectionTitle}>Alıcı Soruları</Text>
                {/* List your Buyer FAQ items with icons here */}
                <View style={styles.faqItemContainer}>
                    <View style={styles.faqItem}>
                        <View style={styles.iconContainer}>
                            {/* <Ionicons name="ios-information-circle" size={24} color="blue" style={styles.icon} /> */}
                            <MaterialCommunityIcons name="rocket" size={24} color="red" />
                        </View>
                        <Text style={styles.faqQuestion}>Kitap nasıl satın alınır?</Text>
                    </View>
                    <Text style={styles.faqAnswer}>Üye olduktan sonra portala gidin ve kolayca kitabınızı sepetinize ekleyin.</Text>
                </View>

                <View style={styles.faqItemContainer}>
                    <View style={styles.faqItem}>
                        <View style={styles.iconContainer}>
                            <MaterialCommunityIcons name="truck" size={24} color="red" />
                        </View>
                        <Text style={styles.faqQuestion}>Ödeme güvenli mi?</Text>
                    </View>
                    <Text style={styles.faqAnswer}>Evet. Ödemeniz güvenlidir. Kredi kartı bilgilerinizi kaydetmiyoruz.</Text>
                </View>

                <View style={styles.faqItemContainer}>
                    <View style={styles.faqItem}>
                        <View style={styles.iconContainer}>
                            <Ionicons name="ios-information-circle" size={24} color="red" />
                        </View>
                        <Text style={styles.faqQuestion}>Ürünü nasıl iade edebilirim?</Text>
                    </View>
                    <Text style={styles.faqAnswer}>Ürünü iade etmek için, sipariş ettiğiniz kitaplar sayfasına gidin. Iade etmek istediğiniz kitap için "Problem bildir" e tıklayın ve iade talebinizi formda detaylı bilgilerle doldurunuz.</Text>
                </View>

                <View style={styles.faqItemContainer}>
                    <View style={styles.faqItem}>
                        <View style={styles.iconContainer}>
                            <MaterialCommunityIcons name="check-circle" size={24} color="red" />
                        </View>
                        <Text style={styles.faqQuestion}>Ürün satın alma süreci nasıldır?</Text>
                    </View>
                    <Text style={styles.faqAnswer}>Sipariş ettiğiniz ürünü siteden takip edin. Ürün teslim edildikten sonra, ürünü aldım diye onaylayın. Onayladıktan hemen sonra satıcıya paranızı veriyoruz. Eğer onaylamazsanız, sizin yerinize biz otomatik olarak onaylıyoruz ki satıcı parasını alsın.</Text>
                </View>

                <View style={styles.faqItemContainer}>
                    <View style={styles.faqItem}>
                        <View style={styles.iconContainer}>
                            <MaterialCommunityIcons name="file-find" size={24} color="red" />
                        </View>
                        <Text style={styles.faqQuestion}>Kargo takibi nasıl yapılır?</Text>
                    </View>
                    <Text style={styles.faqAnswer}>Kargonu platformdan kolaylıkla takip et. Satıcının kargo takip numarası girmesi şart.</Text>
                </View>

                <View style={styles.faqItemContainer}>
                    <View style={styles.faqItem}>
                        <View style={styles.iconContainer}>
                            <MaterialCommunityIcons name="message-question" size={24} color="red" />
                        </View>
                        <Text style={styles.faqQuestion}>Ürün kargoda kayboldu, ne yapmalıyım?</Text>
                    </View>
                    <Text style={styles.faqAnswer}>Uygulamaya gidin ve "Sipariş Ettiğim Kitaplar" sayfasına tıklayın. Kaybolan ürün için "Problem Bildir" butonuna tıklayın ve formu doldurun. Paranızı hemen iade edeceğiz.</Text>
                </View>

                <View style={styles.faqItemContainer}>
                    <View style={styles.faqItem}>
                        <View style={styles.iconContainer}>
                            <MaterialCommunityIcons name="backup-restore" size={24} color="red" />
                        </View>
                        <Text style={styles.faqQuestion}>Yanlış ürün geldi, ne yapmalıyım?</Text>
                    </View>
                    <Text style={styles.faqAnswer}>Yanlış gelen ürün için "Problem Bildir" butonuna tıklayın ve formu doldurun. Paranızı hemen iade edeceğiz.</Text>
                </View>
                {/* Add more Buyer FAQ items */}
            </View>

            {/* Seller FAQ */}
            <View style={styles.faq}>
                <Text style={styles.sectionTitle}>Satıcı Soruları</Text>
                {/* List your Seller FAQ items with icons here */}
                <View style={styles.faqItemContainer}>
                    <View style={styles.faqItem}>
                        <View style={styles.iconContainer}>
                            <MaterialCommunityIcons name="wallet" size={24} color="red" />
                        </View>
                        <Text style={styles.faqQuestion}>Kitap nasıl satışa sunulur?</Text>
                    </View>
                    <Text style={styles.faqAnswer}>"Kitap satışa sun sayfasına tıklayın ve resim, fiyat, başlık, yazar ismi gibi bilgileri girin. Tamam'a tıklayın ve kitabınızı satışa kolaylıkla sunun.</Text>
                </View>

                <View style={styles.faqItemContainer}>
                    <View style={styles.faqItem}>
                        <View style={styles.iconContainer}>
                            <MaterialCommunityIcons name="account-cash" size={24} color="red" />
                        </View>
                        <Text style={styles.faqQuestion}>Ödemeleri nasıl alabilirim?</Text>
                    </View>
                    <Text style={styles.faqAnswer}>Banka hesabınızı girin. Kargoladığınız kitap teslimat edildikten 5 gün sonra paranız hesabınıza yatar.</Text>
                </View>

                <View style={styles.faqItemContainer}>
                    <View style={styles.faqItem}>
                        <View style={styles.iconContainer}>
                            <MaterialCommunityIcons name="cart" size={24} color="red" />
                        </View>
                        <Text style={styles.faqQuestion}>Kargoyu kim ödüyor?</Text>
                    </View>
                    <Text style={styles.faqAnswer}>Kargo parasını satıcı ödemektedir.</Text>
                </View>

                <View style={styles.faqItemContainer}>
                    <View style={styles.faqItem}>
                        <View style={styles.iconContainer}>
                            <MaterialCommunityIcons name="exclamation-thick" size={24} color="red" />
                        </View>
                        <Text style={styles.faqQuestion}>Satışlarımın kargosunu nasıl yaparım?</Text>
                    </View>
                    <Text style={styles.faqAnswer}>MNG anlasmalı kargo şirketimizdir. Sattığınız her kitap için indirimli MNG kampanya kodu üretmekteyiz. "Satışlar" sayfasından kodunuzu alın ve MNG şubesine gidin. Eğer kendi kargonuzu kendiniz seçtiğiniz kuryeyle gondermek istiyorsanız, kargoya kitaplarınızı verin ve teslimat takip numarasını "Satışlar" sayfasına girin. </Text>
                </View>

                {/* Add more Seller FAQ items */}
            </View>



            {/** CTA v2 */}
            <View style={styles.ctaContainer}>
                <View style={{ alignItems: "center" }}>
                    <Text style={styles.ctaBuyText}>İkinci El Kitaplarını Sat</Text>
                    <Text style={styles.ctaBuyText}>Ucuza Kitap Al</Text>
                    <Text style={styles.ctaSmallText}>KAZAN!</Text>
                </View>
                <View
                    style={{
                        marginTop: 20,
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                >
                    <View style={styles.ctaButton_container}>
                        <TouchableOpacity onPress={handleAppleBadgePress}>
                            <Image
                                source={appleStoreLogo}
                                style={{ width: 120, height: 40, resizeMode: 'contain' }}
                            />
                            <View style={{ marginLeft: 5 }}>
                                <Text style={styles.ctaAppStore}>AppStore</Text>
                                <Text style={styles.ctaDownload}>Uygulamasını Indir</Text>
                            </View>
                        </TouchableOpacity>
                    </View>

                    <View style={styles.ctaButton_container}>
                        <TouchableOpacity onPress={handleGoogleBadgePress}>
                            <Image
                                source={googlePlayLogo}
                                style={{ width: 120, height: 40, resizeMode: 'contain' }}
                            />
                            <View style={{ marginLeft: 5 }}>
                                <Text style={styles.ctaAppStore}>Google Play</Text>
                                <Text style={styles.ctaDownload}>Uygulamasını İndir</Text>
                            </View>
                        </TouchableOpacity>
                    </View>

                    <View style={styles.ctaButton_container}>
                        <TouchableOpacity onPress={handleWebBadgeClick}>
                            <Image
                                source={websiteBadge}
                                style={{ width: 100, height: 36, resizeMode: 'contain', backgroundColor: "white", borderRadius: 3, borderColor: "black" }}
                            />
                            <View style={{ marginLeft: 5, marginTop: 4 }}>
                                <Text style={styles.ctaAppStore}>Websitesini</Text>
                                <Text style={styles.ctaDownload}>Kullan</Text>
                            </View>
                        </TouchableOpacity>
                    </View>





                </View>
            </View >

            {/** Announcement */}
            < View style={styles.announcementContainer} >
                <View>
                    <Image source={bookPicture} />
                </View>
                <Text style={styles.terms}>İkinci El Kitaplarını Sat</Text>
                <View style={styles.announcementContent_container}>
                    <Text style={styles.announcementContent}>
                        Üye ol ve bir formla kitabını koy.
                        {" "}
                        Kullanmadığınız kitaplarınızı satın. Kitap satmak çok kolay. Teslimattan 5 gün sonra paranız hesabınıza yatar.
                    </Text>
                </View>
                <View>

                </View>
            </View >



            {/* Terms */}
            < View style={styles.termsContainer} >
                <Text style={styles.terms}>Ucuza Kitap Al</Text>
                <View style={styles.termsText_container}>
                    <Text style={styles.termsText_content}>
                        Kitaplar çok pahalı olabilir. Bizim platformumuzu kullan. Hesap yarat ve ucuza kitap satın al.
                        {" "}
                    </Text>
                </View>
            </View >


            {/* https://www.freecodecamp.org/static/Emma-5a662db9bcf55809a1fefb2ea17634bc.png */}
            {/* Testimonials */}
            <View style={styles.testimonialOuterContainer}>
                <View style={styles.testimonialHeader_container}>
                    <Text style={styles.testimonialHeader_text}>Raf Kullanıcıları Ne Diyor?</Text>
                </View>
                <View style={styles.testimonialContent_container}>
                    <View style={styles.testimonialContainer}>
                        {/* <Image source={{ uri: "https://www.freecodecamp.org/static/Emma-5a662db9bcf55809a1fefb2ea17634bc.png" }} style={styles.testimonialImage} /> */}
                        <Text style={styles.testimonialText}>"Çok sayıda indirimli kitaplar var. İkinci el kitaplardan para tasarrufu yapmak için çok iyi bir site."</Text>
                    </View>
                    <View style={styles.testimonialContainer}>
                        {/* <Image source={{ uri: "https://www.freecodecamp.org/static/Emma-5a662db9bcf55809a1fefb2ea17634bc.png" }} style={styles.testimonialImage} /> */}
                        <Text style={styles.testimonialText}>"İlk kitabımı satmak çok kolaydı. Kullanıcı arayüzü basit ve anlaması kolay."</Text>
                    </View>
                    <View style={styles.testimonialContainer}>
                        {/* <Image source={{ uri: "https://www.freecodecamp.org/static/Emma-5a662db9bcf55809a1fefb2ea17634bc.png" }} style={styles.testimonialImage} /> */}
                        <Text style={styles.testimonialText}>"Hızlı teslimat ve kaliteli ürünler."</Text>
                    </View>
                    <View style={styles.testimonialContainer}>
                        {/* <Image source={{ uri: "https://www.freecodecamp.org/static/Emma-5a662db9bcf55809a1fefb2ea17634bc.png" }} style={styles.testimonialImage} /> */}
                        <Text style={styles.testimonialText}>"Satın almak hızlı ve kolay. Kitap beklediğim gibi geldi"</Text>
                    </View>
                </View>
            </View>


            {/* Wishlist */}
            <View style={styles.wishlistContainer}>
                <View style={styles.wishlistHeader_container}>
                    <Text style={styles.wishlistHeader_text}>Rafa Kitap Koy. Para Kazan</Text>
                    <Text style={styles.wishlistHeader_text}>Raftan Kitap Satın Al. </Text>
                    <Text style={styles.wishlistHeader_text_last}>Kazan Kazan!</Text>
                </View>
                <View style={styles.wishlistContent_container}>
                    <Text style={styles.wishlistContent}>
                        Üye Olmak Kolay. Kitap Koymak Kolay. Para Kazanmak, Daha Kolay!
                    </Text>
                </View>

                <View style={styles.wishlistInput_container}>
                    {/* Store Badges */}
                    <View style={styles.storeBadgesContainer}>
                        <TouchableOpacity style={styles.storeBadge} onPress={handleAppleBadgePress}>
                            <Image source={appleStoreLogo} style={styles.storeBadgeImage} />
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.storeBadge} onPress={handleGoogleBadgePress}>
                            <Image source={googlePlayLogo} style={styles.storeBadgeImage} />
                        </TouchableOpacity>
                    </View>
                </View>

                <View style={styles.wishlistContent_container}>
                    <Text style={styles.wishlistContent}>
                        Uygulamalarımızı İndirebilirsiniz veya Websitemizi Kullanabilirsiniz
                    </Text>
                </View>

                <View style={styles.wishlistInput_container}>
                    <TouchableOpacity onPress={(e) => handleLogin()} style={styles.button}>
                        <Text style={styles.buttonText}>GİRİŞ YAP</Text>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={(e) => handleSignUp()} style={styles.button}>
                        <Text style={styles.buttonText}>ÜYE OL</Text>
                    </TouchableOpacity>
                </View>

                {/* <View style={styles.wishlistInput_container}>
                    <TextInput
                        placeholder="Input your email address"
                        style={styles.wishlistInput}
                    />
                    <View>
                        <Button title="JOIN  WAITLIST" color="rgba(255, 0, 104, 1)" />
                    </View>
                </View> */}
            </View>

            {/* Our Company Press*/}
            {/* <View style={styles.announcementContainer}>
                <View>
                    <Image source={{ uri: bookPicture }} />
                </View>
                <Text style={styles.terms}>Basında Biz</Text>
                <View style={styles.announcementContent_container}>
                    <Text style={styles.announcementContent}>
                        Basında yer aldık.
                    </Text>
                    <View style={styles.companyImages}>
                        <Image source={require('../../assets/image/newspaper-1.jpg')} style={styles.companyImage} />
                        <Image source={require('../../assets/image/newspaper-2.jpg')} style={styles.companyImage} />
                        <Image source={require('../../assets/image/newspaper-3.jpg')} style={styles.companyImage} />
                        <Image source={require('../../assets/image/newspaper-4.jpg')} style={styles.companyImage} />
                    </View>
                </View>
                <View>
                </View>
            </View> */}


            {/** Call to Action again */}
            {/* <View style={styles.wishlistContainer}>
                <View style={styles.wishlistHeader_container}>
                    <Text style={styles.wishlistHeader_text}>Buy from Haggle</Text>
                    <Text style={styles.wishlistHeader_text}>Merchants and</Text>
                    <Text style={styles.wishlistHeader_text_last}>pay small small</Text>
                </View>
                <View style={styles.wishlistContent_container}>
                    <Text style={styles.wishlistContent}>
                        Buying is a pleasure. Be in control of your happiness; choose how much
                        you're willing to pay and when you will pay back.
                    </Text>
                </View>

                <View style={styles.wishlistInput_container}>
                    <View style={styles.storeBadgesContainer}>
                        <TouchableOpacity style={styles.storeBadge}>
                            <Image source={require('../../assets/logo/google-store-badge.png')} style={styles.storeBadgeImage} />
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.storeBadge}>
                            <Image source={require('../../assets/logo/apple-store-badge.png')} style={styles.storeBadgeImage} />
                        </TouchableOpacity>
                    </View>
                </View>
                <View style={styles.wishlistInput_container}>
                    <TouchableOpacity onPress={(e) => handleLogin()} style={styles.button}>
                        <Text style={styles.buttonText}>GİRİŞ YAP</Text>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={(e) => handleSignUp()} style={styles.button}>
                        <Text style={styles.buttonText}>ÜYE OL</Text>
                    </TouchableOpacity>
                </View>
            </View> */}


            {/** Contact Us */}
            <View style={styles.contactContainer}>
                <View style={styles.contactHeader_container}>
                    <Text style={styles.contactHeader_text}>BİZE ULAŞIN</Text>
                    <Text style={styles.wishlistHeader_text_last}>Email: destek@raftankitap.com</Text>
                </View>
                <View>
                </View>
            </View>




            {/* Footer */}
            <View style={styles.footerContainer}>
                <View style={styles.footerRow}>
                    <View styles={styles.footerColumn}>
                        <TouchableOpacity onPress={() =>
                            navigation.navigate("about")
                        }>
                            <Text style={styles.footerLink}>Hakkımızda</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() =>
                            navigation.navigate("return")
                        }>
                            <Text style={styles.footerLink}>Teslimat ve İade Şartları</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() =>
                            navigation.navigate("privacy")
                        }>
                            <Text style={styles.footerLink}>Gizlilik Sözleşmesi</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() =>
                            navigation.navigate("cookies")
                        }>
                            <Text style={styles.footerLink}>Çerez Politikası</Text>
                        </TouchableOpacity>

                        <TouchableOpacity onPress={() =>
                            navigation.navigate("contact")
                        }>
                            <Text style={styles.footerLink}>İletişim</Text>
                        </TouchableOpacity>
                    </View>
                    <View styles={styles.footerColumn}>
                        <TouchableOpacity onPress={() =>
                            navigation.navigate("distanceselling")
                        }>
                            <Text style={styles.footerLink}>Mesafeli Satış Sözleşmesi</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() =>
                            navigation.navigate("faq")
                        }>
                            <Text style={styles.footerLink}>Sıkça Sorulan Sorular</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() =>
                            navigation.navigate("terms")
                        }>
                            <Text style={styles.footerLink}>Kullanım Koşulları Sözleşmesi</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() =>
                            navigation.navigate("feedback")
                        }>
                            <Text style={styles.footerLink}>Müşteri İletişim Formu</Text>
                        </TouchableOpacity>
                    </View>
                </View>
                <Image source={iyzicoLogo} style={styles.storeIyzicoLogo} />
                <Text style={styles.footerAltText}>Şirketimizden yazılı izin alınmadan sitedeki bilgiler ve fotoğraflar başka bir yerde kesinlikle kullanılamaz.</Text>
                <Text style={styles.footerAltText}>Deneyiminizi iyileştirmek için çerezler (cookies) kullanıyoruz. Detaylara Gizlilik Sözleşmesi sayfamızdan erişebilirsiniz.</Text>
                <Text style={styles.footerAltText}>Bütün hakları saklıdır © {(new Date().getFullYear())} Raf</Text>

            </View>


        </ScrollView >
    );
};

const styles = StyleSheet.create({
    iconContainer: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        paddingRight: 5
    },
    ctaButtonWebsite: {
        // width: 120,
        // height: 30,
        borderRadius: 5,
        backgroundColor: colors.white,
        display: "flex",
        flexDirection: "row",
        textAlign: "center",
        justifyContent: "center",
    },
    ctaButtonWebsiteContainer: {
        width: 120,
        height: 30,
        borderRadius: 5,
        backgroundColor: colors.white,
        display: "flex",
        flexDirection: "row",
        textAlign: "center",
        justifyContent: "center",
    },
    ctaButtonWebsiteText: {
        color: 'black', // Change this to your desired text color
        // fontSize: 12,
        // fontWeight: "600",
        paddingTop: 5
    },
    webBadge: {
        backgroundColor: 'black', // Change this to your desired background color
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderRadius: 5,
    },
    webBadgeText: {
        color: 'white', // Change this to your desired text color
        fontSize: 12,
        fontWeight: "600",
    },
    testimonialImage: {
        width: 100,
        height: 100,
        borderRadius: 50,
    },
    testimonialText: {
        color: "#fff",
        fontSize: 20,
        fontWeight: "600",
    },
    testimonialOuterContainer: {
        width: "100%",
        backgroundColor: "black",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 8,
        paddingHorizontal: 20,
        paddingVertical: 20,
        borderRadius: 20,
        marginTop: 30
    },
    testimonialHeader_container: {
        width: "100%",
        alignItems: "center",
    },
    testimonialContent: {
        color: "#fff",
        textAlign: "center",
    },
    testimonialContainer: {
        justifyContent: "center",
        backgroundColor: "white",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 8,
        paddingHorizontal: 20,
        paddingVertical: 20,
        borderRadius: 20,
        marginTop: 30
    },
    testimonialHeader_text: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 20,
        textAlign: "center",
        color: "white"
    },
    wishlistContainer: {
        width: "100%",
        // height: 300,
        backgroundColor: "black",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 8,
        paddingHorizontal: 20,
        paddingVertical: 20,
        borderRadius: 20,
        marginTop: 30
    },
    wishlistHeader_container: {
        width: "100%",
        alignItems: "center",
        justifyContent: "center"
    },
    wishlistHeader_text: {
        color: "#fff",
        fontSize: 20,
        fontWeight: "600",
    },
    wishlistHeader_text_last: {
        color: colors.primary,
        fontSize: 20,
        fontWeight: "600",
    },
    wishlistContent_container: {
        width: " 70%",
        marginVertical: 20,
    },
    wishlistContent: {
        color: "#fff",
        textAlign: "center",
    },
    wishlistInput_container: {
        width: "80%",
        flexDirection: "row",
        justifyContent: "center"
    },
    wishlistInput: {
        backgroundColor: "#fff",
        width: "50%",
        padding: 8,
        borderRadius: 5,
    },
    termsContainer: {
        backgroundColor: "white",
        width: "100%",
        alignItems: "center",
        paddingHorizontal: 20,
        paddingVertical: 20,
        borderRadius: 20,
        marginTop: 30,
    },
    terms: {
        color: colors.primary,
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 20,
        textAlign: "center",
    },
    termsText_container: {
        width: "70%",
        marginTop: 10,
    },
    termsText_content: {
        textAlign: "center",
        fontSize: 15,
        letterSpacing: 1,
    },
    navigationBar: {
        backgroundColor: colors.primary,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: "100%",
        paddingHorizontal: 20,
    },
    container: {
        flex: 1,
        paddingBottom: 20,
    },
    header: {
        alignItems: 'center',
        paddingHorizontal: 20,
        marginTop: 20,
    },
    title: {
        fontSize: 36,
        fontWeight: 'bold',
    },
    subtext: {
        fontSize: 18,
        color: '#888',
    },
    storeBadges: {
        flexDirection: 'row',
        marginTop: 20,
    },
    storeBadgesContainer: {
        flexDirection: 'row',
    },
    storeBadge: {
        marginRight: 10,
    },
    storeBadgeImage: {
        width: 120,
        height: 40,
        resizeMode: 'contain',
    },
    storeIyzicoLogo: {
        width: 360,
        height: 200,
        resizeMode: 'contain',
    },
    section: {
        marginTop: 30,
        paddingHorizontal: 20,
        backgroundColor: colors.primary
    },
    faq: {
        marginTop: 30,
        paddingHorizontal: 20,
        paddingVertical: 20,
        backgroundColor: "white",
        borderRadius: 20,
    },
    sectionTitle: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 20,
        textAlign: "center",
        color: colors.primary
    },
    company: {
        marginTop: 30,
        paddingHorizontal: 20,
    },
    cta: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 30,
        paddingHorizontal: 20,
        backgroundColor: colors.primary,
        borderRadius: 8,
        paddingHorizontal: 20,
        paddingVertical: 20,
        borderRadius: 20,
    },
    companyImages: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 10,
    },
    companyImage: {
        width: 80,
        height: 80,
        resizeMode: 'cover',
        borderRadius: 10,
        borderWidth: 1,
        borderColor: '#ccc',
        marginHorizontal: 5,
    },
    loginButton: {
        backgroundColor: '#FF0000',
        alignItems: 'center',
        paddingVertical: 12,
        marginHorizontal: 20,
        borderRadius: 10,
    },
    loginButtonText: {
        color: '#fff',
        fontSize: 18,
        fontWeight: 'bold',
    },
    button: {
        backgroundColor: colors.blue,
        paddingHorizontal: 15,
        paddingVertical: 8,
        borderRadius: 10,
        marginLeft: 10,
    },
    buttonText: {
        color: '#fff',
        fontWeight: "bold",
    },
    companyLogo: {
        width: 40,
        height: 40,
        resizeMode: 'contain',
    },
    buttonContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 20,
    },
    footerContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "black",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 8,
        paddingHorizontal: 20,
        paddingVertical: 20,
        borderRadius: 20,
        marginTop: 30,
    },
    footerRow: {
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        flex: 1
    },
    footerColumn: {
        backgroundColor: "#000",
        color: "#fff",
    },
    footerLink: {
        paddingRight: "20px",
        fontSize: 14,
        marginBottom: 5,
        letterSpacing: 1,
        color: "white"
    },
    footerAltText: {
        fontSize: 12,
        marginBottom: 5,
        letterSpacing: 1,
        color: "grey"
    },
    ctaContainer: {
        backgroundColor: "black",
        width: "100%",
        height: 300,
        marginTop: 30,
        alignItems: "center",
        justifyContent: "center",
        marginBottom: 20,
        borderRadius: 8,
        paddingHorizontal: 20,
        paddingVertical: 20,
        borderRadius: 20,

    },
    bookImageContainer: {
        width: "100%",
        height: 300,
        marginTop: 40,
        alignItems: "center",
        justifyContent: "center",
    },
    ctaBuyText: {
        color: "#fff",
        fontSize: 25,
        letterSpacing: 1,
    },
    ctaSmallText: {
        color: colors.primary,
        fontSize: 25,
        letterSpacing: 1,
        marginTop: 8,
    },
    testimonialText: {
        fontSize: 25,
        letterSpacing: 1
    },
    ctaTerms: {
        color: "#fff",
        marginTop: 8,
        fontSize: 18,
    },
    ctaButton_container: {
        borderWidth: 1,
        // borderColor: "#fff",
        flexDirection: "column",
        borderRadius: 5,
        padding: 5,
        alignItems: "center",
    },
    ctaDownload: {
        color: "#fff",
    },
    ctaAppStore: {
        color: "#fff",
        fontSize: 20,
        fontWeight: "600",
    },
    ctaGoogleImage: {
        width: 20,
        height: 20,
    },
    contactContainer: {
        width: "100%",
        height: 300,
        backgroundColor: "black",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 8,
        paddingHorizontal: 20,
        paddingVertical: 20,
        borderRadius: 20,
        marginTop: 30,
    },
    contactHeader: {
        color: "#000",
        fontWeight: "700",
        fontSize: 20,
    },
    contactHeader_text: {
        color: "#fff",
        fontSize: 20,
        fontWeight: "600",
    },
    contactHeader_container: {
        width: "100%",
        alignItems: "center",
    },
    announcementContainer: {
        backgroundColor: "white",
        alignItems: "center",
        borderRadius: 8,
        paddingHorizontal: 20,
        paddingVertical: 20,
        borderRadius: 20,
        marginTop: 30,
    },
    announcementContent_container: {
        marginTop: 20,
    },
    announcementContent: {
        letterSpacing: 1,
        fontSize: 15,
        textAlign: "center",
    },
    icon_container: {
        backgroundColor: "#000",
        width: 35,
        height: 35,
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 50,
        marginRight: 10,
    },
    icon_box: {
        flexDirection: "row",
        marginVertical: 15,
    },
    faqItem: {
        display: "flex",
        flexDirection: "row",
    },
    faqQuestion: {
        fontWeight: "700",
        fontSize: 20,
    },
    faqItemContainer: {
        alignItems: "left",
        justifyContent: "center",
        borderRadius: 50,
        marginBottom: 20,
        paddingLeft: 50
    }
});

export default LandingScreen;
